import React from "react";
import { SideBar } from "./sidebar";
import { getWalletUsers } from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  createWallet,
  getAllWallets,
  getWallet,
  updateWalletFile,
  updateWalletTransaction,
  deleteTransaction,
} from "../../models/wallet";
import { getLoggedUser } from "../../state/user";
import { apiUrl } from "../../constants/global";
import { HomeBanner } from "../home/homeBanner";
import FileInput from "../helpers/fileInputNew";
import { getAllOrganization } from "../../models/organization";
import { getAllGroups } from "../../models/groups";

const user = getLoggedUser();
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9 }),
};

const wallet_types = {
  admin: "Added by admin",
  sitevisit: "Site Visit",
  enroll: "Enroll",
  wallet_recharge: "Recharge",
  withdraw: i18n.t("withdraw"),
};
const wallet_statuses = {
  A: i18n.t("approved"),
  P: "Waiting for admin approval",
  F: i18n.t("waiting_for_finance_approval"),
  W: "Withdraw requested",
  WA: i18n.t("withdraw_request_approved_by_admin"),
  R: "Refund Approved",
  WR: "Withdraw Request Rejected",
};

export class WalletWithdrawals extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      update_popup: false,
      add: {},
      data: {},
      errors: {},
      is_loading: false,
      transactions: [],
      customers: [],
      hideSidebar: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 25,
        rtype: "U",
        status: "R",
      },
      clients: [],
      groups: [],
      user_info: {},
      file_receipt: null,
      file_receipt_edit: null,
      file_fm: null,
      file_fm_edit: null,
      credit_sum: 0,
      debit_sum: 0,
      balance_sum: 0,
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          wrap: true,
          width: "150px",
          cell: (row) => {
            return (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <b>{row.id}</b>
                <small>
                  <a
                    href={`/users-wallet?user=` + row.user}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {i18n.t("wallet")} <i className="fa fa-external-link" />
                  </a>
                </small>
              </div>
            );
          },
        },
        {
          name: i18n.t("mzadcom_id"),
          selector: "user",
          sortable: true,
          wrap: false,
        },
        {
          name: i18n.t("username"),
          selector: "user",
          sortable: true,
          wrap: true,
          cell: (row) =>
            `${row.user && row.user_info ? row.user_info.name : ""}`,
        },
        {
          name: i18n.t("beneficiary"),
          selector: "beneficiary",
          sortable: true,
          wrap: true,
          cell: (row) =>
            `${row.user && row.user_info ? row.user_info.beneficiary : ""}`,
        },
        {
          name: i18n.t("transaction"),
          selector: "transaction_id",
          sortable: true,
        },
        {
          name: i18n.t("deposit"),
          selector: "credit",
        },
        {
          name: i18n.t("withdraw"),
          selector: "debit",
        },
        {
          name: i18n.t("balance"),
          selector: "balance",
        },
        {
          name: i18n.t("reference"),
          selector: "reference",
          wrap: true,
        },
        {
          name: i18n.t("type"),
          selector: "type",
          wrap: true,
          cell: (row) => `${row.type ? wallet_types[row.type] : ""}`,
        },
        {
          name: i18n.t("status"),
          selector: "status",
          wrap: true,
          width: "150px",
          cell: (row) => `${row.status ? wallet_statuses[row.status] : ""}`,
        },
        {
          name: i18n.t("admin_approved_on"),
          wrap: true,
          selector: "admin_approved_on",
        },
        {
          name: i18n.t("receipt"),
          selector: "file_receipt",
          cell: (row) => {
            if (row.file_receipt) {
              return (
                <a href={row.file_receipt} target="_new">
                  {i18n.t("view")}
                </a>
              );
            }
          },
        },
        {
          name: i18n.t("fm_approved_on"),
          wrap: true,
          selector: "fm_approved_on",
        },
        {
          name: i18n.t("attatchment"),
          selector: "file_fm",
          cell: (row) => {
            if (row.file_fm) {
              return (
                <a href={row.file_fm} target="_new">
                  {i18n.t("view")}
                </a>
              );
            }
          },
        },
        {
          name: i18n.t("date"),
          wrap: true,
          selector: "created_at",
        },
        {
          name: "",
          selector: "user",
          cell: (row) => {
            return (
              <button
                className="btn rounded-circle btn-outline-info btn-sm p-1"
                onClick={() => {
                  this.setState({
                    show_info_popup: true,
                    selected_user: row.user_info,
                  });
                }}
                style={{ width: "30px", height: "30px" }}
              >
                <i className="fa fa-info" />
              </button>
            );
          },
        },
        {
          name: i18n.t("action"),
          width: "auto",
          cell: (row) => {
            if (user.role === "A") {
              let ret = [
                <button
                  key="edit"
                  className="btn btn-outline-warning btn-sm"
                  onClick={() => {
                    this.setState({
                      show_edit_popup: true,
                      selected_row: row,
                      user_info: row.user_info,
                    });
                  }}
                >
                  {i18n.t("edit")}
                </button>,
              ];

              if (row.status === "W") {
                ret.push(
                  <>
                    <button
                      key="approve"
                      className="btn btn-outline-info btn-sm"
                      onClick={() => {
                        this.setState({
                          show_admin_popup: true,
                          selected_row: row,
                          user_info: row.user_info,
                        });
                        //this.updateWalletRow("WA", row);
                      }}
                    >
                      {i18n.t("approve")}
                    </button>
                    <button
                      key="reject"
                      className="btn btn-outline-danger btn-sm"
                      onClick={() => {
                        this.setState({
                          show_reject_popup: true,
                          selected_row: row,
                        });
                      }}
                    >
                      {i18n.t("reject")}
                    </button>
                  </>
                );
              }

              return <>{ret}</>;
            }

            if (user.role === "F" && row.status === "WA") {
              return (
                <button
                  className="btn btn-outline-info btn-sm"
                  onClick={() => {
                    this.setState({
                      show_finance_popup: true,
                      selected_row: row,
                      user_info: row.user_info,
                    });
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }
          },
        },
        {
          name: i18n.t("status"),
          cell: (row) => {
            if (user.role !== "A" && row.status === "W") {
              return (
                <small className="text-danger">
                  {i18n.t("waiting_for_finance_approval")}
                </small>
              );
            }
            if (row.status === "R") {
              return (
                <small className="text-success text-center">{"Refunded"}</small>
              );
            }
            if (row.status === "WA") {
              return (
                <small className="text-danger">
                  {i18n.t("waiting_for_finance_approval")}
                </small>
              );
            }
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.triggerReload = this.triggerReload.bind(this);
    this.updateWalletRow = this.updateWalletRow.bind(this);
    this.updateTransactionRow = this.updateTransactionRow.bind(this);
    this.deleteTransactionRow = this.deleteTransactionRow.bind(this);
    this.updateWalletRowWithFile = this.updateWalletRowWithFile.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleChangeAdmin = this.handleChangeAdmin.bind(this);
    this.handleChangeUpdate = this.handleChangeUpdate.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
    this.loadCustomers();
    this.loadAllOrganisation();
    this.loadAllGroups();
  }

  onFileChange = (event) => {
    if (event.target.name === "file_receipt") {
      this.setState({ file_receipt: event.target.files[0] });
    }
    if (event.target.name === "file_fm") {
      this.setState({ file_fm: event.target.files[0] });
    }
    if (event.target.name === "file_receipt_edit") {
      this.setState({ file_receipt_edit: event.target.files[0] });
    }
    if (event.target.name === "file_fm_edit") {
      this.setState({ file_fm_edit: event.target.files[0] });
    }
  };

  async loadCustomers() {
    this.setState({ is_loading: true });
    const response = await getWalletUsers();
    let data = response.data;
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });

      this.setState({ is_loading: false });
      this.setState({ customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    if (event.target.name === "client") {
      input.group = "";
      this.loadAllGroups();
    }
    this.setState({ input });
  }
  handleAddChange(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }
  handleChangeAdmin(event) {
    let { user_info } = this.state;
    user_info[event.target.name] = event.target.value;
    this.setState({ user_info });
  }

  handleChangeUpdate(event) {
    let { selected_row } = this.state;
    selected_row[event.target.name] = event.target.value;
    this.setState({ selected_row });
  }

  handleSearch(event) {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllTransactions(q) {
    this.setState({ is_loading: true });
    q = q + "&type=withdraw";
    const response = await getAllWallets(q);
    let transactions = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ transactions: transactions });
      this.setState({ totalRows: meta.total });
      this.setState({
        credit_sum: meta["credit_sum"],
        debit_sum: meta["debit_sum"],
        balance_sum: meta["balance_sum"],
      });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async viewRow(id) {
    const response = await getWallet(id);
    let data = response.data;
    this.setState({ data, update_popup: true });
  }

  async updateWalletRow(status) {
    let row = this.state.selected_row;
    let { bank, beneficiary, account_number } = this.state.user_info;
    if (!status) {
      status = this.state.user_info.status;
    }
    if (!bank || !beneficiary || !account_number || !this.state.file_receipt) {
      swal(i18n.t("error"), i18n.t("all_mandatory"), "error");
      return;
    }

    const formData = new FormData();
    formData.append("id", row.id);
    formData.append("user", row.user);
    formData.append("status", status);
    formData.append("bank", bank);
    formData.append("beneficiary", beneficiary);
    formData.append("account_number", account_number);
    if (this.state.file_receipt) {
      formData.append(
        "file_receipt",
        this.state.file_receipt,
        this.state.file_receipt.name
      );
    }
    const response = await updateWalletFile(formData, row.id);
    if (response && response.success) {
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
      this.setState({ show_admin_popup: false });
    }
  }

  async updateWalletRowWithFile(event) {
    event.preventDefault();
    if (!this.state.file_fm) {
      swal(i18n.t("error"), i18n.t("upload_receipt"), "error");
      return;
    }
    let { selected_row } = this.state;
    const formData = new FormData();
    formData.append("id", selected_row.id);
    formData.append("user", selected_row.user);
    formData.append("status", "R");
    if (this.state.file_fm) {
      formData.append("file_fm", this.state.file_fm, this.state.file_fm.name);
    }

    const response = await updateWalletFile(formData, selected_row.id);
    if (response && response.success) {
      this.setState({ show_finance_popup: false });
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
    }
  }

  async updateTransactionRow() {
    let {
      id,
      user,
      bank,
      account_number,
      receipt_number,
      status,
      debit,
      credit,
      hold_amount,
      balance,
    } = this.state.selected_row;
    // if (!status) {
    //   swal(i18n.t("error"), i18n.t("all_mandatory"), "error");
    //   return;
    // }

    const formData = new FormData();
    formData.append("id", id);
    formData.append("user", user);
    formData.append("credit", credit);
    formData.append("debit", debit);
    formData.append("status", status);
    formData.append("bank", bank);
    formData.append("hold_amount", hold_amount);
    formData.append("balance", balance);
    formData.append("account_number", account_number);
    if (receipt_number && typeof receipt_number != "undefined") {
      formData.append("receipt_number", receipt_number);
    }

    if (this.state.file_receipt_edit) {
      formData.append(
        "file_receipt",
        this.state.file_receipt_edit,
        this.state.file_receipt_edit.name
      );
    }
    if (this.state.file_fm_edit) {
      formData.append(
        "file_fm",
        this.state.file_fm_edit,
        this.state.file_fm_edit.name
      );
    }
    const response = await updateWalletTransaction(formData, id);
    if (response && response.success) {
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
    }
  }

  async deleteTransactionRow() {
    let { id } = this.state.selected_row;
    if (!id) {
      swal(
        i18n.t("error"),
        i18n.t("Please select a row before delete"),
        "error"
      );
      return;
    }
    const response = await deleteTransaction({ id: id });
    if (response && response.success) {
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.setState({ show_reject_popup: false });
      this.handleSearch();
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllTransactions(qstring);
  };

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createWallet(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "Fund added successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              this.resetForm();
              let { input } = this.state;
              let q = new URLSearchParams(input).toString();
              this.loadAllTransactions(q);
              this.setState({ open_popup: false });
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  resetForm() {
    let { add } = this.state;
    add["name"] = "";
    add["mobile"] = "";
    add["email"] = "";
    add["username"] = "";
    add["country_code"] = "+968";
    add["password"] = "";
    add["role"] = "";
    this.setState({ add: add });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.add;
    let errors = {};
    let isValid = true;
    if (!input["user"]) {
      isValid = false;
      errors["user"] = "Please select a user.";
    }
    if (!input["credit"]) {
      isValid = false;
      errors["credit"] = "Please enter an amount.";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  triggerReload(opt) {
    if (opt) {
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadAllTransactions(qstring);
    }
  }

  render() {
    let { transactions, customers, input, clients, groups } = this.state;
    let q = new URLSearchParams(input).toString();
    let pdf_url = "withdrawal_report/export?type=withdraw&" + q;
    let excel_url = "withdrawal_report/excel?type=withdraw&" + q;
    const statusOptions = Object.entries(wallet_statuses);
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <div
                      className="d-flex column-direction"
                      style={{ width: "100%" }}
                    >
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                        className="d-mob-none"
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>

                      <div
                        className="d-flex justify-content-between w-50 w-mob-100 pe-5"
                        style={{ width: "100%" }}
                      >
                        <h3 className="card-title">
                          {i18n.t("withdraw_requests")}
                        </h3>
                        {transactions.length > 0 && (
                          <div className="d-flex">
                            <a
                              href={apiUrl + pdf_url}
                              className="btn btn-outline-danger btn-sm border-0 p-1"
                              target="_new"
                            >
                              <i className="fa fa-file-pdf-o fa-2x" />
                            </a>{" "}
                            &nbsp;
                            <a
                              href={apiUrl + excel_url}
                              className="btn btn-outline-dark btn-sm border-0 p-1"
                              target="_new"
                            >
                              <i className="fa fa-file-excel-o fa-2x" />
                            </a>{" "}
                            &nbsp;
                          </div>
                        )}
                      </div>

                      <div
                        className="d-flex w-33 w-mob-100 gap-1"
                        style={{ width: "45%" }}
                      >
                        <div className="col-6 mx-1">
                          {/* <select
                            className="form-select textbox fs15 text-gray filter-icon rounded-2"
                            data-placeholder="Select Client"
                            name="client"
                            value={this.state.input.client}
                            onChange={this.handleChange}
                          >
                            <option value="">{i18n.t("select_client")}</option>
                            {clients.length > 0 &&
                              clients.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.organization_name}
                                  </option>
                                );
                              })}
                          </select> */}
                        </div>
                        <div className="col-6 mx-1">
                          <select
                            className="form-select textbox fs15 text-gray filter-icon rounded-2"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">{i18n.t("select_group")}</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.state.is_loading && (
                      <div className="text-center">
                        <Spinner animation="border" size="lg" />
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            placeholder={i18n.t("from_date")}
                            name="fdate"
                            value={this.state.input.fdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            placeholder={i18n.t("to_date")}
                            name="tdate"
                            value={this.state.input.tdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group">
                          <select
                            className="form-control"
                            data-placeholder="Select Type"
                            name="rtype"
                            value={this.state.input.rtype}
                            onChange={this.handleChange}
                          >
                            <option value="">--Type--</option>
                            <option value="U">
                              {i18n.t("user_requested")}
                            </option>
                            <option value="R">{i18n.t("refund")}</option>
                          </select>
                        </div>
                      </div>

                      {this.state.input.rtype === "U" && (
                        <div className="col-md-2">
                          <div className="form-group">
                            <select
                              className="form-control"
                              data-placeholder="Status"
                              name="status"
                              value={this.state.input.status}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("status")}--</option>
                              <option value="R">{i18n.t("requested")}</option>
                              <option value="A">{i18n.t("approved")}</option>
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="col-md-2">
                        <div className="form-group">
                          <Select
                            className="rounded-5 "
                            placeholder={i18n.t("bidder")}
                            options={customers}
                            onChange={(e) => {
                              let { input } = this.state;
                              input.customer = e.value;
                              this.setState({ input });
                            }}
                            name="customer"
                            styles={selectStyles}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                          ></Select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          {(this.state.input.customer ||
                            this.state.input.rtype ||
                            this.state.input.fdate ||
                            this.state.input.tdate) && (
                            <button
                              className="btn btn-danger rounded-0"
                              onClick={(e) => {
                                let { input } = this.state;
                                input.customer = "";
                                input.fdate = "";
                                input.tdate = "";
                                input.rtype = "";
                                input.status = "";
                                this.setState({ input });
                                this.handleSearch(e);
                              }}
                            >
                              <i className="fa fa-times" />
                            </button>
                          )}
                          <button
                            className="btn btn-primary rounded-0"
                            onClick={this.handleSearch}
                          >
                            <i className="fa fa-search fa-1x" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive ">
                      {!this.state.is_loading && transactions.length > 0 && (
                        <div className="row">
                          <div className="table-wrapper">
                            <DataTable
                              title=""
                              fixedHeader
                              columns={this.state.columns}
                              data={transactions}
                              customStyles={customStyles}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={this.state.totalRows}
                              paginationPerPage={this.state.input.limit}
                              paginationDefaultPage={this.state.currentPage}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: i18n.t(
                                  "transactions_per_page"
                                ),
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              onChangePage={this.handlePageChange}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.show_info_popup}
          onHide={() => {
            let { selected_user } = this.state;
            selected_user = "";
            this.setState({ show_info_popup: false, selected_user });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("bank_details")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              <p>
                {i18n.t("bank")} :{" "}
                <b>
                  {this.state.selected_user && this.state.selected_user.bank}
                </b>
              </p>
              <p>
                {i18n.t("account_number")} :{" "}
                <b>
                  {this.state.selected_user &&
                    this.state.selected_user.account_number}
                </b>
              </p>
              <p>
                {i18n.t("beneficiary")} :{" "}
                <b>
                  {this.state.selected_user &&
                    this.state.selected_user.beneficiary}
                </b>
              </p>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show_finance_popup}
          onHide={() => {
            let { selected_row } = this.state;
            selected_row = "";
            this.setState({ show_finance_popup: false, selected_row });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("approve")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <form
                  id="Register"
                  className="card-body"
                  tabindex="500"
                  autoComplete="off"
                  onSubmit={this.updateWalletRowWithFile}
                >
                  <div className="form-group m-0">
                    <label for="form-label" className="form-label">
                      {i18n.t("bank")}
                      {" : "}
                      <span className="text text-danger font-weight-bold">
                        {this.state.user_info.bank}
                      </span>
                    </label>
                    &nbsp;
                  </div>
                  <div className="form-group m-0">
                    <label for="form-label" className="form-label">
                      {i18n.t("beneficiary")}
                      {" : "}
                      <span className="text text-danger font-weight-bold">
                        {this.state.user_info.beneficiary}
                      </span>
                    </label>
                    &nbsp;
                  </div>
                  <div className="form-group m-0">
                    <label for="form-label" className="form-label">
                      {i18n.t("account_number")}
                      {" : "}
                      <span className="text text-danger font-weight-bold">
                        {this.state.user_info.account_number}
                      </span>
                    </label>
                    &nbsp;
                  </div>

                  <div className="form-group">
                    <div className="form-group">
                      <label
                        for="form-label"
                        className="form-label"
                        style={{ top: "inherit" }}
                      >
                        {i18n.t("attatchment")}{" "}
                        <span className="text text-danger">(*)</span>
                      </label>
                      <FileInput
                        wrapperClass=" pad-adjust"
                        placeholder={i18n.t("choose_file")}
                        id="file_fm"
                        name="file_fm"
                        onChange={this.onFileChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      {i18n.t("submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show_admin_popup}
          onHide={() => {
            let { selected_row } = this.state;
            selected_row = "";
            this.setState({ show_admin_popup: false, selected_row });
          }}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("approve")} {i18n.t("by")} {i18n.t("admin")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <form
                  id="UserUpdate"
                  className="card-body pt-0"
                  tabindex="500"
                  autoComplete="off"
                >
                  <div className="form-group">
                    <div className="form-group">
                      <label
                        for="form-label"
                        className="form-label"
                        style={{ top: "inherit" }}
                      >
                        {i18n.t("bank")}{" "}
                        <span className="text text-danger">(*)</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="bank"
                        value={this.state.user_info.bank}
                        onChange={this.handleChangeAdmin}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label
                        for="form-label"
                        className="form-label"
                        style={{ top: "inherit" }}
                      >
                        {i18n.t("account_number")}{" "}
                        <span className="text text-danger">(*)</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="account_number"
                        value={this.state.user_info.account_number}
                        onChange={this.handleChangeAdmin}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label
                        for="form-label"
                        className="form-label"
                        style={{ top: "inherit" }}
                      >
                        {i18n.t("beneficiary")}{" "}
                        <span className="text text-danger">(*)</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="beneficiary"
                        value={this.state.user_info.beneficiary}
                        onChange={this.handleChangeAdmin}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group">
                      <label
                        for="form-label"
                        className="form-label"
                        style={{ top: "inherit" }}
                      >
                        {i18n.t("attatchment")}{" "}
                        <span className="text text-danger">(*)</span>
                      </label>
                      <FileInput
                        wrapperClass=" pad-adjust"
                        placeholder={i18n.t("choose_file")}
                        id="file_receipt"
                        name="file_receipt"
                        onChange={this.onFileChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="button"
                      className="btn btn-primary btn-block btn-md"
                      onClick={() => {
                        this.updateWalletRow("WA");
                      }}
                    >
                      {i18n.t("submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show_edit_popup}
          onHide={() => {
            let { selected_row } = this.state;
            selected_row = "";
            this.setState({ show_edit_popup: false, selected_row });
          }}
          size="lg"
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("edit")} {i18n.t("transaction")} :{" "}
              {this.state.selected_row?.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <form
                  id="UserUpdate"
                  className="card-body pt-0"
                  tabindex="500"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("withdraw")} {i18n.t("amount")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="debit"
                            value={this.state.selected_row?.debit}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("deposit")} {i18n.t("amount")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="credit"
                            value={this.state.selected_row?.credit}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("hold")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={i18n.t("hold_amount")}
                            name="hold_amount"
                            value={this.state.selected_row?.hold_amount}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("balance")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={i18n.t("balance")}
                            name="balance"
                            value={this.state.selected_row?.balance}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("bank")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="bank"
                            value={this.state.selected_row?.bank}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("account_number")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="account_number"
                            value={this.state.selected_row?.account_number}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("receipt")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={i18n.t("please_enter_receipt_number")}
                            name="receipt_number"
                            value={this.state.selected_row?.receipt_number}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("attatchment")}({i18n.t("receipt")})
                          </label>
                          <FileInput
                            wrapperClass=" pad-adjust"
                            placeholder={i18n.t("choose_file")}
                            id="file_receipt_edit"
                            name="file_receipt_edit"
                            onChange={this.onFileChange}
                            className="form-control"
                            format_url={this.state.selected_row?.file_receipt}
                            is_file_view={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("attatchment")}({i18n.t("finance")})
                          </label>
                          <FileInput
                            wrapperClass=" pad-adjust"
                            placeholder={i18n.t("choose_file")}
                            id="file_fm_edit"
                            name="file_fm_edit"
                            onChange={this.onFileChange}
                            className="form-control"
                            format_url={this.state.selected_row?.file_fm}
                            is_file_view={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("status")}
                          </label>
                          <select
                            className="form-select textbox fs15 text-gray filter-icon rounded-2"
                            data-placeholder="Select status"
                            name="status"
                            value={this.state.selected_row?.status}
                            onChange={this.handleChangeUpdate}
                          >
                            <option value="">{i18n.t("status")}</option>
                            {statusOptions.length > 0 &&
                              statusOptions.map(([key, value]) => (
                                <option value={key} key={key}>
                                  {value}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group mb-0 mt-4 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-success btn-md"
                            onClick={() => {
                              this.updateTransactionRow(false);
                            }}
                          >
                            <i className="fa fa-save" />
                            &nbsp;
                            {i18n.t("submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show_reject_popup}
          onHide={() => {
            let { selected_row } = this.state;
            selected_row = "";
            this.setState({ show_reject_popup: false, selected_row });
          }}
          size=""
          dialogClassName=""
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("reject")} {i18n.t("transaction")} :{" "}
              {this.state.selected_row?.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <p className="text-center">
                      Do you want to reject this transaction.?
                    </p>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <div className="form-group mb-0 mt-4 d-flex justify-content-center">
                        <button
                          type="button"
                          className="btn btn-warning btn-md"
                          onClick={() => {
                            this.deleteTransactionRow();
                          }}
                        >
                          {/* <i className="fa fa-delete" />
                          &nbsp; */}
                          {i18n.t("yes")}
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-light btn-md border border-dark"
                          onClick={() => {
                            this.setState({ show_reject_popup: false });
                          }}
                        >
                          {/* <i className="fa fa-times" />
                          &nbsp; */}
                          {i18n.t("no")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
