import React from "react";
import { SideBar } from "./sidebar";
import {
  getAllAuctionsByGroup,
  getEnrolledUsers,
  activateUserEnroll,
  deActivateUserEnroll,
  activateAllUserEnroll,
  deleteEnrolledUser,
  approveOrRejectEnrolls,
  enrollBulkDelete,
  getEnrolledUsersByGroup,
} from "../../models/auctions";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import { getAllGroups } from "../../models/groups";
import { HomeBanner } from "../home/homeBanner";
import { apiUrl } from "../../constants/global";
import { getLoggedUser } from "../../state/user";
import { getAllOrganization } from "../../models/organization";
import { ListTable } from "../helpers/skeletons/ListTable";
import Select from "react-select";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9 }),
};

const user = getLoggedUser();
export class EnrolledUsers extends React.Component {
  constructor() {
    super();
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    this.state = {
      bulk_updating: false,
      row_updating: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
        type: "active",
        group: params.group ? params.group : "",
      },
      query: "",
      auctions: {},
      is_loading: false,
      customers: [],
      clients: [],
      groups: [],
      group_auctions: [],
      isChecked: false,
      isAllChecked: false,
      selected_ids: [],
      columns: [
        // {
        //   cell: (row) => {
        //     return (
        //       <input
        //         type="checkbox"
        //         value={row.id}
        //         checked={row.isChecked}
        //         onChange={() => this.handleCheckboxChange(row.id)}
        //         disabled={row.status === "A"}
        //       />
        //     );
        //   },
        // },
        {
          name: "ID",
          sortable: true,
          width: "100px",
          cell: (row) => row.id,
        },
        {
          name: "Enroll ID",
          sortable: true,
          width: "100px",
          cell: (row) => row.enroll_number,
        },
        {
          name: "Auction ID",
          sortable: true,
          width: "100px",
          cell: (row) => row.auction_id,
        },
        {
          name: i18n.t("name"),
          sortable: true,
          cell: (row) => row.user.name,
        },
        {
          name: i18n.t("username"),
          sortable: true,
          cell: (row) => row.user.username,
        },
        {
          name: i18n.t("email"),
          cell: (row) => row.user.email,
        },
        {
          name: i18n.t("mobile"),
          wrap: true,
          cell: (row) => row.user.mobile,
        },
        {
          name: i18n.t("auction"),
          wrap: true,
          cell: (row) => row.auction.title,
        },
        {
          name: i18n.t("identity_type"),
          wrap: true,
          cell: (row) => row.identity_type,
        },
        {
          name: i18n.t("Civil Card"),
          cell: (row) =>
            row.file_civil_card ? (
              <a href={row.file_civil_card} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("Signature"),
          cell: (row) =>
            row.file_signature ? (
              <a href={row.file_signature} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("receipt"),
          cell: (row) =>
            row.file_receipt ? (
              <a href={row.file_receipt} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("account_number"),
          wrap: true,
          cell: (row) => row.account_number || "No",
        },
        {
          name: i18n.t("is_company"),
          wrap: true,
          cell: (row) => (row.is_company === 1 ? "Yes" : "No"),
        },
        {
          name: i18n.t("cr_number"),
          wrap: true,
          cell: (row) => (row.is_company === 1 ? row.cr_number : "No"),
        },
        {
          name: i18n.t("Bank Account"),
          cell: (row) =>
            row.file_ba ? (
              <a href={row.file_ba} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("Bank Guarantee"),
          cell: (row) =>
            row.file_bg ? (
              <a href={row.file_bg} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("CR Certificate"),
          cell: (row) =>
            row.file_cr ? (
              <a href={row.file_cr} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("payment type"),
          wrap: true,
          cell: (row) => {
            return row.pay_type;
            // if (row.auction.payment_type === "Both") {
            //   return "Online & Offline";
            // }
            // return row.auction.payment_type;
          },
        },
        {
          name: i18n.t("payment status"),
          wrap: true,
          cell: (row) =>
            row.payment != null && row.payment.status === "P" ? (
              <span className="text-success">Paid</span>
            ) : (
              <span className="text-danger"></span>
            ),
        },
        {
          name: i18n.t("status"),
          cell: (row) =>
            row.status === "A" ? (
              <span className="text-success">Approved</span>
            ) : (
              <span className="text-danger">Pending</span>
            ),
        },
        {
          name: i18n.t("Action"),
          button: true,
          cell: (row) => {
            return (
              <div className="d-flex">
                <button
                  className={
                    row.status === "A"
                      ? "btn btn-info btn-sm"
                      : "btn btn-success btn-sm"
                  }
                  onClick={(e) => {
                    if (row.status === "A") {
                      row.status = "N";
                      this.deActivate(row.id);
                    } else {
                      row.status = "A";
                      this.activate(row.id);
                    }
                  }}
                >
                  {this.state.row_updating && <Spinner animation="animation" />}
                  {!this.state.row_updating && (
                    <span>
                      {row.status === "A"
                        ? i18n.t("Disable")
                        : i18n.t("Enable")}
                    </span>
                  )}
                </button>
              </div>
            );
          },
        },
        {
          name: i18n.t("delete"),
          button: true,
          cell: (row) => {
            return (
              <div className="d-flex">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={(e) => {
                    if (row.status === "A") {
                      return;
                    } else {
                      row.status = "A";
                      this.deleteEnroll(row.id);
                    }
                  }}
                  disabled={row.status === "A"}
                >
                  {this.state.row_updating && <Spinner animation="animation" />}
                  {!this.state.row_updating && (
                    <span>
                      <i className="fa fa-times" />
                    </span>
                  )}
                  {/* {!this.state.row_updating && <span>{i18n.t("delete")}</span>} */}
                </button>
              </div>
            );
          },
        },
      ],
    };
    this.loadAllGroups = this.loadAllGroups.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.activateAllUserEnroll = this.activateAllUserEnroll.bind(this);
    this.activate = this.activate.bind(this);
    this.deActivate = this.deActivate.bind(this);
    this.deleteEnroll = this.deleteEnroll.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllOrganisation();
    this.loadAllGroups();
    this.loadAllEnrolledUsers(qstring);
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuctionsByGrp(grp) {
    let { input } = this.state;
    const response = await getAllAuctionsByGroup(input.group);
    let group_auctions = response.data;
    this.setState({ group_auctions });
  }

  async loadAllEnrolledUsers(q) {
    this.setState({ is_loading: true });
    if (this.state.input.group) {
      q = q + "&group=" + this.state.input.group;
    }
    if (this.state.input.user_name) {
      q = q + "&uname=" + this.state.input.user_name;
    }
    this.setState({ query: q });
    const response = await getEnrolledUsers(q);
    let users = response.data;
    users = users.map((item) => ({
      ...item,
      isChecked: false,
    }));
    let meta = response.meta;
    if (users) {
      this.setState({ is_loading: false });
      this.setState({ auctions: users });
      this.setState({ totalRows: meta.total });
      //this.setState({ users_filter: meta.users });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "client") {
      input.group = "";
      this.loadAllGroups();
    }
    if (event.target.name === "group") {
      this.loadAuctionsByGrp();
      this.loadCustomers();
    }
    if (event.target.name === "auction") {
      this.loadCustomers();
    }
    let query = this.state.query;
    let new_q = this.removeUrlParameter(query, "group", "uname");
    this.setState({ query: new_q });
  }

  removeUrlParameter(url, paramKey1, paramKey2) {
    url = url
      .replace(new RegExp("[?&]" + paramKey1 + "=[^&#]*(#.*)?$"), "$1")
      .replace(new RegExp("([?&])" + paramKey1 + "=[^&]*&"), "$1");
    url = url
      .replace(new RegExp("[?&]" + paramKey2 + "=[^&#]*(#.*)?$"), "$1")
      .replace(new RegExp("([?&])" + paramKey2 + "=[^&]*&"), "$1");
    return url;
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllEnrolledUsers(qstring);
  };

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  async loadCustomers() {
    let q = "";
    if (this.state.input.group) {
      q = q + "&group=" + this.state.input.group;
    }
    if (this.state.input.auction) {
      q = q + "&auction=" + this.state.input.auction;
    }
    const response = await getEnrolledUsersByGroup(q);
    let data = response.data;
    if (response.success) {
      const customers = data.map((item) => {
        let user = item.user;
        return {
          value: user.id,
          label: user.name + " (" + user.username + ")",
        };
      });
      this.setState({ customers: customers });
    }
  }

  activateAllUserEnroll() {
    swal("Are you sure.? Do you want to continue.?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then((value) => {
      if (value === "yes") {
        const response = activateAllUserEnroll({
          group: this.state.input.group,
        });
        swal(
          i18n.t("success"),
          "All enrollments activated for this user.",
          "success",
          {
            buttons: false,
            timer: 2000,
          }
        );
        this.loadAllEnrolledUsers(this.state.query);
      }
      return false;
    });
  }

  async activate(id) {
    this.setState({ row_updating: true });
    const response = await activateUserEnroll({ enroll_id: id });
    swal(i18n.t("success"), "User activated", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.setState({ row_updating: false });
        this.loadAllEnrolledUsers(this.state.query);
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  async deActivate(id) {
    this.setState({ row_updating: true });
    const response = await deActivateUserEnroll({ enroll_id: id });
    swal(i18n.t("success"), "User disabled", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.setState({ row_updating: false });
        this.loadAllEnrolledUsers(this.state.query);
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  async deleteEnroll(id) {
    swal("Are you sure.? Do you want to continue.?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then((value) => {
      if (value === "yes") {
        this.setState({ row_updating: true });
        const response = deleteEnrolledUser(id);
        if (response.success) {
          swal(i18n.t("success"), "User enrollment deleted.", "success", {
            buttons: false,
            timer: 2000,
          });
          this.loadAllEnrolledUsers(this.state.query);
          this.setState({ row_updating: false });
        } else {
          this.setState({ row_updating: false });
        }
      }
      return false;
    });
  }

  async deleteSelected() {
    let { selected_ids } = this.state;
    if (!selected_ids.length) {
      swal("Error", "Please select any id", "error");
      return;
    }
    swal("Are you sure.? Do you want to delete selected enrollments.?", {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "no") {
        return;
      }
      this.setState({ row_updating: true });
      let payload = {
        ids: selected_ids,
      };
      const response = await enrollBulkDelete(payload);
      if (response.success) {
        swal(i18n.t("success"), "Selected enrollments deleted.", "success", {
          buttons: false,
          timer: 2000,
        });
        this.loadAllEnrolledUsers(this.state.query);
        this.setState({ row_updating: false });
      } else {
        this.setState({ row_updating: false });
      }

      return false;
    });
  }

  async approveRejectSelected(is_approve) {
    let { selected_ids } = this.state;
    if (!selected_ids.length) {
      swal("Error", "Please select any id", "error");
      return;
    }
    let msg = is_approve
      ? "Are you sure.? Do you want to approve selected enrollments.?"
      : "Are you sure.? Do you want to reject selected enrollments.?";
    let msg_2 = is_approve
      ? "Selected enrollments approved."
      : "Selected enrollments rejected.";
    swal(msg, {
      buttons: {
        yes: {
          text: "Yes",
          value: "yes",
        },
        no: {
          text: "No",
          value: "no",
        },
      },
    }).then(async (value) => {
      if (value === "no") {
        return;
      }
      this.setState({ row_updating: true, bulk_updating: true });
      let payload = {
        ids: selected_ids,
        is_approve: is_approve,
      };
      const response = await approveOrRejectEnrolls(payload);
      console.log(response);
      if (response.success) {
        swal(i18n.t("success"), msg_2, "success", {
          buttons: false,
          timer: 2000,
        });
        this.loadAllEnrolledUsers(this.state.query);
        this.setState({ row_updating: false, bulk_updating: false });
      } else {
        this.setState({ row_updating: false, bulk_updating: false });
      }
      return false;
    });
  }

  render() {
    let {
      groups,
      customers,
      auctions,
      input,
      selected_ids,
      clients,
      group_auctions,
    } = this.state;
    let queryString = new URLSearchParams(input).toString();
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <div
                      className="d-flex justify-content-between w-50 w-mob-100 pe-5"
                      style={{ width: "100%" }}
                    >
                      <h3 className="card-title">
                        <span
                          style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                          onClick={() => {
                            this.setState({
                              hideSidebar: !this.state.hideSidebar,
                            });
                          }}
                        >
                          {this.state.hideSidebar && (
                            <i className="icon-size-actual"></i>
                          )}
                          {!this.state.hideSidebar && (
                            <i className="icon-size-fullscreen"></i>
                          )}
                        </span>
                        {i18n.t("enrolled_users")}
                      </h3>
                    </div>
                    <div
                      className="d-flex w-33 w-mob-100 gap-1"
                      style={{ width: "45%" }}
                    >
                      <div className="col-sm-3 col-md-3">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Client"
                          name="client"
                          value={this.state.input.client}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_client")}</option>
                          {clients.length > 0 &&
                            clients.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.organization_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-sm-3 col-md-3 mx-1">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Group"
                          name="group"
                          value={this.state.input.group}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_group")}</option>
                          {groups.length > 0 &&
                            groups.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.group_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-sm-3 col-md-3 mx-1">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Auction"
                          name="auction"
                          value={this.state.input.auction}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_auction")}</option>
                          {group_auctions.length > 0 &&
                            group_auctions.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.id} - {item.title}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {/* {i18n.t("username")} */}
                            Name/Username/email/phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="user_name"
                            placeholder="Search by Name, Username, email or phone"
                            value={this.state.input.user_name}
                            onChange={this.handleChange}
                          />
                          {/* <Select
                            className="rounded-5 "
                            placeholder={i18n.t("bidder")}
                            options={customers}
                            onChange={(e) => {
                              let { input } = this.state;
                              input.user_name = e.value;
                              this.setState({ input });
                            }}
                            name="user_name"
                            styles={selectStyles}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                          ></Select> */}
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("pay_type")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder={i18n.t("pay_type")}
                            name="pay_type"
                            value={this.state.input.pay_type}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("pay_type")}--</option>
                            <option value="online">{i18n.t("online")}</option>
                            <option value="offline">{i18n.t("offline")}</option>
                            <option value="wallet">{i18n.t("wallet")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("status")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder={i18n.t("pay_type")}
                            name="status"
                            value={this.state.input.status}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("status")}--</option>
                            <option value="A">{i18n.t("approved")}</option>
                            <option value="N">{i18n.t("not_approved")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group mt-4 mb-0">
                          <button
                            type="button"
                            className="btn btn-primary  mt-2"
                            onClick={(e) => {
                              this.setState({ selected_ids: [] });
                              let qstring = new URLSearchParams(
                                input
                              ).toString();
                              this.loadAllEnrolledUsers(qstring);
                            }}
                          >
                            <i className="fa fa-search" />
                            &nbsp;
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>

                    {!this.state.is_loading && auctions.length === 0 && (
                      <div className="text-danger text-center p-3 border border-danger">
                        {i18n.t("no_data_found")}
                      </div>
                    )}

                    <div className="table-responsive ">
                      {this.state.is_loading && <ListTable />}
                      {!this.state.is_loading && auctions.length > 0 && (
                        <div className="table-wrapper">
                          <div className="d-flex justify-content-between my-1">
                            {(user.role === "A" || user.role === "F") && (
                              <div>
                                {user.role === "A" && (
                                  <button
                                    className="btn btn-sm btn-outline-danger rounded-0"
                                    disabled={selected_ids.length < 1}
                                    onClick={() => {
                                      this.deleteSelected();
                                    }}
                                  >
                                    <i className="fa fa-trash" />
                                    &nbsp;{i18n.t("delete")}
                                  </button>
                                )}
                                <button
                                  className="btn btn-sm btn-outline-success rounded-0"
                                  disabled={selected_ids.length < 1}
                                  onClick={() => {
                                    this.approveRejectSelected(true);
                                  }}
                                >
                                  {this.state.bulk_updating ? (
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : (
                                    <i className="fa fa-check" />
                                  )}
                                  &nbsp;{i18n.t("approve")}
                                </button>
                                <button
                                  className="btn btn-sm btn-outline-warning rounded-0"
                                  disabled={selected_ids.length < 1}
                                  onClick={() => {
                                    this.approveRejectSelected(false);
                                  }}
                                >
                                  <i className="fa fa-ban" />
                                  &nbsp;{i18n.t("reject")}
                                </button>
                              </div>
                            )}

                            {this.state.input.group && auctions.length > 0 && (
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-info rounded-0"
                                onClick={this.activateAllUserEnroll}
                              >
                                Activate all enrolls in this group
                              </button>
                            )}
                            <a
                              className="btn btn-outline-danger btn-lg rounded-0"
                              href={
                                apiUrl +
                                `enrolled_users/excel?l_user=${user.id}&${queryString}`
                              }
                              target="_new"
                            >
                              <span className="text-danger">
                                <i className="fa fa-file-excel-o fa-2x" />
                              </span>
                            </a>
                          </div>
                          <DataTable
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={auctions}
                            customStyles={customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={this.state.input.limit}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            onChangeRowsPerPage={(size) => {
                              this.setState({ rows_per_page: 15 });
                              let { input } = this.state;
                              input.limit = size;
                              this.setState({ input });
                              this.handlePageChange(1);
                            }}
                            paginationComponentOptions={{
                              rowsPerPageText: "Enrolls per page:",
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                            selectableRows={
                              user.role === "A" || user.role === "F"
                            }
                            onSelectedRowsChange={(e) => {
                              let { selectedRows } = e;
                              const selectedIds = selectedRows.map(
                                (row) => row.id
                              );
                              this.setState({ selected_ids: selectedIds });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
