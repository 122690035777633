import React from "react";
import i18n from "../../../i18n";
import Slider from "react-slick";
import { SearchCard } from "./searchCard";
import { Link } from "react-router-dom";

let current_lng = localStorage.getItem("I18N_LANGUAGE");

var settings = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 10000,
  pauseOnHover: true,
  rtl: current_lng === "ar" ? true : false,
  appendDots: (dots) => (
    <div
      style={{
        backgroundColor: "#FFF",
        borderRadius: "25px",
        padding: "10px",
      }}
    >
      <ul style={{ margin: "0px" }}>
        {dots.map((item, index) => {
          console.log(item, index);
          return (
            <li key={index} className={item.props.className}>
              {item.props.children}
            </li>
          );
        })}
      </ul>
    </div>
  ),
  customPaging: (i) => <div className="slick-custom-dots">{i + 1}</div>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export class HomeSearchResults extends React.Component {
  constructor() {
    super();
    this.state = {
      auctions: [],
    };
  }

  componentDidMount() {
    let { auctions } = this.props;
    this.setState({ auctions });
  }

  render() {
    let { auctions, total } = this.props;
    return (
      <React.Fragment>
        <section className="mt-5 mb-5">
          <div className="container-fluid p-0">
            <div className="row mx-5 mx-1-mob">
              <div className="col-12">
                <div className="container-fluid w-90">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="d-flex justify-content-start gap-3 align-items-center">
                      <span
                        className="bg-primary text-white rounded-5bg-primary text-white rounded-5 px-2 py-1"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {total}
                      </span>
                      <b>{i18n.t("end_soon")}</b>
                    </h5>
                    <div>
                      <Link to="/auctions?is_end=1" className="text-primary">
                        {i18n.t("view_all")}&nbsp;
                        <i
                          className={
                            current_lng === "ar"
                              ? "fa fa-chevron-left"
                              : "fa fa-chevron-right"
                          }
                        />
                      </Link>
                    </div>
                  </div>
                  {auctions.length > 0 && (
                    <div className="row mt-4 gap-2">
                      <Slider {...settings} slidesToShow={4}>
                        {auctions.map((item, i) => {
                          return <SearchCard auction={item} />;
                        })}
                      </Slider>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
