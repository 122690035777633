import React from "react";
import i18n from "../../../i18n";
import swal from "sweetalert";
import { createWallet } from "../../../models/wallet";
import { convertToWords } from "../../utils/formatter";
import { apiUrl } from "../../../constants/global";
import { getLoggedUser } from "../../../state/user";
import { Modal } from "react-bootstrap";
import FileInputNew from "../../helpers/fileInputNew";
import { approveClientPayment } from "../../../models/client_payment";

const user = getLoggedUser();

const tableHeaderStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "0.5rem 0.5rem",
  border: "1px solid #efefef",
  fontSize: "0.8rem",
};
const tableCellStyle = {
  border: "1px solid #efefef",
  fontSize: "0.8rem",
};
const tableFooterStyle = {
  ...tableCellStyle,
  fontWeight: "bold",
  backgroundColor: "#f8f9fa",
  border: "1px solid rgb(208 208 208)",
  textAlign: "center",
};
const tableLastFooterStyle = {
  ...tableCellStyle,
  fontWeight: "bold",
  backgroundColor: "#f8f9fa",
  border: "1px solid #dedede",
};
const statuses = {
  A: i18n.t("approved"),
  R: i18n.t("rejected"),
  NU: i18n.t("pending"),
};
class AuctionTable extends React.Component {
  constructor() {
    super();
    this.state = {
      show_fund_screen: false,
      add: {},
      errors: {},
      selected_group: null,
      selected_auction: null,
      payment_popup: false,
      payment_approval_popup: false,
      payment: {},
      payment_approval: {},
      file_receipt: null,
      is_loading: false,
      fileInputs: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.handleApproveChange = this.handleApproveChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleClientPaymentSubmit = this.handleClientPaymentSubmit.bind(this);
    this.handlePaymentApproveSubmit =
      this.handlePaymentApproveSubmit.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  handleChange(event) {
    let { payment } = this.state;
    if (
      event.target.name === "debit" &&
      Number(event.target.value) > Number(payment.auction_total)
    ) {
      swal("Failed", "Amount should be less than total amount", "error");
      return;
    }
    payment[event.target.name] = event.target.value;
    this.setState({ payment });
  }

  handleApproveChange(event) {
    let { payment_approval } = this.state;
    payment_approval[event.target.name] = event.target.value;
    this.setState({ payment_approval });
  }

  handleAddChange(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_receipt") {
      this.setState({ file_receipt: event.target.files[0] });
    }
  };

  onFileChangeNew = (e, index) => {
    const updatedInputs = [...this.state.fileInputs];
    updatedInputs[index].file = e.target.files[0];
    this.setState({ fileInputs: updatedInputs });
  };

  addNewFileInput = () => {
    this.setState((prevState) => ({
      fileInputs: [
        ...prevState.fileInputs,
        { id: prevState.fileInputs.length + 1, file: null },
      ],
    }));
  };

  handleSelectAll = (event) => {
    const { auctions, onSelectAll } = this.props;
    const { checked } = event.target;
    onSelectAll(
      checked
        ? auctions.map((auction) => {
            console.log(auction.id, auction.withdraw_status);
            if (user.role !== "F") {
              return auction.approve_status === "P" ||
                auction.approve_status === null
                ? auction.id
                : false;
            } else {
              return auction.withdraw_status === "A" ? auction.id : false;
            }
          })
        : []
    );

    setTimeout(() => {
      let { payment_approval } = this.state;
      let { group } = this.props;
      let totalAmount = this.calculateTotalAmount();
      let vatAmt = (Number(totalAmount) * Number(group.vat)) / 100;
      payment_approval.amount = totalAmount;
      payment_approval.vat = vatAmt;
      this.setState({ payment_approval });
    }, 500);
  };

  handleSelectRow = (event, id) => {
    const { checked } = event.target;
    const { selectedRows, onSelectRow } = this.props;

    if (checked) {
      onSelectRow([...selectedRows, id]);
    } else {
      onSelectRow(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  handleRadioChange = (event) => {
    let value = JSON.parse(event.target.value);
    let { payment, display_amount, original_amount } = this.state;
    display_amount = value ? original_amount / 2 : original_amount;
    payment.debit = display_amount;
    this.setState({
      payment,
      display_amount,
    });
  };

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createWallet(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "Fund added successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              let { selected_group } = this.state;
              this.resetForm();
              this.props.onTrigger(selected_group);
              this.setState({ open_popup: false });
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {}
    }
  }

  validate() {
    let { add } = this.state;
    let errors = {};
    let isValid = true;
    if (!add["user"]) {
      isValid = false;
      errors["user"] = "Please select a user.";
    }
    if (!add["credit"]) {
      isValid = false;
      errors["credit"] = "Please enter an amount.";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  resetForm() {
    let { add } = this.state;
    add["name"] = "";
    add["mobile"] = "";
    add["email"] = "";
    add["username"] = "";
    add["country_code"] = "+968";
    add["password"] = "";
    add["role"] = "";
    this.setState({ add: add });
  }

  calculateTotalServiceCharge() {
    const { auctions, selectedRows } = this.props;
    return auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.service_charge) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
  }

  calculateTotalVatServiceCharge() {
    const { auctions, selectedRows } = this.props;
    return auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.vat_service_charge) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
  }

  calculateItemVatAmount() {
    const { auctions, selectedRows } = this.props;
    return auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.item_amount_vat) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
  }

  calculateTotalAmount() {
    const { auctions, selectedRows } = this.props;
    let totalAmount = auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.winner?.total_amount) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
    return totalAmount;
  }

  calculateTotalAmountWithdrawn() {
    const { auctions, selectedRows } = this.props;
    let totalAmount = auctions
      .filter((item) => selectedRows.includes(item.id))
      .reduce((total, item) => {
        const amount = parseFloat(item?.withdrawn_amount_client) || 0;
        return total + amount;
      }, 0)
      .toFixed(2);
    return totalAmount;
  }

  async handleClientPaymentSubmit(event) {
    const { selectedRows, group } = this.props;
    let { is_individual, selected_auction } = this.state;
    let auctions = selectedRows;
    if (is_individual && selected_auction) {
      auctions = [selected_auction];
    }
    event.preventDefault();
    console.log(this.state.payment);
    if (
      !this.state.payment.user ||
      !this.state.payment.debit ||
      !group.id
      //!this.state.file_receipt ||
      //!this.state.payment.account_number ||
      //!this.state.payment.bank ||
      //!this.state.payment.receipt_number
    ) {
      swal(i18n.t("failed"), i18n.t("all_mandatory"), "error");
      return;
    }
    try {
      this.setState({ is_loading: true });
      const formData = new FormData();
      formData.append("credit", 0);
      formData.append("debit", this.state.payment.debit);
      // formData.append("account_number", this.state.payment.account_number);
      // formData.append("bank", this.state.payment.bank);
      // formData.append("receipt_number", this.state.payment.receipt_number);
      formData.append("auction", selected_auction);
      formData.append("auctions", auctions);
      formData.append("group", group.id);
      formData.append("method", "offline");
      formData.append("status", "A");
      formData.append("type", "client_payment");
      formData.append("user", this.state.payment.user);
      formData.append("no_balance_update", false);

      // if (this.state.file_receipt) {
      //   formData.append(
      //     "file_receipt",
      //     this.state.file_receipt,
      //     this.state.file_receipt.name
      //   );
      // }

      const response = await createWallet(formData);
      if (response && response.success) {
        if (response.success) {
          swal(
            i18n.t("success"),
            i18n.t("withdraw_display_message"),
            "success"
          );
          this.setState({ is_loading: false });
        } else {
          swal(
            i18n.t("failed"),
            "Some errors occured. please contact administrator",
            "error"
          );
          this.setState({ is_loading: false });
          return;
        }
      } else {
        this.setState({ is_loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async handlePaymentApproveSubmit(event) {
    let { fileInputs, payment_approval } = this.state;
    let { selectedRows } = this.props;
    if (selectedRows.length < 1) {
      swal(i18n.t("failed"), "Missing auction IDs", "error");
    }
    const formData = new FormData();
    formData.append("auctions", selectedRows);
    formData.append("client_name", payment_approval.client_name);
    formData.append("account_number", payment_approval.bank_account);
    formData.append("bank_name", payment_approval.bank_name);
    if (this.state.file_receipt) {
      formData.append(
        "file_receipt",
        this.state.file_receipt,
        this.state.file_receipt.name
      );
    }
    if (fileInputs.length > 0) {
      fileInputs.forEach((input, index) => {
        if (input.file) {
          formData.append(
            `file_receipt_addl_${index}`,
            input.file,
            input.file.name
          );
        }
      });
    }

    const response = await approveClientPayment(formData);
    if (response.success) {
      swal(i18n.t("success"), "Approval completed!!!", "success");
      this.setState({ payment_approval_popup: false });
      return;
    }
    swal(i18n.t("failed"), "Some errors occured!!!", "error");
    return;
  }

  setPaymentApprovalForm(totalAmount, totalAmountWithdrawn) {
    let { group } = this.props;
    this.setState({ payment_approval_popup: true });
    this.setState({ selected_auction: null });
    let { payment_approval } = this.state;
    //payment_approval.amount = totalAmount;
    payment_approval.amount = totalAmountWithdrawn;
    payment_approval.is_service_charge_included =
      group.is_service_charge_included;
    payment_approval.is_service_charge_vat_included =
      group.is_service_charge_vat_included;
    payment_approval.is_vat_included = group.is_vat_included;
    let client_name = group.client_name
      ? group.client_name
      : group?.organization_info?.organization_name;
    let bank_name = group?.bank_name;
    let bank_account = group?.bank_account;
    let vat = group?.vat || 0;
    payment_approval.vat = Number(
      (Number(totalAmountWithdrawn) * Number(vat)) / 100
    ).toFixed(3);

    let service_charge = group?.service_charge || 0;
    let calc_service_charge = 0;
    if (typeof service_charge === "string" && service_charge.includes("%")) {
      calc_service_charge = Number(
        (Number(totalAmountWithdrawn) *
          Number(service_charge.replace("%", ""))) /
          100
      ).toFixed(3);
    } else {
      calc_service_charge = Number(
        (Number(totalAmountWithdrawn) * Number(service_charge)) / 100
      ).toFixed(3);
    }
    payment_approval.service_charge = calc_service_charge;
    payment_approval.vat_service_charge = Number(
      (Number(calc_service_charge) * Number(vat)) / 100
    ).toFixed(3);
    payment_approval.client_name = client_name;
    payment_approval.bank_name = bank_name;
    payment_approval.bank_account = bank_account;
    this.setState({
      payment_approval,
    });
  }

  removeFile = (index) => {
    this.setState((prevState) => ({
      fileInputs: prevState.fileInputs.filter((_, i) => i !== index),
    }));
  };

  render() {
    const { auctions, selectedRows, group } = this.props;
    const { is_loading } = this.state;
    const selectAll = selectedRows.length === auctions.length;
    const totalServiceChrg = this.calculateTotalServiceCharge();
    const totalVatServiceChrg = this.calculateTotalVatServiceCharge();
    const totalItemVat = this.calculateItemVatAmount();
    const totalAmount = this.calculateTotalAmount();
    const totalAmountWithdrawn = this.calculateTotalAmountWithdrawn();

    return (
      <>
        <div
          className="rounded-3 table-responsive"
          style={{
            maxHeight: "300px",
            overflow: "scroll",
            padding: "1rem",
          }}
        >
          <table className="table table-stripped table-sm">
            <thead>
              <tr>
                <th style={tableHeaderStyle}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={this.handleSelectAll}
                  />
                </th>
                <th style={tableHeaderStyle}>{i18n.t("sl_no")}</th>
                <th style={tableHeaderStyle}>{i18n.t("ID")}</th>
                <th style={tableHeaderStyle}>{i18n.t("auction")}</th>
                <th style={tableHeaderStyle}>{i18n.t("client")}</th>
                <th style={tableHeaderStyle}>{i18n.t("winner")}</th>
                <th style={tableHeaderStyle}>{i18n.t("latest_bid_amount")}</th>
                <th style={tableHeaderStyle}>
                  {i18n.t("latest_bid_amount")}+{i18n.t("vat")}
                </th>
                <th style={tableHeaderStyle}>
                  {i18n.t("wallet")}-{i18n.t("balance")}
                </th>
                <th style={tableHeaderStyle}>{i18n.t("service_charge")}</th>
                <th style={tableHeaderStyle}>{i18n.t("vat_service_charge")}</th>
                <th style={tableHeaderStyle}>{i18n.t("item_amount_vat")}</th>
                <th style={tableHeaderStyle}>
                  {i18n.t("total_amount")}-{i18n.t("bidder")}
                </th>
                <th style={tableHeaderStyle}>{i18n.t("status")}</th>
                {user.role === "F" && (
                  <>
                    <th style={tableHeaderStyle}>
                      {i18n.t("withdraw")} {i18n.t("amount")}
                    </th>

                    <th style={tableHeaderStyle}>
                      {i18n.t("withdraw")} {i18n.t("status")}
                    </th>
                  </>
                )}
                <th style={tableHeaderStyle}>{i18n.t("download")}</th>
                {user.role === "F" && (
                  <th style={tableHeaderStyle}>{i18n.t("action")}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {auctions.map((item, i) => (
                <tr key={item.id}>
                  <td style={tableCellStyle}>
                    {user.role !== "F" && (
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={(e) => this.handleSelectRow(e, item.id)}
                        disabled={
                          item.approve_status === "A" ||
                          item.approve_status === "R"
                            ? true
                            : false
                        }
                      />
                    )}
                    {user.role === "F" && (
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item.id)}
                        onChange={(e) => this.handleSelectRow(e, item.id)}
                        disabled={item.withdraw_status !== "A" ? true : false}
                      />
                    )}
                  </td>
                  <td style={tableCellStyle}>{i + 1}</td>
                  <td style={tableCellStyle}>{item.auction_number}</td>
                  <td style={{ ...tableCellStyle, textWrap: "nowrap" }}>
                    {item.title}
                  </td>
                  <td style={{ ...tableCellStyle, textWrap: "nowrap" }}>
                    {item?.organizationDetails?.organization_name}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.name ? item?.winner?.name : "N/A"}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.user_id ? item?.winner?.bid_amount : "N/A"}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.user_id
                      ? (
                          Number(item?.winner?.bid_amount) +
                          Number(item?.winner?.item_amount_vat)
                        ).toFixed(3)
                      : "N/A"}
                  </td>
                  <td style={tableCellStyle}>
                    <div
                      className={
                        item?.winner?.total_amount > item?.winner?.wallet_amount
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {item?.winner?.wallet_amount || Number("0").toFixed(2)}
                    </div>
                  </td>
                  <td style={tableCellStyle}>{item?.winner?.service_charge}</td>
                  <td style={tableCellStyle}>
                    {item?.winner?.vat_service_charge}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.item_amount_vat}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.winner?.total_amount.toFixed(3)}
                  </td>
                  <td style={tableCellStyle}>
                    {item?.approve_status
                      ? statuses[item?.approve_status]
                      : i18n.t("pending")}
                  </td>
                  {user.role === "F" && (
                    <>
                      <td style={tableCellStyle}>
                        {item?.withdrawn_amount_client || "0.00"}
                      </td>
                      <td style={tableCellStyle}>
                        {item.withdraw_status === "A"
                          ? i18n.t("approved")
                          : i18n.t("pending")}
                      </td>
                    </>
                  )}
                  <td>
                    <div className="d-flex gap-1 justify-content-end mb-1 mx-1 mt-1">
                      {item?.approve_status === "A" && (
                        <a
                          className="btn btn-outline-danger  p-1 px-2"
                          href={`${apiUrl}auction_approval_ind_inv/pdf?auc=${item?.id}`}
                          target="_new"
                        >
                          <i className="fa fa-file-pdf-o" />
                          &nbsp;{i18n.t("invoice")} -{" "}
                          {item?.winner?.invoice_sequence}
                        </a>
                      )}
                      {item?.approve_status !== "A" && (
                        <button
                          className="btn btn-outline-light p-1 px-2"
                          type="button"
                          disabled={true}
                        >
                          <i className="fa fa-file-pdf-o" />
                          &nbsp;{i18n.t("invoice")}
                        </button>
                      )}
                    </div>
                  </td>

                  {user.role === "F" && (
                    <td>
                      <div className="d-flex gap-1 justify-content-end mb-1 mx-1 mt-1">
                        {item?.approve_status === "A" && (
                          <button
                            className="btn btn-outline-warning p-1 px-2"
                            type="button"
                            disabled={
                              item?.winner?.total_amount >
                              item?.winner?.wallet_amount
                                ? true
                                : false
                            }
                            onClick={() => {
                              this.setState({ payment_popup: true });
                              this.setState({ is_individual: true });
                              this.setState({ selected_auction: item?.id });
                              let auction_total = (
                                Number(item?.winner?.total_amount) -
                                Number(item?.withdrawn_amount_client)
                              ).toFixed(3);
                              let { payment } = this.state;
                              payment.actual_total = Number(
                                item?.winner?.total_amount
                              ).toFixed(3);
                              payment.user = item?.winner?.user_id;
                              payment.auction_total = auction_total;
                              payment.debit = auction_total;
                              payment.withdrawn_amount_client = Number(
                                item?.withdrawn_amount_client
                              ).toFixed(3);
                              this.setState({
                                payment,
                                display_amount: auction_total,
                                original_amount: auction_total,
                              });
                            }}
                          >
                            <i className="fa fa-money" />
                            &nbsp;{i18n.t("wallet_deduction")}
                          </button>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
        </div>

        {selectedRows.length > 0 && (
          <table className="table table-stripped table-sm">
            <tfoot>
              <tr>
                <td style={tableFooterStyle} align="right">
                  {i18n.t("total_service_charge")}:{totalServiceChrg}
                </td>
                <td style={tableFooterStyle}>
                  {i18n.t("total_vat_service_amount")}:{totalVatServiceChrg}
                </td>
                <td style={tableFooterStyle}>
                  {i18n.t("total_vat_amount")}:{totalItemVat}
                </td>
                <td style={tableFooterStyle}>
                  {i18n.t("total_amount")} {i18n.t("withdraw")}:
                  {totalAmountWithdrawn}
                </td>
                <td style={tableFooterStyle}>
                  {i18n.t("total_amount")}:{totalAmount}
                </td>
              </tr>
              <tr>
                <td colSpan="4" style={tableLastFooterStyle} align="center">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{convertToWords(totalAmount).toUpperCase()}</div>
                    {selectedRows.length > 0 && user.role === "F" && (
                      <div className="d-flex">
                        <button
                          className="btn btn-outline-warning btn-sm"
                          onClick={() => {
                            this.setPaymentApprovalForm(
                              totalAmount,
                              totalAmountWithdrawn
                            );
                          }}
                        >
                          {i18n.t("payment_approval")}({selectedRows.length}{" "}
                          {i18n.t("auctions")})
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        )}

        <Modal
          show={this.state.payment_popup}
          onHide={() => this.setState({ payment_popup: false })}
          size=""
          className="bg-info-subtle"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-lg"
              style={{ width: "100%" }}
            >
              {i18n.t("wallet_deduction")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 mt-1">
                <label>{i18n.t("actual_total")}</label>
                <div className="form-control bg-light">
                  {this.state.payment.actual_total}
                </div>
              </div>
              <div className="col-12 mt-1">
                <label>{i18n.t("total_amount_after_paid")}</label>
                <div className="form-control bg-light">
                  {this.state.payment.auction_total}
                </div>
              </div>
              <div className="col-12 mt-1">
                <label>{i18n.t("withdrawn_amount")}</label>
                <div className="form-control bg-light">
                  {this.state.payment.withdrawn_amount_client}
                </div>
              </div>
              <div className="col-12 mt-1">
                <label>
                  {i18n.t("withdraw")} {i18n.t("amount")}
                </label>
                <input
                  type="text"
                  name="debit"
                  value={this.state.payment.debit}
                  onChange={this.handleChange}
                  className="form-control"
                  readOnly={false}
                />
              </div>
              {/* <div className="col-6 mt-1">
                <label>{i18n.t("account_number")}</label>
                <input
                  type="text"
                  name="account_number"
                  value={this.state.payment.account_number}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div> */}

              {/* <div className="col-6 mt-1">
                <label>{i18n.t("bank")}</label>
                <input
                  type="text"
                  name="bank"
                  value={this.state.payment.bank}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div> */}

              {/* <div className="col-6 mt-1">
                <label>
                  {i18n.t("receipt")} {i18n.t("number")}
                </label>
                <input
                  type="text"
                  name="receipt_number"
                  value={this.state.payment.receipt_number}
                  onChange={this.handleChange}
                  className="form-control"
                />
              </div> */}

              {/* <div className="col-12 mt-1">
                <div className="custom-text3">{i18n.t("receipt")}</div>
                <div className="form-group">
                  <FileInputNew
                    wrapperClass=""
                    placeholder={i18n.t("choose_file")}
                    id="file_receipt"
                    name="file_receipt"
                    onChange={this.onFileChange}
                    className="form-control"
                  />
                </div>
              </div> */}

              <div className="row">
                <div class="d-flex align-items-center justify-content-center mt-2">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    <i className="fa fa-times-circle" />
                    &nbsp;
                    {i18n.t("cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleClientPaymentSubmit}
                  >
                    {is_loading && <i className="fa fa-spinner fa-spin" />}
                    {!is_loading && <i className="fa fa-save" />}
                    &nbsp;
                    {i18n.t("submit")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.payment_approval_popup}
          onHide={() => this.setState({ payment_approval_popup: false })}
          size="lg"
          className="bg-dark-subtle"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-modal-sizes-title-lg"
              style={{ width: "100%" }}
            >
              {i18n.t("payment_approval")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-6 mt-1">
                <label>{i18n.t("client_name")}</label>
                <div
                  className="form-control bg-light"
                  style={{ minHeight: "38px" }}
                >
                  {this.state.payment_approval.client_name}
                </div>
              </div>
              <div className="col-6 mt-1">
                <label>{i18n.t("bank")}</label>
                <div
                  className="form-control bg-light"
                  style={{ minHeight: "38px" }}
                >
                  {this.state.payment_approval.bank_name}
                </div>
              </div>

              <div className="col-6 mt-1">
                <label>{i18n.t("account_number")}</label>
                <div
                  className="form-control bg-light"
                  style={{ minHeight: "38px" }}
                >
                  {this.state.payment_approval.bank_account}
                </div>
              </div>
              <div className="col-6 mt-1">
                <label>{i18n.t("total_amount")}</label>
                <input
                  type="text"
                  name="amount"
                  value={this.state.payment_approval.amount}
                  onChange={this.handleApproveChange}
                  className="form-control"
                  readOnly={true}
                />
              </div>

              {this.state.payment_approval.is_vat_included && (
                <div className="col-6 mt-1">
                  <label>
                    {i18n.t("vat")}({group.vat}%)
                  </label>
                  <input
                    type="text"
                    name="vat"
                    value={this.state.payment_approval.vat}
                    onChange={this.handleApproveChange}
                    className="form-control"
                    readOnly={true}
                  />
                </div>
              )}

              {this.state.payment_approval.is_service_charge_included && (
                <div className="col-6 mt-1">
                  <label>{i18n.t("service_charge")}</label>
                  <input
                    type="text"
                    name="service_charge"
                    value={this.state.payment_approval.service_charge}
                    onChange={this.handleApproveChange}
                    className="form-control"
                    readOnly={true}
                  />
                </div>
              )}

              {this.state.payment_approval.is_service_charge_vat_included && (
                <div className="col-6 mt-1">
                  <label>
                    {i18n.t("vat")}-{i18n.t("service_charge")}
                  </label>
                  <input
                    type="text"
                    name="vat_service_charge"
                    value={this.state.payment_approval.vat_service_charge}
                    onChange={this.handleApproveChange}
                    className="form-control"
                  />
                </div>
              )}

              <div className="col-12 mt-1">
                <div className="custom-text3">{i18n.t("attatchment")}</div>
                <div className="form-group">
                  <FileInputNew
                    wrapperClass=""
                    placeholder={i18n.t("choose_file")}
                    id="file_receipt"
                    name="file_receipt"
                    onChange={this.onFileChange}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row">
                <label className="custom-text3">
                  {i18n.t("additional_doc")}
                </label>
                {this.state.fileInputs.map((input, index) => (
                  <div key={input.id} className="row mb-2">
                    <div className="col-3"></div>
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <div className="flex-grow-1 me-2">
                        <FileInputNew
                          wrapperClass={`bg-white ${
                            !this.state.fileInputs[index].file ? "is-empty" : ""
                          }`}
                          className="form-control custom-input "
                          placeholder={i18n.t("choose_file")}
                          help_text=""
                          name="file_receipt_addl"
                          id={`file_receipt_addl_${index}`}
                          onChange={(e) => this.onFileChangeNew(e, index)}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm rounded-circle"
                        style={{ padding: "0px 6px" }}
                        onClick={() => this.removeFile(index)}
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col-12 d-flex justify-content-start align-items-center">
                    <button
                      className="btn btn-sm btn-info"
                      style={{ height: "90%" }}
                      onClick={this.addNewFileInput}
                      type="button"
                    >
                      <i className="fa fa-plus" />
                      &nbsp;{i18n.t("add_new")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="d-flex align-items-center justify-content-center mt-2">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    <i className="fa fa-times-circle" />
                    &nbsp;
                    {i18n.t("cancel")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handlePaymentApproveSubmit}
                  >
                    {is_loading && <i className="fa fa-spinner fa-spin" />}
                    {!is_loading && <i className="fa fa-save" />}
                    &nbsp;
                    {i18n.t("submit")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default AuctionTable;
