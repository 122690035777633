import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import i18n from "../../i18n";
import { ActiveAuctions } from "./auctionsCategory/active";
import { PreviousAuctions } from "./auctionsCategory/previous";
import { FeaturedAuctions } from "./auctionsCategory/featured";
import { UpcomingAuctions } from "./auctionsCategory/upcoming";
import { DirectAuctions } from "./auctionsCategory/direct";
import { AllAuctions } from "./auctionsCategory/all";
//import { HotMarketAuctions } from "./helpers/hotMarketAuction";
import { getAuctionChartData } from "../../models/auctions";
export class CategoryAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedTab: "active",
      active_count: 0,
      upcoming_count: 0,
      firstload: true,
      secondLoad: false,
      tabs: [
        {
          name: i18n.t("active_auctions"),
          tab: "active",
          icon: process.env.PUBLIC_URL + "/assets/icons/active_auction.png",
          count_color: "#FFFFFF",
        },
        {
          name: i18n.t("upcoming_auctions"),
          tab: "upcoming",
          icon: process.env.PUBLIC_URL + "/assets/icons/upcoming_auction.png",
          count_color: "#FFFFFF",
        },
        // {
        //   name: i18n.t("featured_auctions"),
        //   tab: "featured",
        //   icon: process.env.PUBLIC_URL + "/assets/icons/featured_auction.png",
        //   count_color: "#FF4664",
        // },
        {
          name: i18n.t("previous_auctions"),
          tab: "previous",
          icon: process.env.PUBLIC_URL + "/assets/icons/expired_auction.png",
          count_color: "#FFFFFF",
        },
        // {
        //   name: i18n.t("all_auctions"),
        //   tab: "all",
        //   icon: process.env.PUBLIC_URL + "/assets/icons/all_auctions.png",
        //   count_color: "#8247FF",
        // },
        {
          name: i18n.t("direct_sale"),
          tab: "direct",
          icon: process.env.PUBLIC_URL + "/assets/icons/direct_auctions.png",
          count_color: "#FFFFFF",
        },
      ],
      lightboxImages: [],
      is_active_light_box: false,
      counts: {},
      is_loading_1: false,
    };
    this.handleTabs = this.handleTabs.bind(this);
    this.getActiveCount = this.getActiveCount.bind(this);
    this.getUpcomingCount = this.getUpcomingCount.bind(this);
    this.getImagePopup = this.getImagePopup.bind(this);
    this.loadAuctionsCount = this.loadAuctionsCount.bind(this);
  }

  componentDidMount() {
    this.loadAuctionsCount();
  }

  handleTabs(tabname) {
    console.log(tabname);
    this.setState({ selectedTab: tabname });
  }

  getActiveCount(count) {
    if (count === 0 && this.state.firstload) {
      this.setState({
        selectedTab: "upcoming",
        firstload: false,
        secondLoad: true,
      });
    }
  }

  getUpcomingCount(count) {
    console.log(count);
    if (count === 0 && this.state.secondLoad) {
      this.setState({ selectedTab: "previous", secondLoad: false });
    }
  }

  getImagePopup(active, images) {
    let lightboxImages = [];
    if (images) {
      images.forEach(function (arrayItem) {
        lightboxImages.push({
          url: arrayItem.image,
        });
      });
    }
    this.setState({
      is_active_light_box: active,
      lightboxImages: lightboxImages,
    });
  }

  async loadAuctionsCount() {
    this.setState({ is_loading_1: true });
    let counts = {};
    const response = await getAuctionChartData("main");
    let data = response.data;
    if (response) {
      counts = {
        active: data.active,
        upcoming: data.upcoming,
        previous: data.previous,
        featured: data.featured,
        direct: data.direct,
        all: Number(data.previous) + Number(data.active),
      };
      this.setState({
        is_loading_1: false,
        counts,
      });
    }
  }

  render() {
    let selectedTab = this.state.selectedTab;
    let tabs = this.state.tabs;
    let currTabObj = tabs.find((obj) => {
      return obj.tab === selectedTab;
    });
    let tabName = currTabObj.name;
    let tabImage = currTabObj.icon;
    return (
      <React.Fragment>
        <section className="mt-2 mb-2 overflow-hidden">
          <div className="container-fluid p-0">
            <div className="row mx-5">
              <div className="col-12">
                <div className="container-fluid nav-menu">
                  <div className="d-flex auction-tabs sky-blue-div-II gap-1">
                    {tabs.map((item, i) => {
                      return (
                        <div
                          className="flex-fill col-md-1"
                          key={i}
                          onClick={() => {
                            this.handleTabs(item.tab);
                          }}
                        >
                          <div
                            class={
                              selectedTab === item.tab
                                ? "nav-item auction-tab-item is-active"
                                : "nav-item auction-tab-item"
                            }
                          >
                            {/* <div className="image-container">
                              <img
                                src={item.icon}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                            </div> */}
                            <div className="auction-container">
                              <span className="auction-container-number ">
                                {this.state.is_loading_1
                                  ? "0"
                                  : this.state.counts[item.tab]}
                              </span>
                            </div>
                            <a
                              href={true}
                              className={
                                selectedTab === item.tab ? "active show" : ""
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {item.name}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                    {/* <div
                    className="d-flex align-items-center justify-content-center navy-blue-div-II  col-md-1 search-box"
                    style={{
                      backgroundColor: "#1A1E42",
                      marginLeft: "10px",
                    }}
                  >
                    <div className="nav-item mt-2" style={{ height: "36px" }}>
                      <div className="image-container">
                        <img
                          src="./assets/images/client.png"
                          height="20"
                          width="17"
                          alt=""
                        />
                      </div>
                      <a
                        href={false}
                        style={{
                          textDecoration: "none",
                          color: "#FFFFFF",
                        }}
                      >
                        Client Search
                      </a>
                    </div>
                  </div> */}
                  </div>
                </div>

                <div className="mobile-blue-nav-container">
                  <div className="nav-dropdown">
                    <div className="nav-dropbtn">
                      <Dropdown className="d-inline mx-2 d-mob">
                        <Dropdown.Toggle
                          id="dropdown-autoclose-true"
                          className="btn-block"
                          variant="primary"
                        >
                          {/* <img
                            src={tabImage}
                            alt=""
                            style={{ width: "20px", height: "20px" }}
                          />
                          &nbsp; */}
                          {tabName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {tabs.map((item, i) => {
                            return (
                              <Dropdown.Item
                                key={i}
                                href="#"
                                onClick={() => {
                                  this.handleTabs(item.tab);
                                }}
                              >
                                <div className="d-flex justify-content-between">
                                  {/* <img
                                    src={item.icon}
                                    alt=""
                                    style={{ width: "20px", height: "20px" }}
                                  /> */}
                                  <span className="px-1">{item.name}</span>
                                  <span className="auction-container-number rounded-4">
                                    {this.state.is_loading_1
                                      ? "0"
                                      : this.state.counts[item.tab]}
                                  </span>
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-12">
              <HotMarketAuctions />
            </div> */}

            <div className="col-md-12">
              <div className="items-gallery">
                <div className="items-blog-tab text-center">
                  <div className="tab-content auc-card-wrapper">
                    {this.state.selectedTab === "all" && (
                      <div className="tab-pane active">
                        <AllAuctions
                          type={this.state.selectedTab}
                          getActiveCount={this.getActiveCount}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "active" && (
                      <div className="tab-pane active">
                        <ActiveAuctions
                          type={this.state.selectedTab}
                          getActiveCount={this.getActiveCount}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "upcoming" && (
                      <div className="tab-pane active">
                        <UpcomingAuctions
                          type={this.state.selectedTab}
                          getUpcomingCount={this.getUpcomingCount}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "featured" && (
                      <div className="tab-pane active">
                        <FeaturedAuctions
                          type={this.state.selectedTab}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "previous" && (
                      <div className="tab-pane active">
                        <PreviousAuctions
                          type={this.state.selectedTab}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}

                    {this.state.selectedTab === "direct" && (
                      <div className="tab-pane active">
                        <DirectAuctions
                          type={this.state.selectedTab}
                          getImagePopup={this.getImagePopup}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.is_active_light_box && (
          <Lightbox
            images={this.state.lightboxImages}
            onClose={() => {
              this.setState({ is_active_light_box: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
