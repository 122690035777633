import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import i18n from "../../i18n";
import { Link } from "react-router-dom";
import { getUserInfo } from "../../models/users";
import { getLoggedUser } from "../../state/user";
import { createWallet, getAllWallets, updateWallet } from "../../models/wallet";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import { HomeBanner } from "../home/homeBanner";
import { CustomTitle } from "../helpers/customTitle";
import { SideBar } from "./sidebar";
import { getAllOrganization } from "../../models/organization";
import { getAllGroups } from "../../models/groups";

const user = getLoggedUser();
const wallet_types = {
  admin: i18n.t("added_by_admin"),
  sitevisit: i18n.t("site_visit"),
  enroll: i18n.t("enroll"),
  wallet_recharge: i18n.t("wallet_recharge"),
  withdraw: i18n.t("withdraw"),
};
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};
const wallet_statuses = {
  A: i18n.t("approved"),
  P: "Waiting for admin approval",
  F: i18n.t("waiting_for_finance_approval"),
  W: "Withdraw requested",
  WA: i18n.t("withdraw_request_approved_by_admin"),
  R: "Refunded",
};
export class Wallet extends React.Component {
  constructor() {
    super();
    this.state = {
      withdraw_popup: false,
      is_loading: false,
      user_info: {},
      transactions: [],
      input: {},
      balance: 0,
      credit_sum: 0,
      debit_sum: 0,
      balance_sum: 0,
      hold_sum: 0,
      client_due: 0,
      list: {
        page: 1,
        limit: 15,
      },
      is_wthdraw_req_sending: false,
      clients: [],
      groups: [],
      columns: [
        {
          name: "ID",
          cell: (row) => row.id,
          sortable: true,
          wrap: true,
          width: "70px",
        },
        {
          name: i18n.t("transaction"),
          sortable: true,
          cell: (row) => row.transaction_id,
        },
        {
          name: i18n.t("credit"),
          cell: (row) => row.credit,
        },
        {
          name: i18n.t("debit"),
          cell: (row) => row.debit,
        },
        {
          name: i18n.t("reference"),
          cell: (row) => row.reference,
          wrap: true,
        },
        {
          name: i18n.t("type"),
          wrap: true,
          cell: (row) => `${row.type ? wallet_types[row.type] : ""}`,
        },
        {
          name: i18n.t("status"),
          cell: (row) => `${row.status ? wallet_statuses[row.status] : ""}`,
        },
        {
          name: i18n.t("date"),
          wrap: true,
          cell: (row) => row.created_at,
        },
      ],
    };
    this.updateWalletRow = this.updateWalletRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    this.loadAllOrganisation();
    this.loadAllGroups();
    this.checkUserWallet();
    this.loadAllTransactions();
  }

  handlePageChange = (page) => {
    let { list } = this.state;
    let currentPage = page;
    list["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ list: list });
    let qstring = new URLSearchParams(list).toString();
    this.setState({ query: qstring });
    this.loadAllTransactions();
  };

  handleChange(event) {
    let { user_info, input } = this.state;
    if (
      isNaN(event.target.value) ||
      (event.target.value && event.target.value < 1)
    ) {
      swal(i18n.t("Warning"), "Invalid amount", "warning");
      return;
    }
    console.log(event.target.value, user_info.wallet_amount);
    if (Number(event.target.value) > Number(user_info.wallet_amount)) {
      swal(
        i18n.t("Warning"),
        `Invalid amount!!!. Amount should be less than ${user_info.wallet_amount}`,
        "warning"
      );
      return;
    }
    input[event.target.name] = event.target.value;
    this.setState({ input });
    let balance = user_info.wallet_amount - event.target.value;
    this.setState({ balance: balance });
  }

  handleSearchChange(event) {
    let { list } = this.state;
    list[event.target.name] = event.target.value;
    this.setState({ list });
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllGroups() {
    let q = "";
    let { list } = this.state;
    if (list.client) {
      q += `organization=${list.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async updateWalletRow(status, row, e) {
    e.preventDefault();
    swal({
      title: i18n.t("warn_message_withdraw_amt"),
      text: i18n.t("warn_message_desc_withdraw_amt"),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18n.t("confirm"),
    }).then(async function () {
      let payload = { status: status, id: row.id, user: user.id };
      const response = await updateWallet(payload, row.id);
      if (response && response.success) {
        swal(i18n.t("success"), i18n.t("withdraw_request_message"), "success");
        this.loadAllTransactions();
      }
    });
  }

  async checkUserWallet() {
    this.setState({ is_loading: true });
    const response = await getUserInfo(user.id);
    if (response && response.success) {
      let data = response.data;
      this.setState({ user_info: data });
      this.setState({ is_loading: false });
      let { input } = this.state;
      input.amount = data.wallet_amount;
      this.setState({ input });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async loadAllTransactions() {
    let { list } = this.state;
    let qstring = new URLSearchParams(list).toString();
    this.setState({ query: qstring });
    let q = `customer=${user.id}&${qstring}`;
    this.setState({ is_loading: true });
    const response = await getAllWallets(q);
    let transactions = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ transactions: transactions });
      this.setState({ totalRows: meta.total });
      this.setState({
        credit_sum: meta["credit_sum"],
        debit_sum: meta["debit_sum"],
        balance_sum: meta["balance_sum"],
        hold_sum: meta["hold_sum"],
        client_due: meta["client_due"],
      });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ is_wthdraw_req_sending: true });
    if (this.validate()) {
      try {
        let payload = {
          no_balance_update: false,
          user: user.id,
          type: "withdraw",
          debit: this.state.input.amount,
          credit: 0,
          status: "W",
          method: "offline",
        };
        const response = await createWallet(payload);
        if (response.success) {
          swal(
            i18n.t("success"),
            i18n.t("withdraw_display_message"),
            "success",
            {
              buttons: false,
              timer: 10000,
            }
          )
            .then((value) => {
              this.setState({ is_wthdraw_req_sending: false });
              this.loadAllTransactions();
              this.checkUserWallet();
              this.setState({ withdraw_popup: false });
            })
            .catch((response) => {
              this.setState({ is_wthdraw_req_sending: false });
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
          this.setState({ is_wthdraw_req_sending: false });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["amount"]) {
      isValid = false;
      errors["user"] = "Enter an amount";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    let { user_info, transactions, groups, clients } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0 wallet-dash">
                  <div className="container">
                    <div className="row">
                      {this.state.is_loading && (
                        <div className="col-lg-12 col-xl-12 col-md-12 text-center">
                          <Spinner animation="grow text-primary" size="lg" />
                        </div>
                      )}
                      {!this.state.is_loading && (
                        <div className="col-lg-12 col-xl-12 col-md-12 text-center mt-3">
                          <div className="row">
                            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                              <div className="card border rounded-4 p-2">
                                <div className="section-header">
                                  <div className="title-div p-2">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/mzadcom-wallet-icon.png"
                                      }
                                      alt="wallet"
                                    />
                                    <span>{i18n.t("my_wallet")}</span>
                                  </div>
                                  <Link
                                    to="/wallet-manage"
                                    className="btn  btn-sm btn-warning text-white"
                                  >
                                    {i18n.t("add_fund")}
                                  </Link>
                                </div>

                                <div class="p-0 px-2 payment-main mt-0">
                                  <div class="d-flex align-items-center gap-1 justify-content-between column-direction">
                                    <div class="user-brd rounded-4 p-2 br-div w-33 w-mob-100">
                                      <div class="d-flex justify-content-center align-items-center">
                                        <div>
                                          <div class="text-black fs12 text-justify">
                                            {i18n.t("balance")}
                                            <img
                                              class="px-2"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/refresh-blue-icon.png"
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div class="d-flex align-items-center gap-2 mt-1">
                                            <div class="fs24 text-blue fwb">
                                              <span>
                                                {user_info.wallet_amount}
                                              </span>
                                            </div>
                                            <div class="fs16">
                                              <span>{i18n.t("omr")}</span>
                                            </div>
                                            <div class="fs12 text-gray d-mob-none">
                                              <span>Updated 3 min ago</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="pnow-div"
                                          style={{ paddingLeft: "5px" }}
                                        >
                                          <button
                                            className="btn  btn-sm btn-warning text-white mt-3"
                                            onClick={() => {
                                              if (user_info.wallet_amount < 1) {
                                                return;
                                              }
                                              this.setState({
                                                withdraw_popup: true,
                                              });
                                            }}
                                          >
                                            <span className="d-mob-none">
                                              <i className="fa fa-money" />
                                              &nbsp;
                                            </span>
                                            <span>{i18n.t("withdraw")}</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex gap-2 w-50 w-mob-100 mt-1">
                                      <div
                                        className="col-sm-6 col-md-5"
                                        style={{ width: "50%" }}
                                      >
                                        <select
                                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                                          data-placeholder="Select Client"
                                          name="client"
                                          value={this.state.list.client}
                                          onChange={this.handleSearchChange}
                                        >
                                          <option value="">
                                            {i18n.t("select_client")}
                                          </option>
                                          {clients.length > 0 &&
                                            clients.map((item) => {
                                              return (
                                                <option
                                                  value={item.id}
                                                  key={item.id}
                                                >
                                                  {item.organization_name}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                      <div className="col-sm-6 col-md-5">
                                        <select
                                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                                          data-placeholder="Select Group"
                                          name="group"
                                          value={this.state.list.group}
                                          onChange={this.handleSearchChange}
                                        >
                                          <option value="">
                                            {i18n.t("select_group")}
                                          </option>
                                          {groups.length > 0 &&
                                            groups.map((item) => {
                                              return (
                                                <option
                                                  value={item.id}
                                                  key={item.id}
                                                >
                                                  {item.group_name}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="dropdown-section row wallet-dropdown-section">
                                  <div className="col-md-3 col-mob">
                                    <input
                                      type="date"
                                      className="form-control input text-black"
                                      placeholder={i18n.t("from_date")}
                                      name="fdate"
                                      value={this.state.list.fdate}
                                      onChange={this.handleSearchChange}
                                    />
                                  </div>
                                  <div className="col-md-3 col-mob">
                                    <input
                                      type="date"
                                      className="form-control input text-black"
                                      placeholder={i18n.t("to_date")}
                                      name="tdate"
                                      value={this.state.list.tdate}
                                      onChange={this.handleSearchChange}
                                    />
                                  </div>
                                  <div className="col-md-2 col-mob">
                                    <select
                                      className="form-select select text-black"
                                      name="pay_type"
                                      value={this.state.list.pay_type}
                                      onChange={this.handleSearchChange}
                                    >
                                      <option value="">
                                        {i18n.t("pay_type")}
                                      </option>
                                      <option value="online">
                                        {i18n.t("online")}
                                      </option>
                                      <option value="offline">
                                        {i18n.t("offline")}
                                      </option>
                                      <option value="wallet">
                                        {i18n.t("wallet")}
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-md-2 col-mob">
                                    <select
                                      className="form-select select text-black"
                                      name="trans_type"
                                      value={this.state.list.trans_type}
                                      onChange={this.handleSearchChange}
                                    >
                                      <option value="">
                                        {i18n.t("transaction") +
                                          " " +
                                          i18n.t("type")}
                                      </option>
                                      <option value="deposit">
                                        {i18n.t("deposit")}
                                      </option>
                                      <option value="withdraw">
                                        {i18n.t("withdraw")}
                                      </option>
                                      <option value="hold">
                                        {i18n.t("hold")}
                                      </option>
                                    </select>
                                  </div>
                                  <div className="dropdown-section-search col-md-1 col-mob-btn">
                                    <button
                                      type="button"
                                      className="border-0 bg-white"
                                      onClick={() => {
                                        this.loadAllTransactions();
                                      }}
                                    >
                                      <img
                                        src="./assets/images/search-trans-icon.png"
                                        alt=""
                                        width="35"
                                      />
                                    </button>
                                  </div>
                                </div>

                                {!this.state.is_loading &&
                                  transactions.length > 0 && (
                                    <div className="payment-info-section">
                                      <div className="bg-lt-green payment-info-container">
                                        <span className="payment-info-text">
                                          {i18n.t("deposit")} :{" "}
                                          {this.state.credit_sum}{" "}
                                          {i18n.t("omr")}
                                        </span>
                                      </div>
                                      <div className="bg-lt-orange payment-info-container">
                                        <span className="payment-info-text">
                                          {i18n.t("client_due")} :{" "}
                                          {this.state.client_due}{" "}
                                          {i18n.t("omr")}
                                        </span>
                                      </div>
                                      <div class="bg-lt-red payment-info-container">
                                        <span class="payment-info-text">
                                          {i18n.t("hold")} :{" "}
                                          {this.state.hold_sum} {i18n.t("omr")}
                                        </span>
                                      </div>
                                      <div className="bg-lt-blue payment-info-container">
                                        <span className="payment-info-text">
                                          {i18n.t("withdraw")} :{" "}
                                          {this.state.debit_sum} {i18n.t("omr")}
                                        </span>
                                      </div>
                                      {/* <div className="bg-lt-blue payment-info-container">
                                        <span className="payment-info-text">
                                          {i18n.t("balance")} :{" "}
                                          {this.state.balance_sum}{" "}
                                          {i18n.t("omr")}
                                        </span>
                                      </div> */}
                                    </div>
                                  )}

                                <div className="card-body px-2 border mx-2 rounded-4">
                                  {!this.state.is_loading &&
                                    transactions.length > 0 && (
                                      <div className="row">
                                        {/* <div className="col-12 d-flex">
                                          <div className="col-lg-4 col-md-12 col-sm-12 border border-info p-2 mb-2 rounded">
                                            <h5 className="text-info  text-center text-uppercase">
                                              {i18n.t("deposit")} :{" "}
                                              {this.state.credit_sum}
                                            </h5>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 border border-info p-2 mb-2 rounded">
                                            <h5 className="text-primary text-center text-uppercase">
                                              {i18n.t("withdraw")} :{" "}
                                              {this.state.debit_sum}
                                            </h5>
                                          </div>
                                          <div className="col-lg-4 col-md-12 col-sm-12 border border-info p-2 mb-2 rounded">
                                            <h5 className="text-success text-center text-uppercase">
                                              {i18n.t("balance")} :{" "}
                                              {this.state.balance_sum}
                                            </h5>
                                          </div>
                                        </div> */}
                                        <div className="">
                                          <DataTable
                                            title=""
                                            fixedHeader
                                            columns={this.state.columns}
                                            data={transactions}
                                            customStyles={customStyles}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationTotalRows={
                                              this.state.totalRows
                                            }
                                            paginationPerPage={15}
                                            paginationDefaultPage={
                                              this.state.currentPage
                                            }
                                            paginationRowsPerPageOptions={[
                                              15, 25, 50, 100,
                                            ]}
                                            paginationComponentOptions={{
                                              rowsPerPageText: i18n.t(
                                                "transactions_per_page"
                                              ),
                                              rangeSeparatorText: i18n.t("out"),
                                            }}
                                            onChangePage={this.handlePageChange}
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          show={this.state.withdraw_popup}
          onHide={() => this.setState({ withdraw_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("withdraw")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-4">
              <div className="col-12">{i18n.t("withdraw_display_message")}</div>
            </div>
            <form
              id="wallet-withdraw"
              className="row"
              tabindex="500"
              autoComplete="off"
            >
              <div className="col-5">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control fs-7rem-mob"
                    placeholder={i18n.t("amount")}
                    name="amount"
                    value={this.state.input.amount}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="form-group p-2 border border-danger text-danger text-center rounded fs-7rem-mob">
                  <b>
                    {i18n.t("balance")} : {this.state.balance}
                  </b>
                </div>
              </div>
              <div className="col-3">
                <button
                  className="btn btn-danger btn-md btn-block fs-5rem-mob"
                  onClick={this.handleSubmit}
                  disabled={this.state.is_wthdraw_req_sending}
                >
                  {!this.state.is_wthdraw_req_sending && (
                    <span>
                      <span>
                        <i className="fa fa-save" />
                      </span>
                      &nbsp;
                      {i18n.t("submit")}
                    </span>
                  )}
                  {this.state.is_wthdraw_req_sending && (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp;
                      {i18n.t("loading")}
                    </span>
                  )}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
