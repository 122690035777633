import React from "react";
import { Spinner } from "react-bootstrap";

export class MzadLoader extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }
  componentDidMount() {}

  render() {
    let size = "sm";
    if (this.props.size) {
      size = this.props.size;
    }
    let animation = "grow";
    if (this.props.animation) {
      animation = this.props.animation;
    }
    let style = {};
    if (this.props.style) {
      style = this.props.style;
    }
    let loading = this.props.loading ? this.props.loading : false;
    return loading ? (
      <React.Fragment>
        <div className="m-3 text-center p-3" style={style}>
          <Spinner animation={animation} variant="primary" size={size} />
          &nbsp;
          <Spinner animation={animation} variant="info" size={size} />
          &nbsp;
          <Spinner animation={animation} variant="secondary" size={size} />
        </div>
      </React.Fragment>
    ) : (
      false
    );
  }
}
