import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export class SkeletonHomeCard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    let baseColor = "#CCC";
    let height = 350;
    return (
      <React.Fragment>
        <div className="container">
          <div className="row px-5 justify-content-center gap-3 mx-1-mob px-1-mob">
            <div className="col-md-4 col-sm-6 col-xs-12 mt-3 auc-card-container m-auto mx-1">
              <Skeleton
                height={height}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mt-3 auc-card-container m-auto mx-1 d-mob-none">
              <Skeleton
                height={height}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "30px" }}
              />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mt-3 auc-card-container m-auto mx-1 d-mob-none">
              <Skeleton
                height={height}
                baseColor={baseColor}
                className="card"
                style={{ borderRadius: "30px" }}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
