import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { forgotPassword } from "../../models/login";
import swal from "sweetalert";
import { HomeBanner } from "../home/homeBanner";
export class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      sending: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendForgetPasswordEmail = this.sendForgetPasswordEmail.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async sendForgetPasswordEmail() {
    this.setState({ sending: true });
    const response = await forgotPassword({ userId: this.state.input.userId });
    if (response.success) {
      this.setState({ sending: false });
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 10000,
      })
        .then((value) => {})
        .catch((response) => {
          this.setState({ sending: false });
          swal("Failed", response.message, "error");
        });
    } else {
      this.setState({ sending: false });
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <HomeBanner />
        <div className="page-container">
          <div className="content-bg">
            <div className="container py-5">
              <div className="row justify-content-center">
                <div className="mx-auto">
                  <div className="col-lg-12 col-lg-6 col-lg-3 d-flex justify-content-center">
                    <div className="card w-65">
                      <div
                        className="card-header bg-primary"
                        style={{ zIndex: "999" }}
                      >
                        <h4>
                          <i
                            className="mdi mdi-account-convert"
                            style={{ fontSize: "1.5rem" }}
                          />
                          &nbsp;{i18n.t("forget_password")}
                        </h4>
                      </div>
                      <div
                        className="card-body"
                        style={{ zIndex: "99", marginTop: "-35px" }}
                      >
                        <form id="forgotpsd" className="card-body mt-3">
                          <div className="col-md-12 col-md-6 col-md-3">
                            <div>
                              <label for="username">
                                {i18n.t("username_or_email")}
                              </label>
                            </div>
                            <div className="mt-1">
                              <input
                                className="form-control custom-input "
                                type="email"
                                name="userId"
                                value={this.state.input.userId}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-12 col-md-6 col-md-3">
                            <div className="text-center mt-4">
                              <button
                                type="button"
                                className="btn btn-secondary px-5"
                                disabled={!this.state.input.userId}
                                onClick={this.sendForgetPasswordEmail}
                              >
                                {this.state.sending && i18n.t("sending")}
                                {!this.state.sending && i18n.t("send")}
                              </button>
                            </div>
                          </div>

                          <div className="horizontal-line"></div>

                          <div className="text-center text-dark mb-0">
                            Forget it,{" "}
                            <Link to="/login" className="text-warning">
                              send me back{" "}
                            </Link>{" "}
                            to the{" "}
                            <Link to="/login" className="text-warning">
                              sign in
                            </Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
