//Production
//const apiUrl = "";

//Test
let env = "test";
let baseUrl = "";
let apiUrl = "";
let thawaniUrl = "";
let thawaniConfig = {};
let paymentSuccessUrl = "";
let paymentCancelledUrl = "";
let paymentSuccessUrlBulk = "";
let walletPaymentSuccessUrl = "";
let walletPaymentCancelledUrl = "";
let clientPaymentSuccessUrl = "";
let clientPaymentCancelledUrl = "";
let thawaniPaymentUrl = "https://uatcheckout.thawani.om/pay/";
let scopeUrl = "";

if (env === "test") {
  baseUrl = "https://test.rop.mzadcom.om/";
  scopeUrl = "https://test.rop.mzadcom.om";
  apiUrl = baseUrl + "services/public/api/";
  thawaniUrl = "https://uatcheckout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "rRQ26GcsZzoEhbrP2HZvLYDbn9C9et",
    publishable_key: "HGvTMLDssJghr9tlN9gr4DVYt0qyBy",
  };
  paymentSuccessUrl = baseUrl + "payment-success/";
  paymentSuccessUrlBulk = baseUrl + "bulk-payment-success/";
  paymentCancelledUrl = baseUrl + "payment-cancelled";
  walletPaymentSuccessUrl = baseUrl + "wallet-payment-success/";
  walletPaymentCancelledUrl = baseUrl + "wallet-payment-cancelled";
  clientPaymentSuccessUrl = baseUrl + "client-payment-success/";
  clientPaymentCancelledUrl = baseUrl + "client-payment-cancelled";
}
//Dev
if (env === "dev") {
  baseUrl = "http://localhost:3000/";
  scopeUrl = "http://localhost:3000";
  apiUrl = "http://localhost:8000/api/";
  thawaniUrl = "https://uatcheckout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "rRQ26GcsZzoEhbrP2HZvLYDbn9C9et",
    publishable_key: "HGvTMLDssJghr9tlN9gr4DVYt0qyBy",
  };

  paymentSuccessUrl = "http://localhost:3000/payment-success/";
  paymentSuccessUrlBulk = "http://localhost:3000/bulk-payment-success/";
  paymentCancelledUrl = "http://localhost:3000/payment-cancelled";
  walletPaymentSuccessUrl = baseUrl + "wallet-payment-success/";
  walletPaymentCancelledUrl = baseUrl + "wallet-payment-cancelled";
  clientPaymentSuccessUrl = baseUrl + "client-payment-success/";
  clientPaymentCancelledUrl = baseUrl + "client-payment-cancelled";
}

if (env === "production") {
  baseUrl = "https://rop.mzadcom.om/";
  apiUrl = baseUrl + "services/public/api/";
  thawaniUrl = "https://checkout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "SQeBH2iJiRsRS2r4yPClP1mHO1UkPH",
    publishable_key: "R0YzWOnB1HdUKZmiiQqnjuNJjscETk",
  };
  paymentSuccessUrl = baseUrl + "payment-success/";
  paymentSuccessUrlBulk = baseUrl + "bulk-payment-success/";
  paymentCancelledUrl = baseUrl + "payment-cancelled";
  walletPaymentSuccessUrl = baseUrl + "wallet-payment-success/";
  walletPaymentCancelledUrl = baseUrl + "wallet-payment-cancelled";
  clientPaymentSuccessUrl = baseUrl + "client-payment-success/";
  clientPaymentCancelledUrl = baseUrl + "client-payment-cancelled";
  thawaniPaymentUrl = "https://checkout.thawani.om/pay/";
}

export {
  apiUrl,
  thawaniUrl,
  thawaniConfig,
  thawaniPaymentUrl,
  paymentSuccessUrl,
  paymentSuccessUrlBulk,
  paymentCancelledUrl,
  walletPaymentSuccessUrl,
  walletPaymentCancelledUrl,
  clientPaymentSuccessUrl,
  clientPaymentCancelledUrl,
  baseUrl,
  scopeUrl,
};
