import React from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import { BiddingModal } from "../../helpers/biddingModal";
//import { getLoggedUser } from "../../../state/user";
import { toggleLike, toggleWishlist } from "../../../models/auctions";
import { AuctionShare } from "./auctionShare";
import swal from "sweetalert";
import { getLoggedUser } from "../../../state/user";
import { formatCurrency } from "../../../state/utils";
import { VisitNow } from "./visitNow";
//import { Tooltip, OverlayTrigger } from "react-bootstrap";

//const user = getLoggedUser();
//let lng = localStorage.getItem("I18N_LANGUAGE");
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
const FinishBid = () => (
  <div className="outer-div m-auto mt-1">
    <div className="p-1 text-center text-success">
      {i18n.t("auction_started")}
    </div>
  </div>
);
const rendererEnds = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className="d-flex flex-column align-items-start m-auto mt-1">
        <small style={{ fontSize: "0.7rem" }}>{i18n.t("ends_after")} : </small>{" "}
        <div className="first d-flex gap-1" style={{ fontSize: "0.8rem" }}>
          <span className="d-flex gap-2">
            <span>
              {days}
              {i18n.t("D")}
            </span>
            <span>
              {hours}
              {i18n.t("H")}
            </span>{" "}
            <span>
              {minutes}
              {i18n.t("M")}{" "}
            </span>
            <span>
              {seconds}
              {i18n.t("S")}
            </span>
          </span>
        </div>
      </div>
    );
  }
};
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return "";
  } else {
    // Render a countdown
    return (
      <div className="d-flex flex-column align-items-start m-auto mt-1">
        <small style={{ fontSize: "0.7rem" }}>
          {i18n.t("starts_after")} :{" "}
        </small>{" "}
        <div className="first d-flex gap-1" style={{ fontSize: "0.8rem" }}>
          <span className="d-flex gap-2">
            <span>
              {days}
              {i18n.t("D")}
            </span>
            <span>
              {hours}
              {i18n.t("H")}
            </span>{" "}
            <span>
              {minutes}
              {i18n.t("M")}{" "}
            </span>
            <span>
              {seconds}
              {i18n.t("S")}
            </span>
          </span>
        </div>
      </div>
    );
  }
};

export class AuctionCard extends React.Component {
  constructor() {
    super();
    this.state = {
      open_popup: false,
      wishlist: false,
      like: false,
      is_active_light_box: false,
      bidTimeStopped: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.closeModal = this.closeModal.bind(this);
    this.addWishList = this.addWishList.bind(this);
    this.addLike = this.addLike.bind(this);
    this.incrementTime = this.incrementTime.bind(this);
  }

  componentDidMount() {
    this.setState({ like: this.props.auction.auction_liked });
    this.setState({ wishlist: this.props.auction.auction_wishlisted });
    if (this.props.auction) {
      setInterval(this.incrementTime, 1000);
    }
  }

  incrementTime() {
    let { auction } = this.props;
    if (!auction) {
      console.error("auction is undefined in props");
      return;
    }
    let dt = new Date(auction.server_time);
    dt.setSeconds(dt.getSeconds() + 1);
    auction.server_time = dt;
    this.setState({ auction });
  }

  closeModal() {
    this.setState({ open_popup: false });
  }
  async addWishList(wishlist) {
    if (is_logged) {
      const response = await toggleWishlist({
        auction_id: this.props.auctionId,
        wishlist,
      });
      if (response) {
        this.setState({ wishlist: wishlist });
      }
    }
  }

  async addLike(like) {
    if (is_logged) {
      const response = await toggleLike({
        auction_id: this.props.auctionId,
        like,
      });
      if (response) {
        this.setState({ like: like });
      }
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let auction = this.props.auction;
    let images = this.props.images;
    let img_src = process.env.PUBLIC_URL + "/assets/images/placeholder.jpg";
    //let imgFilter = { filter: "brightness(0.8) invert(1)" };
    if (images.length > 0) {
      //imgFilter = {};
      img_src = images[0].image;
    }
    let key = this.props.key;

    let amount_label = "";
    if (this.props.type === "upcoming") {
      amount_label = i18n.t("start_bid_amount");
    } else if (this.props.type === "previous") {
      amount_label = i18n.t("last_bid_amount");
    } else {
      amount_label = i18n.t("current_amount");
    }

    let startDateAr = auction.start_date_ar;
    let endDateAr = auction.end_date_ar;

    // let auctionImageStyle = {};
    // if (this.props.isDetailsPage) {
    //   auctionImageStyle = { height: "90%" };
    // }
    let statusAr = auction.status_label;
    let disp_status = "";
    let statusClass = "";
    let statusClasses = {
      E: "btn btn-label expired",
      F: "btn btn-label featured",
      A: "btn btn-label active",
      U: "btn btn-label upcoming",
    };
    if (statusAr && statusAr["status"]) {
      statusClass = statusClasses[statusAr["status"]];
      disp_status = current_lng === "ar" ? statusAr["ar"] : statusAr["en"];
    }

    //let now = this.state.now;
    let endTime = new Date(this.createDate(auction.start_date)).getTime();
    let aucEndTime = new Date(this.createDate(auction.end_date)).getTime();

    let end_time = endDateAr && endDateAr["time"] ? endDateAr["time"] : "";
    end_time = end_time.replace("PM", i18n.t("PM"));
    end_time = end_time.replace("AM", i18n.t("AM"));
    let start_time =
      startDateAr && startDateAr["time"] ? startDateAr["time"] : "";
    start_time = start_time.replace("PM", i18n.t("PM"));
    start_time = start_time.replace("AM", i18n.t("AM"));

    // let orgImg = "";
    // if (
    //   auction.organizationDetails &&
    //   auction.organizationDetails.file_organization_image_full
    // ) {
    //   orgImg = auction.organizationDetails.file_organization_image_full;
    // }
    let guarantee_amount = Number(auction.guarantee_amount);
    let orgImage = "";
    if (
      auction &&
      auction.organizationDetails &&
      auction.organizationDetails.file_organization_image_full
    ) {
      orgImage = auction.organizationDetails.file_organization_image_full;
    }

    const currentDate = new Date();
    const auctionStartDate = new Date(auction.start_date);
    let can_show_end_clock = true;
    console.log(auctionStartDate + " < " + currentDate);
    if (auctionStartDate > currentDate) {
      can_show_end_clock = false;
    }
    console.log("can_show_end_clock", can_show_end_clock);
    return (
      <React.Fragment key={this.props.key}>
        <div className="col-md-4 col-sm-6 col-xs-12 mt-3 auc-card-container m-auto">
          <span className={statusClass}>{disp_status}</span>
          <div className="auction-card-img-cont">
            <img
              src={img_src}
              className="img-fluid"
              width="333px"
              height="auto"
              alt=""
              onClick={() => {
                this.props.getImagePopup(true, images);
              }}
            />
          </div>
          <div className="content-div">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column align-items-start">
                <div>
                  {current_lng === "ar"
                    ? this.props.title_ar
                    : this.props.title}
                </div>
                <div className="" style={{ fontSize: "0.5rem" }}>
                  {i18n.t("auction_number")} : {auction.auction_number}
                </div>
              </div>

              {/** 2nd column */}
              <div>
                <div>
                  <span className="span-1 text-primary">
                    <strong style={{ fontSize: "1.5rem" }}>
                      {formatCurrency(auction.start_amount)}{" "}
                    </strong>
                    {i18n.t("omr")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between p-2">
            <div>
              <div className="col cont-list-auc" id="div2">
                <div className="">
                  <div className="" id="div21">
                    <Link
                      to={"/auction-details/" + this.props.auctionId}
                      className="d-flex align-items-center btn btn-primary py-1 px-3"
                    >
                      <i className="fa fa-info-circle" />
                      &nbsp;{i18n.t("view_details")}
                    </Link>
                  </div>
                  {this.props.can_bid && auction.is_enrolled && (
                    <div className="" id="div21">
                      <button
                        className="d-flex align-items-center btn btn-primary py-1 px-3"
                        onClick={() => {
                          if (
                            is_logged &&
                            !user.is_staff &&
                            auction.is_staff_auction
                          ) {
                            swal(
                              i18n.t("un_authorized"),
                              i18n.t("non_staff_popup"),
                              "warning"
                            );
                            return;
                          }
                          this.setState({ open_popup: true });
                        }}
                      >
                        <i className="fa fa-gavel"></i>
                        &nbsp;
                        {i18n.t("bid_now")}
                      </button>
                    </div>
                  )}
                  {auction.status === "A" && !auction.is_enrolled && (
                    <div className="" id="div21">
                      <Link
                        to={!is_logged ? "/login" : "/enroll/" + auction.id}
                        className="d-flex align-items-center btn btn-primary py-1 px-3"
                      >
                        <i className="fa fa-user" />
                        &nbsp;{i18n.t("enroll")}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div>
                {(auction.is_bid_time_ends || auction.status !== "A") && (
                  <div className="outer-div m-auto mt-1">
                    <div className="p-1 text-center text-danger">
                      {i18n.t("bid_time_ends")}
                    </div>
                  </div>
                )}
                {auction.status === "A" &&
                  !auction.is_bid_time_ends &&
                  auction.end_date && (
                    <Countdown
                      date={endTime}
                      renderer={renderer}
                      onComplete={() => {
                        this.setState({ bidTimeStopped: true });
                      }}
                      now={() => new Date(auction.server_time)}
                    />
                  )}

                {auction.status === "A" &&
                  !auction.is_bid_time_ends &&
                  auction.end_date &&
                  can_show_end_clock && (
                    <Countdown
                      date={aucEndTime}
                      renderer={rendererEnds}
                      onComplete={() => {
                        this.setState({ bidTimeStopped: true });
                      }}
                      now={() => new Date(auction.server_time)}
                    />
                  )}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between flex-column align-items-start px-2">
            <div className="span-1 span-pn">
              <small>
                {i18n.t("gurantee_amount")}: {formatCurrency(guarantee_amount)}{" "}
                {i18n.t("omr")}
              </small>
              {auction.status === "A" && !auction.is_enrolled && (
                <Link
                  to={"/enroll/" + auction.id}
                  className="btn btn-n1"
                  onClick={() => {
                    if (
                      is_logged &&
                      !user.is_staff &&
                      auction.is_staff_auction
                    ) {
                      swal(
                        i18n.t("un_authorized"),
                        i18n.t("non_staff_popup"),
                        "warning"
                      );
                      return;
                    }
                    this.setState({ open_popup: true });
                  }}
                >
                  {i18n.t("pay_now")}
                </Link>
              )}
            </div>
            {Number(auction.visit_amount) > 0 && (
              <div className="span-1">
                <small>
                  {i18n.t("visit_amount")}:{" "}
                  {formatCurrency(auction.visit_amount)}&nbsp;
                  {i18n.t("omr")}
                </small>
                <span>
                  {auction.is_visit_active && (
                    <VisitNow
                      amount={auction.visit_amount}
                      type="sitevisit"
                      id={auction.id}
                      gid={auction.group}
                      is_group={false}
                      group={auction.group}
                      main_class="btn btn-n1"
                    />
                  )}
                </span>
              </div>
            )}
            {Number(auction.visit_amount) < 1 && (
              <span className="span-1">
                <strong>{i18n.t("visit_amount")}: N/A</strong>
              </span>
            )}
          </div>
          <div className="social-icons-container">
            <div className="social-icons">
              <AuctionShare auction={auction} />
            </div>
          </div>

          {/* <div>
            <div>
              <div className="container interactions">
              <div className="row">
                <button type="button" className="btn btn-circle btn-sm">
                  {this.props.total_views} &nbsp;
                  <i className="icon icon-eye" style={{ fontSize: "12px" }}></i>
                </button>
                <button
                  type="button"
                  className="btn  btn-circle btn-sm"
                  onClick={(event) => {
                    this.addWishList(!this.state.wishlist);
                  }}
                >
                  <i
                    style={{ fontSize: "12px" }}
                    className={
                      this.state.wishlist ? "fa fa-heart" : "fa fa-heart-o"
                    }
                  ></i>
                </button>
                <button
                  type="button"
                  className="btn  btn-circle btn-sm"
                  onClick={(event) => {
                    this.addLike(!this.state.like);
                  }}
                >
                  <i
                    style={{ fontSize: "12px" }}
                    className={
                      this.state.like ? "fa fa-thumbs-up" : "fa fa-thumbs-o-up"
                    }
                  ></i>
                </button>
                <button type="button" className="btn  btn-circle btn-sm count">
                  {i18n.t("bid_counr")}
                  {"#:"}
                  {auction.bid_count}
                </button>
              </div>
            </div>
            </div>

            <div className="container pt-3 contaiiner-amt">
              <div className="d-flex">
                <div className="col col-one" id="div1">
                  <span className="span-1">
                    <strong>
                      {i18n.t("start_bid_amount")} :
                      {formatCurrency(auction.start_amount)} {i18n.t("omr")}
                    </strong>
                  </span>
                  <span className="span-1 span-pn">
                    <strong>
                      {i18n.t("gurantee_amount")}:{" "}
                      {formatCurrency(guarantee_amount)} {i18n.t("omr")}
                    </strong>
                    {auction.status === "A" && !auction.is_enrolled && (
                      <Link
                        to={"/enroll/" + auction.id}
                        className="btn btn-n1"
                        onClick={() => {
                          if (
                            is_logged &&
                            !user.is_staff &&
                            auction.is_staff_auction
                          ) {
                            swal(
                              i18n.t("un_authorized"),
                              i18n.t("non_staff_popup"),
                              "warning"
                            );
                            return;
                          }
                          this.setState({ open_popup: true });
                        }}
                      >
                        {i18n.t("pay_now")}
                      </Link>
                    )}
                  </span>
                  {Number(auction.visit_amount) > 0 && (
                    <span className="span-1">
                      <strong>
                        {i18n.t("visit_amount")}:{" "}
                        {formatCurrency(auction.visit_amount)}
                        {i18n.t("omr")}
                      </strong>
                      <span>
                        {auction.is_visit_active && (
                          <VisitNow
                            amount={auction.visit_amount}
                            type="sitevisit"
                            id={auction.id}
                            gid={auction.group}
                            is_group={false}
                            group={auction.group}
                            main_class="btn btn-n1"
                          />
                        )}
                      </span>
                    </span>
                  )}
                  {Number(auction.visit_amount) < 1 && (
                    <span className="span-1">
                      <strong>{i18n.t("visit_amount")}: N/A</strong>
                    </span>
                  )}
                  <span className="span-1">
                    <strong>
                      {i18n.t("vat")}: {auction.vat || "0"} %
                    </strong>
                  </span>
                  <span className="span-1">
                    <strong>
                      {amount_label}: {formatCurrency(auction.current_amount)}{" "}
                      {i18n.t("omr")}
                    </strong>
                  </span>
                </div>

                <div className="col col-two" id="div2">
                  <div>
                    <img src={orgImage} alt="" height="35" width="45" />
                  </div>
                  <span className="span-1">
                    <strong>
                      {startDateAr && startDateAr["day"]
                        ? i18n.t(startDateAr["day"])
                        : ""}
                    </strong>
                  </span>
                  <span className="span-2">
                    <strong>{i18n.t("start_date")}</strong>
                  </span>
                  <div className="div-1">
                    <span className="date">
                      <strong>
                        {startDateAr && startDateAr["date"]
                          ? startDateAr["date"]
                          : ""}
                      </strong>
                    </span>
                    <span className="time">
                      <strong>({start_time})</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="container container-dates">
              <div className="row">
                <div className="col col-one" id="div2">
                  <div className="row">
                    <div className="col-6 col-times" id="div21">
                      <span className="">
                        <strong>{i18n.t("time")}</strong>
                      </span>
                      <span>
                        <strong>({end_time})</strong>
                      </span>
                    </div>

                    <div className="col-6 col-dates" id="div22">
                      <span>
                        <strong>{i18n.t("end_date")}</strong>
                      </span>
                      <span>
                        <strong>
                          {endDateAr && endDateAr["date"]
                            ? endDateAr["date"]
                            : ""}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col col-cont-details" id="div2">
                  <div className="row d-flex align-items-center justify-content-center mt-2">
                    <div className="col-6 col-cal-cont d-block" id="div21">
                      <div className="d-flex justify-content-between align-items-center gap-2">
                        <i className="fa fa-calendar text-danger" />
                        <p>{auction.days_remaining}</p>
                      </div>
                      <p
                        className="text-center mb-0"
                        style={{ fontSize: "0.5rem" }}
                      >
                        {i18n.t("days_of_auction")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <button
                        type="submit"
                        className="btn btn-color  d-flex align-items-center justify-content-center text-center btn-final-aaproval"
                      >
                        {i18n.t("final_approval_lbl")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <BiddingModal
          open_popup={this.state.open_popup}
          closeModal={this.closeModal}
          auction={auction}
          lng={current_lng}
          key={key}
        />
      </React.Fragment>
    );
  }
}
