import React from "react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import i18n from "../../../i18n";
import { BiddingModal } from "../../helpers/biddingModal";
import { getLoggedUser } from "../../../state/user";
import swal from "sweetalert";
import { VisitNow } from "./visitNow";
import { formatCurrency } from "../../../state/utils";

const is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
let lng = localStorage.getItem("I18N_LANGUAGE");
export class AuctionRowGrouped extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_popup: false,
      bid_count: 0,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.closeModal = this.closeModal.bind(this);
    this.updateRow = this.updateRow.bind(this);
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return this.props.auction.end_date_formatted;
    } else {
      let today = new Date();
      let start_date = new Date(this.createDate(this.props.auction.start_date));
      if (start_date.getTime() > today.getTime()) {
        return (
          <span
            style={{
              padding: "0rem 0.2rem",
              borderRadius: "0.5rem",
            }}
            className="text-dark font_05_rem"
          >
            00 : 00 : 00 : 00
          </span>
        );
      }

      let warning =
        days === 0 && hours === 0 && minutes === 0 && seconds < 10
          ? true
          : false;
      return (
        <span
          style={{
            padding: "0rem 0.2rem",
            borderRadius: "0.5rem",
          }}
          className={
            warning ? "text-danger font_05_rem" : "text-dark font_05_rem"
          }
        >
          {days}
          {i18n.t("D")} : {hours}
          {i18n.t("H")} : {minutes}
          {i18n.t("M")} : {seconds}
          {i18n.t("S")}
        </span>
      );
    }
  };

  componentDidMount() {
    this.setState({ bid_count: this.props.auction.bid_count });
  }

  closeModal() {
    this.setState({ open_popup: false });
  }

  updateRow(data) {
    this.setState({ bid_count: data });
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let key = this.props.keys;
    let auction = this.props.auction;
    let images = auction.images;
    let img_src = process.env.PUBLIC_URL + "/assets/images/placeholder.jpg";
    if (images.length > 0) {
      img_src = images[0].image;
    }

    let is_vehicle = false;
    if (auction && auction.categoryDetails) {
      let cat = auction.categoryDetails;
      is_vehicle = cat.category_name === "Numbers" ? true : false;
    }

    let now = this.state.now;
    let aucEndDate = auction.end_date;
    let auctionEndTime = new Date(this.createDate(aucEndDate)).getTime();

    let visit_amount = auction.visit_amount;
    let group = this.props.group;
    let guarantee_amount = Number(auction.guarantee_amount);

    return (
      <React.Fragment>
        <div className="card border bg-lt-secondary">
          <div
            className="left-image"
            onClick={() => {
              // if (is_logged && !user.is_staff && auction.is_staff_auction) {
              //   swal(
              //     i18n.t("un_authorized"),
              //     i18n.t("non_staff_popup"),
              //     "warning"
              //   );
              //   return;
              // }
              // window.location.href = "/auction-details/" + auction.id;
              this.props.getImagePopup(true, images);
            }}
          >
            <img src={img_src} alt="Auction" className="m-auto" />
          </div>
          <div className="left-section">
            <div className="left-top">
              <div className="auc-head">
                <h3 className="data-h3">
                  <strong>
                    {lng === "ar" ? auction.title_ar : auction.title}
                  </strong>
                </h3>
                <div className="ld-flex justify-content-between align-items-center mb-3">
                  <i
                    className="mdi mdi-map-marker-outline text-primary"
                    style={{ fontSize: "1.5rem" }}
                  />
                  &nbsp;
                  {auction.location ? (
                    <span className="text-primary">
                      {lng === "ar" && auction.location_ar
                        ? auction.location_ar
                        : auction.location}
                    </span>
                  ) : (
                    <span className="text-primary">N/A</span>
                  )}
                </div>
                <h4 className="lot">
                  {i18n.t("auction_number")} :{" "}
                  <span className="text-primary">{auction.auction_number}</span>
                </h4>
                <div className="">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/bx_time.png"}
                    width="15"
                    height="15"
                    alt="clock"
                  />
                  <span>
                    {auctionEndTime && (
                      <Countdown
                        date={auctionEndTime}
                        renderer={this.renderer}
                        now={now}
                        onComplete={() => {
                          this.setState({ show_end_time: true });
                        }}
                      />
                    )}
                  </span>
                </div>
                <div className="d-flex justify-content-start align-items-center gap-1">
                  <span>
                    <b>
                      {i18n.t("gurantee_amount")}:{" "}
                      {formatCurrency(guarantee_amount)} {i18n.t("omr")}
                    </b>
                  </span>
                  {auction.status === "A" && !auction.is_enrolled && (
                    <Link
                      to={"/enroll/" + auction.id}
                      className="btn btn-n1"
                      onClick={() => {
                        if (
                          is_logged &&
                          !user.is_staff &&
                          auction.is_staff_auction
                        ) {
                          swal(
                            i18n.t("un_authorized"),
                            i18n.t("non_staff_popup"),
                            "warning"
                          );
                          return;
                        }
                        this.setState({ open_popup: true });
                      }}
                    >
                      {i18n.t("pay_now")}
                    </Link>
                  )}
                </div>
                {/* <div>
                  {!isNaN(visit_amount) && visit_amount > 0 && (
                    <div className="d-flex text-danger fs-5rem-mob">
                      <span className="">
                        {i18n.t("visit_amount")}: {formatCurrency(visit_amount)}{" "}
                        {i18n.t("omr")}
                      </span>
                      {!group.is_visit_active && (
                        <VisitNow
                          amount={visit_amount}
                          type="sitevisit"
                          id={null}
                          gid={auction.group}
                          is_group={true}
                        />
                      )}
                    </div>
                  )}
                </div> */}
              </div>
              <div className="details-btn-section">
                <div className="rank-dis">{auction.my_rank || "N/A"}</div>
                <div className="d-flex justify-content-between align-items-center gap-1 p-1">
                  <h1 className="text-primary">
                    <strong>{formatCurrency(auction.current_amount)}</strong>
                  </h1>
                  <h4 className="text-primary">
                    <small>{i18n.t("omr")}</small>
                  </h4>
                </div>
                <button
                  className="btn btn-primary d-flex align-items-center gap-2 px-5 py-1"
                  onClick={() => {
                    if (
                      is_logged &&
                      !user.is_staff &&
                      auction.is_staff_auction
                    ) {
                      swal(
                        i18n.t("un_authorized"),
                        i18n.t("non_staff_popup"),
                        "warning"
                      );
                      return;
                    }
                    window.location.href = "/auction-details/" + auction.id;
                  }}
                >
                  <i className="mdi mdi-information" />
                  <span>
                    {i18n.t("view")}&nbsp;{i18n.t("details")}
                  </span>
                </button>
              </div>
            </div>
            <hr />
            <div className="">
              {/* <span className="left-bottom-three lb">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/ri_auction-line.png"
                  }
                  width="15"
                  height="15"
                  alt="bid"
                />
                <span>
                  {i18n.t("bid_count")} : {this.state.bid_count}{" "}
                </span>
              </span> */}

              <div className="">
                {auction.status !== "A" && (
                  <button
                    className="btn btn-primary rounded-5 btn-block mb-2"
                    disabled={true}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/active-auctions-icon.png"
                      }
                      width="15"
                      alt="bid"
                    />
                    &nbsp;
                    <span>{i18n.t("bid_now")}</span>
                  </button>
                )}

                {auction.status === "A" && auction.is_enrolled && (
                  <button
                    className="btn btn-primary rounded-5 btn-block mb-2"
                    onClick={() => {
                      if (
                        is_logged &&
                        !user.is_staff &&
                        auction.is_staff_auction
                      ) {
                        swal(
                          i18n.t("un_authorized"),
                          i18n.t("non_staff_popup"),
                          "warning"
                        );
                        return;
                      }
                      this.setState({
                        open_popup: true,
                      });
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/active-auctions-icon.png"
                      }
                      width="15"
                      alt="bid"
                    />
                    &nbsp;
                    <span>{i18n.t("bid_now")}</span>
                  </button>
                )}

                {auction.status === "A" && !auction.is_enrolled && (
                  <Link
                    to={is_logged ? "/enroll/" + auction.id : "/login"}
                    className="btn btn-primary rounded-5 btn-block mb-2"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/active-auctions-icon.png"
                      }
                      width="15"
                      alt="bid"
                    />
                    &nbsp;
                    <span>{i18n.t("bid_now")}</span>
                  </Link>
                )}
              </div>
            </div>
          </div>

          {/* <hr className="horizontal-line" />
          <div className="right-section">
            <span className="cont-amount">
              {i18n.t("current_amount")} : {lng === "en" ? i18n.t("omr") : ""}{" "}
              {formatCurrency(auction.current_amount)}{" "}
              {lng === "ar" ? i18n.t("omr") : ""}{" "}
            </span>
            {auction.status === "A" && auction.is_enrolled && (
              <button
                className="btn btn-primary rounded-5 btn-block mb-2"
                onClick={() => {
                  if (is_logged && !user.is_staff && auction.is_staff_auction) {
                    swal(
                      i18n.t("un_authorized"),
                      i18n.t("non_staff_popup"),
                      "warning"
                    );
                    return;
                  }
                  this.setState({
                    open_popup: true,
                  });
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/active-auctions-icon.png"
                  }
                  width="25"
                  alt="bid"
                />
                <span>{i18n.t("bid_now")}</span>
              </button>
            )}
            {auction.status === "A" && !auction.is_enrolled && (
              <Link to={"/enroll/" + auction.id} className="btn btn-primary rounded-5 btn-block mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/active-auctions-icon.png"
                  }
                  width="25"
                  alt="bid"
                />
                <span>{i18n.t("bid_now")}</span>
              </Link>
            )}
            <span className="cont-amount">
              {i18n.t("start_price")} : {lng === "en" ? i18n.t("omr") : ""}{" "}
              {formatCurrency(auction.start_amount, lng)}{" "}
              {lng === "ar" ? i18n.t("omr") : ""}{" "}
            </span>
          </div> */}
        </div>

        <BiddingModal
          open_popup={this.state.open_popup}
          closeModal={this.closeModal}
          auction={auction}
          lng={lng}
          key={key}
          updateRow={this.updateRow}
        />
      </React.Fragment>
    );
  }
}
