import React from "react";
//import { Link } from "react-router-dom";
//import { SocialSignin } from "./socialSignin";
import i18n from "../../i18n";
import contryData from "../../constants/country.json";
import { otpLogin, validateOtp } from "../../models/login";
//import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import Dropdown from "react-bootstrap/Dropdown";
import { HomeBanner } from "../home/homeBanner";

const codeData = [...contryData];
export class LoginOtp extends React.Component {
  constructor() {
    super();
    this.state = {
      selected_country: {},
      isCaptchaVerified: false,
      captchaToken: "",
      input: {
        dial_code: "+968",
        type: "sms",
      },
      is_otp_sent: false,
      t_c_acept: false,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendOtp = this.sendOtp.bind(this);
    this.validateOtp = this.validateOtp.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
  }

  componentDidMount() {
    var defaultCodeValue = this.getObjectByKeyValue(
      codeData,
      "dial_code",
      "+968"
    );
    this.setState({ selected_country: defaultCodeValue });
  }

  getObjectByKeyValue(jsonArray, key, value) {
    for (var i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i][key] === value) {
        return jsonArray[i];
      }
    }
    return null;
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }

  handleCaptchaChange = (value) => {
    this.setState({
      isCaptchaVerified: true,
      captchaToken: value,
    });
    let { input } = this.state;
    input.captcha_token = value;
    this.setState({ input });
  };

  async sendOtp() {
    const response = await otpLogin(this.state.input);
    if (response.success) {
      this.setState({ is_otp_sent: true });
    } else {
      swal(response.message, JSON.stringify(response.data), "error");
    }
  }

  async validateOtp() {
    const response = await validateOtp(this.state.input);
    if (!response.success) {
      swal(response.message, response.data, "error");
      return;
    }
    if ("token" in response.data) {
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal(response.message, JSON.stringify(response.data), "error");
    }
  }

  render() {
    let selected_img =
      this.state.selected_country && this.state.selected_country.code
        ? this.state.selected_country.code.toLowerCase()
        : "";
    return (
      <React.Fragment>
        <HomeBanner />
        <div className="page-container">
          <div className="content-bg">
            <div className="container py-5">
              <div className="row justify-content-center">
                <div className="mx-auto">
                  <div className="col-lg-12 col-lg-6 col-lg-3 d-flex justify-content-center">
                    <div className="card w-65">
                      <div
                        className="card-header bg-primary"
                        style={{ zIndex: "999" }}
                      >
                        <h4>
                          <i
                            className="mdi mdi-cellphone-basic"
                            style={{ fontSize: "1.5rem" }}
                          />
                          &nbsp;{i18n.t("login_using_otp")}
                        </h4>
                      </div>
                      <div
                        className="card-body"
                        style={{ zIndex: "99", marginTop: "-35px" }}
                      >
                        <form id="login" className="card-body">
                          {!this.state.is_otp_sent && (
                            <div>
                              <div className="col-md-12">
                                <br></br>
                                <div style={{ width: "90%", margin: "auto" }}>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("phone_number")}
                                  </label>
                                </div>
                                <br></br>
                                <div style={{ width: "90%", margin: "auto" }}>
                                  <Dropdown className="flag-dd">
                                    <Dropdown.Toggle variant="country">
                                      {this.state.selected_country && (
                                        <div>
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/images/flags/" +
                                              selected_img +
                                              ".svg"
                                            }
                                            alt=""
                                            style={{ width: "25px" }}
                                          />
                                        </div>
                                      )}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {codeData.map((item, i) => {
                                        let img = item.code.toLowerCase();
                                        return (
                                          <Dropdown.Item
                                            onClick={() => {
                                              let { input } = this.state;
                                              input.dial_code = item.dial_code;
                                              this.setState({
                                                input: item,
                                                selected_country: item,
                                              });
                                            }}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/flags/" +
                                                img +
                                                ".svg"
                                              }
                                              alt=""
                                              style={{ width: "25px" }}
                                            />{" "}
                                            {item.label}
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  <input
                                    type="text"
                                    style={{ "z-index": 0 }}
                                    name="phone"
                                    value={this.state.input.phone}
                                    onChange={this.handleChange}
                                    className="form-control custom-input  mob-field"
                                  />
                                </div>

                                <div className="text-danger text-justify">
                                  {this.state.errors.mobile}
                                </div>
                              </div>

                              <div className="input-group input-group-sm mt-3">
                                <ReCAPTCHA
                                  sitekey="6LceESUpAAAAAAC7C5FaMVWVBIdBRwWzMqR8Wvq7"
                                  onChange={this.handleCaptchaChange}
                                  className="m-auto recaptcha"
                                  size="normal"
                                  style={{
                                    transform: "scale(0.85)",
                                    transformOrigin: "0 0",
                                  }}
                                />
                              </div>

                              <div className="send-otp-on text-center text-primary mt-3 mb-3">
                                <span>{i18n.t("send_otp_on")}</span>
                                {/* <div className="horizontal-line"></div> */}
                              </div>
                              <div className="buttons text-center">
                                {/* <button
                                  className="sms-btn"
                                  type="button"
                                  onClick={() => {
                                    let { input } = this.state;
                                    input.type = "sms";
                                    this.setState({ input });
                                    this.sendOtp();
                                  }}
                                >
                                  <img
                                    src="./assets/images/sms.png"
                                    alt="sms"
                                    width={20}
                                  />
                                  <span style={{ textWrap: "nowrap" }}>
                                    {i18n.t("SMS")}
                                  </span>
                                </button> */}
                                <button
                                  type="button"
                                  className="btn btn-secondary px-5"
                                  onClick={() => {
                                    let { input } = this.state;
                                    input.type = "whatsapp";
                                    this.setState({ input });
                                    this.sendOtp();
                                  }}
                                >
                                  <div className="d-flex justify-content-center align-items-cente gap-2">
                                    <img
                                      src="./assets/images/whatsapp.png"
                                      alt="whatsapp"
                                      width={17}
                                    />{" "}
                                    <span>{i18n.t("whatsapp")}</span>
                                  </div>
                                </button>
                              </div>
                            </div>
                          )}

                          {this.state.is_otp_sent && (
                            <div className="col-md-12 m-auto">
                              <label>{i18n.t("enter_otp")}</label>
                              <div className="passwd">
                                <input
                                  type="text"
                                  name="otp"
                                  value={this.state.input.otp}
                                  onChange={this.handleChange}
                                  className="form-control custom-input "
                                />
                              </div>
                              <hr />
                              <div className="submit">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block"
                                  onClick={this.validateOtp}
                                >
                                  {i18n.t("verify_login")}
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
