import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import i18n from "../../i18n";
import { getLoggedUser } from "../../state/user";
import {
  createThawaniCustomer,
  createThawaniSession,
  getPaymentMethods,
  deleteSavedCard,
  createIntent,
  confirmIntent,
} from "../../models/thawani";
import logoThawani from "../../logoThawani.svg";
import swal from "sweetalert";
import { updateUser } from "../../models/users";
import {
  walletPaymentSuccessUrl,
  walletPaymentCancelledUrl,
  thawaniConfig,
  thawaniPaymentUrl,
} from "../../constants/global";
import { createWallet } from "../../models/wallet";
import FileInput from "./fileInput";
const user = getLoggedUser();

export class WalletFund extends React.Component {
  constructor() {
    super();
    this.state = {
      online_type: "local",
      is_loading: false,
      is_card_popup: false,
      input: {
        month: 1,
        year: 2023,
      },
      row1: true,
      row2: true,
      payment_method: "offline",
      file_receipt: null,
      is_cards_loading: false,
      cards: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.gotoPayment = this.gotoPayment.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.saveFund = this.saveFund.bind(this);
    this.getCards = this.getCards.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.paymentUsingSavedCard = this.paymentUsingSavedCard.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    console.log(event.target.name + ", " + event.target.value);
    this.setState({ input });
  }

  componentDidMount() {
    this.setState({ is_loading: false });
    this.createCustomer = this.createCustomer.bind(this);
  }

  onFileChange = (event) => {
    if (event.target.name === "file_receipt") {
      this.setState({ file_receipt: event.target.files[0] });
    }
  };

  async createCustomer() {
    if (user && user.email) {
      let thw = await createThawaniCustomer({
        client_customer_id: user.email,
      });
      if (thw && thw.data) {
        this.setState({ row1: true, is_card_popup: false });
        localStorage.setItem("thawani_customer_id", thw.data.id);
        this.setState({ thawani_data_id: thw.data.id });
        let payload = user;
        payload.thawani_id = thw.data.id;
        payload.flag_thawani_update = true;
        let usr = await updateUser(payload, user.id);
        localStorage.setItem("mzad_user_info", JSON.stringify(usr.data));
      }
    } else {
      swal(
        "Failed",
        "Payment gateway requires email id of the logged user.",
        "error"
      );
    }
  }

  async saveFund(e) {
    e.preventDefault();
    if (
      !this.state.input.recharge_amount ||
      !this.state.file_receipt ||
      !this.state.input.account_number ||
      !this.state.input.bank ||
      !this.state.input.receipt_number
    ) {
      swal(i18n.t("failed"), i18n.t("all_mandatory"), "error");
      return;
    }

    this.setState({ is_loading: true });
    const formData = new FormData();
    formData.append("credit", this.state.input.recharge_amount);
    formData.append("account_number", this.state.input.account_number);
    formData.append("bank", this.state.input.bank);
    formData.append("receipt_number", this.state.input.receipt_number);
    formData.append("method", "offline");
    formData.append("status", "P");
    formData.append("type", "wallet_recharge");
    formData.append("user", user.id);
    if (this.state.file_receipt) {
      formData.append(
        "file_receipt",
        this.state.file_receipt,
        this.state.file_receipt.name
      );
    }

    const response = await createWallet(formData);
    if (response && response.success) {
      //let data = response.data;
      if (response.success) {
        swal(i18n.t("success"), i18n.t("fund_added"), "success");
        this.setState({ is_loading: false });
      } else {
        swal(
          i18n.t("failed"),
          "Some errors occured. please contact administrator",
          "error"
        );
        this.setState({ is_loading: false });
        return;
      }
    } else {
      this.setState({ is_loading: false });
    }
  }

  async gotoPayment(e) {
    this.setState({ is_payment_loading: true });
    let recharge_amount = this.state.input.recharge_amount;

    let { online_type } = this.state;
    let two_percent = (parseFloat(recharge_amount) * 2) / 100;
    let credit_amount = parseFloat(recharge_amount) + parseFloat(two_percent);

    let one_five_percent = (parseFloat(recharge_amount) * 1.5) / 100;
    let debit_amount =
      parseFloat(recharge_amount) + parseFloat(one_five_percent);
    if (online_type === "local") {
      recharge_amount = debit_amount;
    }
    if (online_type === "credit") {
      recharge_amount = credit_amount;
    }

    //let customer_id = this.state.thawani_data_id;
    let queryParams =
      "?ptype=online&type=wallet_recharge&amt=" +
      this.state.input.recharge_amount;
    //let rand_digit = Math.floor(10000 + Math.random() * 90000);
    let reference_id = user.email;
    localStorage.setItem("client_reference_id", reference_id);
    let payload = {
      client_reference_id: reference_id,
      mode: "payment",
      products: [
        {
          name: "Mzadcom Wallet : Recharge",
          quantity: 1,
          unit_amount: parseInt(recharge_amount) * 1000,
        },
      ],
      success_url: walletPaymentSuccessUrl + queryParams,
      cancel_url: walletPaymentCancelledUrl,
      customer_id: user.thawani_id,
      is_save_card_allowed: true,
      metadata: {
        "Customer name": user.name,
        "Order id": user.id + "",
        user: user.id,
      },
    };

    let thw = await createThawaniSession(payload);
    if (thw && thw.data && thw.success && thw.data.session_id) {
      let session_id = thw.data.session_id;
      localStorage.setItem("thawani_payment_res", JSON.stringify(thw.data));
      localStorage.setItem("invoice", thw.data.invoice);
      window.location.href =
        thawaniPaymentUrl +
        session_id +
        "?key=" +
        thawaniConfig.publishable_key;
      this.setState({ is_payment_loading: false });
    } else {
      this.setState({ is_payment_loading: false });
    }
  }

  async getCards() {
    if (!user.thawani_id) {
      // swal(
      //   "Warning",
      //   "No saved cards found!!! use any other methods",
      //   "warning"
      // );
      return;
    }
    this.setState({ is_cards_loading: true });
    const response = await getPaymentMethods(user.thawani_id);
    try {
      if (response.success) {
        this.setState({ is_cards_loading: false });
        this.setState({ cards: response.data });
      } else {
        this.setState({ is_cards_loading: false });
      }
    } catch (error) {
      this.setState({ is_cards_loading: false });
      console.error("Error fetching saved card:", error);
    }
  }

  async deleteCard(id) {
    swal({
      title: "Confirm Deletion",
      text: "Are you sure you want to delete this saved card?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await deleteSavedCard(id);
          if (response.success) {
            swal("Success", "Card deleted!!!", "success");
            this.getCards();
          }
        } catch (error) {
          console.error("Error deleting saved card:", error);
        }
      }
    });
  }

  async paymentUsingSavedCard(id) {
    let { recharge_amount } = this.state.input;
    let { online_type } = this.state;
    let two_percent = (parseFloat(recharge_amount) * 2) / 100;
    let credit_amount = parseFloat(recharge_amount) + parseFloat(two_percent);

    let one_five_percent = (parseFloat(recharge_amount) * 1.5) / 100;
    let debit_amount =
      parseFloat(recharge_amount) + parseFloat(one_five_percent);
    if (online_type === "local") {
      recharge_amount = debit_amount;
    }
    if (online_type === "credit") {
      recharge_amount = credit_amount;
    }

    if (!id) {
      swal("Error", "Please select a card", "error");
      return;
    }
    if (!recharge_amount) {
      swal(
        "Error",
        "Please enter an amount before proceeding with saved card!!!",
        "error"
      );
      return;
    }

    let queryParams =
      "?ptype=online&type=wallet_recharge&card=1&amt=" + recharge_amount;
    let payload = {
      payment_method_id: id,
      amount: parseInt(recharge_amount) * 1000,
      client_reference_id: user.email,
      return_url: walletPaymentSuccessUrl + queryParams,
      metadata: {
        "Customer name": user.name,
        "Order id": user.id + "",
        user: user.id,
      },
    };
    const response = await createIntent(payload);
    if (response.success) {
      localStorage.setItem("payment_intent_id", response.data.id);
      localStorage.setItem("invoice", response.data.id);
      const resConf = await confirmIntent(response.data.id);
      if (resConf.success) {
        window.location.href = resConf.data.next_action.url;
      }
    }
  }

  render() {
    let { is_payment_loading, cards, is_cards_loading } = this.state;
    let online_buttons =
      "btn btn-outline-success d-flex w-100 m-2 fs-5rem-mob rounded-0 align-items-center justify-content-center";
    let online_buttons_active =
      "btn btn-success d-flex w-100 m-2 fs-5rem-mob rounded-0 align-items-center justify-content-center";

    return (
      <React.Fragment>
        <div className="sptb mb-0" id="wallet-manage">
          <div className="">
            <div className="row">
              {this.state.is_loading && (
                <div className="col-lg-12 col-xl-12 col-md-12 text-center">
                  <Spinner animation="grow text-primary" size="lg" />
                </div>
              )}

              {!this.state.is_loading && (
                <div className="col-lg-12 col-xl-12 col-md-12 text-center">
                  <div className="mx-full">
                    <div className="col-lg-12 col-xl-12 col-md-12 m-auto">
                      <div className="card border rounded-5">
                        <div
                          className="card-header d-flex justify-content-between border rounded-5"
                          style={{ background: "#C9F3FF" }}
                        >
                          <h4 className="h6 text-justify text-dark">
                            {i18n.t("add_money_wallet")}
                          </h4>
                        </div>

                        <div className="card-body text-justify px-1">
                          <div className="row m-1">
                            <div className="custom-text3">
                              {i18n.t("enter_amount")}
                            </div>
                            <input
                              type="text"
                              name="recharge_amount"
                              className="text-center font-weight-bold p-2  border inpt-amount"
                              placeholder={i18n.t("enter_amount")}
                              value={this.state.input.recharge_amount}
                              onChange={this.handleChange}
                              style={{
                                fontSize: "1rem",
                              }}
                            />
                          </div>
                          <div className="row m-1">
                            <div className="col-12 text-center">
                              <div className="custom-text3">
                                {i18n.t("select_any_methods")}
                              </div>
                              <div class="btn-group btn-group-block m-auto toggler border  w-100">
                                <button
                                  type="button"
                                  className={
                                    this.state.payment_method === "offline"
                                      ? "btn btn-info  px-1 align-content-center align-items-center"
                                      : "btn px-1 align-content-center align-items-center"
                                  }
                                  onClick={() => {
                                    this.setState({
                                      payment_method: "offline",
                                    });
                                  }}
                                >
                                  <i className="fa fa-bank" />
                                  &nbsp;
                                  {i18n.t("bank_transfer")}
                                </button>
                                &nbsp;
                                <button
                                  type="button"
                                  className={
                                    this.state.payment_method === "online"
                                      ? "btn btn-info  px-1 align-content-center align-items-center"
                                      : "btn px-1 align-content-center align-items-center"
                                  }
                                  onClick={() => {
                                    this.setState({ payment_method: "online" });
                                    this.getCards();
                                  }}
                                >
                                  <i className="mdi mdi-web" />
                                  &nbsp;
                                  {i18n.t("online")}
                                </button>
                              </div>
                            </div>

                            {this.state.payment_method === "offline" && (
                              <div className="col-md-12 col-md-6 col-md-3 mt-3">
                                <label
                                  className="custom-text3 mb-2 d-flex"
                                  for="GuaranteeamountDepositandRefundPolicy"
                                >
                                  <img
                                    width="23px"
                                    height="23px"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/image 25.png"
                                    }
                                    alt=""
                                  />
                                  Wallet amount Deposit and Refund Policy
                                </label>
                                <div
                                  className="form-control overflow-auto custom-textarea mb-2"
                                  style={{
                                    height: "170px",
                                    textAlign: "justify",
                                  }}
                                >
                                  <p className="p-0 m-0">
                                    1) Those willing to participate in the
                                    e-auction must deposit an Guarantee amount.{" "}
                                  </p>
                                  <p className="p-0 m-0">
                                    2) The Guarantee amount is refundable.
                                    However, the Service Charges for the Online
                                    Payment is not refundable, which is 1.5% for
                                    local cards and 2% for debit cards.{" "}
                                  </p>
                                  <p className="p-0 m-0">
                                    3) The guarantee amount is separate from the
                                    value of the items offered in the auction.{" "}
                                  </p>
                                  <p className="p-0 m-0">
                                    4) The Guarantee amount may vary from an
                                    item (a commodity) to another, and it may be
                                    a fixed amount or a percentage of the
                                    present value of the offered
                                    commodity(item).
                                  </p>
                                  <p className="p-0 m-0">
                                    5) The Guarantee amount is not automatically
                                    returned. In case of delay, Mzadcom will not
                                    bear any delay in refund.
                                  </p>
                                  <p className="p-0 m-0">
                                    6) The Guarantee amount will return to the
                                    Bidders wallet and Bidders can request for
                                    refund.
                                  </p>
                                </div>
                              </div>
                            )}

                            {this.state.payment_method === "online" && (
                              <div className="col-md-12 col-md-6 col-md-3 mt-3">
                                <label
                                  className="custom-text3 mb-2 d-flex"
                                  for="GuaranteeamountDepositandRefundPolicy"
                                >
                                  <img
                                    width="23px"
                                    height="23px"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/image 25.png"
                                    }
                                    alt=""
                                  />
                                  {i18n.t("online_deposit_and_refund_policy")}
                                </label>
                                <div
                                  className="form-control overflow-auto custom-textarea mb-2"
                                  style={{
                                    height: "170px",
                                    textAlign: "justify",
                                  }}
                                >
                                  <p className="p-0 m-0">
                                    1) Those willing to participate in the
                                    e-auction must deposit an Guarantee amount.{" "}
                                  </p>
                                  <p className="p-0 m-0">
                                    2) The Guarantee amount is refundable.
                                    However, the Service Charges for the Online
                                    Payment is not refundable, which is 1.5% for
                                    local cards and 2% for debit cards.{" "}
                                  </p>
                                  <p className="p-0 m-0">
                                    3) The guarantee amount is separate from the
                                    value of the items offered in the auction.{" "}
                                  </p>
                                  <p className="p-0 m-0">
                                    4) The Guarantee amount may vary from an
                                    item (a commodity) to another, and it may be
                                    a fixed amount or a percentage of the
                                    present value of the offered
                                    commodity(item).
                                  </p>
                                  <p className="p-0 m-0">
                                    5) The Guarantee amount is not automatically
                                    returned. In case of delay, Mzadcom will not
                                    bear any delay in refund.
                                  </p>
                                  <p className="p-0 m-0">
                                    6) The Guarantee amount will return to the
                                    Bidders wallet and Bidders can request for
                                    refund.
                                  </p>
                                </div>
                              </div>
                            )}

                            {this.state.payment_method === "online" && (
                              <div className="card-body text-justify p-0 mt-3">
                                {is_cards_loading && (
                                  <div className="text-center text-danger mb-5 mt-5">
                                    <Spinner animation="grow" />
                                  </div>
                                )}

                                {!is_cards_loading && cards.length === 0 && (
                                  <div className="mb-2 mt-2">
                                    <h5 className="h6 text-center text-danger">
                                      {i18n.t("no_saved_cards")}
                                    </h5>
                                  </div>
                                )}

                                <div className="row m-0 p-0">
                                  {!is_cards_loading && cards.length > 0 && (
                                    <div>
                                      <h5 className="h6 text-danger">
                                        {i18n.t("saved_cards")}
                                        <hr className="m-0" />
                                      </h5>
                                      {cards.map((item) => {
                                        return (
                                          <div className="col-md-4 col-lg-4 col-sm-2 p-2 py-3">
                                            <div
                                              className="card bg-card p-2 text-white"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <div
                                                className="col-lg-2 col-xl-2 col-md-2 text-white"
                                                onClick={() => {
                                                  this.paymentUsingSavedCard(
                                                    item.id
                                                  );
                                                }}
                                              >
                                                <i
                                                  className={`fa fa-2x fa-cc-${item.brand.toLowerCase()} text-white`}
                                                />
                                              </div>
                                              <div
                                                className="row text-white"
                                                onClick={() => {
                                                  this.paymentUsingSavedCard(
                                                    item.id
                                                  );
                                                }}
                                              >
                                                <div className="golden-embosed">
                                                  {item.masked_card}
                                                </div>
                                                <div>
                                                  <small
                                                    id=""
                                                    class="text-white"
                                                  >
                                                    {i18n.t("name")} :{" "}
                                                    {item.nickname}
                                                  </small>
                                                </div>
                                                <small id="" class="text-gray">
                                                  {i18n.t("expiry_month_year")}{" "}
                                                  &nbsp;
                                                  {item.expiry_month}/
                                                  {item.expiry_year}
                                                </small>
                                              </div>

                                              <button
                                                type="button"
                                                className="btn btn-danger border btn-sm rounded-circle px-2 text-white"
                                                onClick={() => {
                                                  this.deleteCard(item.id);
                                                }}
                                                style={{
                                                  position: "absolute",
                                                  right: "-10px",
                                                  top: "-10px",
                                                  fontSize: "0.8rem",
                                                }}
                                              >
                                                <i className="fa fa-times text-white" />
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>

                                <div className="form-group">
                                  <div className="text-center mb-3">
                                    <div className="m-3">
                                      <img
                                        src={logoThawani}
                                        alt=""
                                        height={30}
                                      />
                                    </div>
                                    <div className="m-3">
                                      {i18n.t("redirect_to_thawani")}
                                    </div>
                                  </div>

                                  <div className="d-flex w-100 px-0">
                                    <div className="w-50 p-2 px-1">
                                      <div
                                        className={
                                          this.state.online_type === "local"
                                            ? online_buttons_active
                                            : online_buttons
                                        }
                                        style={{ height: "65px" }}
                                        onClick={() => {
                                          this.setState({
                                            online_type: "local",
                                          });
                                        }}
                                      >
                                        {i18n.t("local_cards_ecommended")}
                                      </div>
                                    </div>
                                    <div className="w-50 p-2 px-1">
                                      <div
                                        className={
                                          this.state.online_type === "credit"
                                            ? online_buttons_active
                                            : online_buttons
                                        }
                                        style={{ height: "65px" }}
                                        onClick={() => {
                                          this.setState({
                                            online_type: "credit",
                                          });
                                        }}
                                      >
                                        {i18n.t("credit_cards_coast_2")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {this.state.payment_method === "offline" && (
                              <div className="row">
                                <div className="col-md-12 col-md-6 col-md-3 mt-2">
                                  <div class="d-flex">
                                    <label for="bankname">
                                      <span style={{ color: "black" }}>*</span>
                                      {i18n.t("bank")}
                                    </label>
                                  </div>
                                  <div className="mt-2">
                                    <input
                                      className="form-control custom-input "
                                      type="text"
                                      id="bankname"
                                      name="bank"
                                      placeholder={i18n.t(
                                        "please_enter_bank_name"
                                      )}
                                      value={this.state.input.bank}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3 mt-2">
                                  <div class="d-flex">
                                    <label for="bankaccount">
                                      <span style={{ color: "black" }}>*</span>
                                      {i18n.t("account_number")}
                                    </label>
                                  </div>
                                  <div className="mt-2">
                                    <input
                                      className="form-control custom-input "
                                      type="text"
                                      id="bankaccount"
                                      name="account_number"
                                      placeholder={i18n.t(
                                        "please_enter_bank_account"
                                      )}
                                      value={this.state.input.account_number}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 col-md-6 col-md-3 mt-2">
                                  <div class="d-flex">
                                    <label for="receiptnumber">
                                      <span style={{ color: "black" }}>*</span>
                                      {i18n.t("receipt")}
                                    </label>
                                  </div>
                                  <div className="mt-2">
                                    <input
                                      className="form-control custom-input "
                                      type="text"
                                      id="receiptnumber"
                                      name="receipt_number"
                                      placeholder={i18n.t(
                                        "please_enter_receipt_number"
                                      )}
                                      value={this.state.input.receipt_number}
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 mt-1">
                                  <div className="custom-text3">
                                    {i18n.t("receipt")}
                                  </div>
                                  <div className="form-group">
                                    <FileInput
                                      wrapperClass=""
                                      placeholder={i18n.t("choose_file")}
                                      id="file_receipt"
                                      name="file_receipt"
                                      onChange={this.onFileChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="col-12 mt-3">
                              <div className="form-group text-center">
                                <button
                                  type="button"
                                  className="btn btn-md btn-warning m-auto  px-5"
                                  onClick={(e) => {
                                    if (!this.state.payment_method) {
                                      swal(
                                        i18n.t("failed"),
                                        i18n.t("choose_payment_method"),
                                        "error"
                                      );
                                      return;
                                    }
                                    if (!this.state.input.recharge_amount) {
                                      swal(
                                        i18n.t("failed"),
                                        "Please enter an amount",
                                        "error"
                                      );
                                      return;
                                    }
                                    if (
                                      this.state.payment_method === "offline"
                                    ) {
                                      this.saveFund(e);
                                    } else {
                                      this.gotoPayment(e);
                                    }
                                  }}
                                >
                                  {is_payment_loading
                                    ? i18n.t("saving")
                                    : i18n.t("submit")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modal
          size="md"
          show={this.state.is_card_popup}
          onHide={() => {
            this.setState({ is_card_popup: false });
          }}
          animation={true}
          dialogClassName="modal-90w"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("add_new_card")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12">
                <div className="form-group">
                  <label className="form-label text-dark text-justify">
                    {i18n.t("card_number")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={i18n.t("card_number")}
                    value={this.state.input.card_number}
                    name="card_number"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label text-dark text-justify">
                    {i18n.t("expiry_month_year")}
                  </label>
                  <div class="form-row">
                    <div class="col-2">
                      <input
                        type="number"
                        className="form-control text-center"
                        placeholder={i18n.t("month")}
                        value={this.state.input.month}
                        name="month"
                        min={1}
                        max={12}
                        step={1}
                      />
                    </div>
                    <div class="col-2 pt-2">{i18n.t("month")}</div>
                    <div class="col-1 pt-2">{" / "}</div>
                    <div class="col-3">
                      <input
                        type="number"
                        className="form-control text-center"
                        placeholder={i18n.t("year")}
                        value={this.state.input.year}
                        name="year"
                        min={2023}
                        max={2050}
                        step={1}
                      />
                    </div>
                    <div class="col-2 pt-2">{i18n.t("year")}</div>
                    <div class="col-2">
                      <input
                        type="text"
                        className="form-control text-center"
                        placeholder={i18n.t("cvv")}
                        value={this.state.input.cvv}
                        name="cvv"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={this.createCustomer}
              className="btn btn-outline-warning btn-md"
            >
              Save Card
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
