import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
export class CarouselAuctionDetails extends Component {
  constructor() {
    super();
    this.state = {
      is_active_light_box: false,
    };
  }
  render() {
    let images = this.props.images;
    let lightboxImages = [];
    if (images) {
      images.forEach(function (arrayItem) {
        lightboxImages.push({
          url: arrayItem.image,
        });
      });
    }
    return (
      <div>
        <Carousel
          showThumbs={this.props.showThumbs || false}
          thumbWidth={200}
          showIndicators={false}
          infiniteLoop
          autoPlay
          onClickItem={() => {
            this.setState({ is_active_light_box: true });
          }}
        >
          {images &&
            images.length > 0 &&
            images.map((item, i) => {
              return (
                <div className="image rounded-4" key={i}>
                  <img src={item.image} alt="img" className="rounded-4" />
                </div>
              );
            })}
        </Carousel>

        {this.state.is_active_light_box && (
          <Lightbox
            images={lightboxImages}
            onClose={() => {
              this.setState({ is_active_light_box: false });
            }}
          />
        )}
      </div>
    );
  }
}

export default CarouselAuctionDetails;
