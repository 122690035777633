import React from "react";
import Countdown from "react-countdown";
import { CarouselAuctionDetails } from "../carousel/CarouselAuctionDetails";
import i18n from "../../i18n";
import {
  getAuction,
  addViewCount,
  toggleWishlist,
  toggleLike,
  toggleCalendar,
} from "../../models/auctions";
//import { AuctionsDetailTabs } from "./helpers/auctionDetailTabs";
import { BiddingComp } from "../helpers/biddingComp";
//import { AuctionShare } from "./helpers/auctionShare";
import MapPicker from "react-google-map-picker";
import { SkeletonAuctionDetails } from "../helpers/skeletons/skeletonAuctionDetails";
import { AuctionDocuments } from "./helpers/auctionDocuments";
//import labelarabic from "../../images/labelarabic.png";
//import LabelEng from "../../images/LabelEng.png";
import { formatCurrency } from "../../state/utils";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../constants/global";
import { HomeBanner } from "./homeBanner";
//import { Categories } from "./categories";
import swal from "sweetalert";
import getServerTime from "../../models/server";
import ActionSheet from "../helpers/actionSheet";
import { Modal } from "react-bootstrap";

let is_logged = localStorage.getItem("is_logged_in") ? true : false;
let current_lng = localStorage.getItem("I18N_LANGUAGE");
const FinishBid = ({ status_dis }) => (
  <div className="p-2" style={{ fontSize: "1.1rem" }}>
    {status_dis === "E" && (
      <span className="text-center text-danger">
        {i18n.t("auction")} {i18n.t("expired")}
      </span>
    )}
    {status_dis !== "E" && (
      <span className="text-center text-success">
        {i18n.t("auction_started")}
      </span>
    )}
  </div>
);
const prependZeroIfNeeded = (number) => {
  return number.toString().length === 1 ? `0${number}` : number;
};

const rendererEnds = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className="d-flex flex-column align-items-start m-auto mt-1">
        <small style={{ fontSize: "0.8rem" }}>{i18n.t("ends_after")} : </small>{" "}
        <div className="first d-flex gap-1" style={{ fontSize: "1rem" }}>
          <span className="d-flex gap-2 font-weight-bold">
            <span>
              {days}
              {i18n.t("D")}
            </span>
            <span>
              {hours}
              {i18n.t("H")}
            </span>{" "}
            <span>
              {minutes}
              {i18n.t("M")}{" "}
            </span>
            <span>
              {seconds}
              {i18n.t("S")}
            </span>
          </span>
        </div>
      </div>
    );
  }
};
const rendererStarts = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return "";
  } else {
    // Render a countdown
    return (
      <div className="d-flex flex-column align-items-start m-auto mt-1">
        <small style={{ fontSize: "0.8rem" }}>
          {i18n.t("starts_after")} :{" "}
        </small>{" "}
        <div className="first d-flex gap-1" style={{ fontSize: "1rem" }}>
          <span className="d-flex gap-2 font-weight-bold">
            <span>
              {days}
              {i18n.t("D")}
            </span>
            <span>
              {hours}
              {i18n.t("H")}
            </span>{" "}
            <span>
              {minutes}
              {i18n.t("M")}{" "}
            </span>
            <span>
              {seconds}
              {i18n.t("S")}
            </span>
          </span>
        </div>
      </div>
    );
  }
};

const renderer = ({ days, hours, minutes, seconds, completed }, status_dis) => {
  if (completed) {
    return <FinishBid status_dis={status_dis} />;
  } else {
    // Render a countdown
    return (
      <div style={{ position: "relative" }}>
        <div className="clock-div d-flex p-1 py-2 gap-2 justify-content-center">
          <div>{i18n.t("starts_after")}</div>
          <div className="clock-item">
            {prependZeroIfNeeded(days)} :
            <span className="border-span days">{i18n.t("lbl_day")}</span>
          </div>
          <div className="clock-item">
            {prependZeroIfNeeded(hours)} :
            <span className="border-span hours">{i18n.t("lbl_hour")}</span>
          </div>
          <div className="clock-item">
            {prependZeroIfNeeded(minutes)} :
            <span className="border-span minutes">{i18n.t("lbl_minute")}</span>
          </div>
          <div className="clock-item">
            {prependZeroIfNeeded(seconds)}
            <span className="border-span seconds">{i18n.t("lbl_second")}</span>
          </div>
        </div>
      </div>
    );
  }
};
export class AuctionDetails extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      is_active_bidnow: false,
      detailsTab: 1,
      wishlist: false,
      like: false,
      calendar: false,
      auctionId: auctionId,
      auction: [],
      toggleArrow: false,
      bid_increment: 0,
      current_bid_amount: 0,
      user_bid_amount: 0,
      left_button_disable: true,
      is_bid_sending: false,
      bid_place_message: "",
      enquiries: [{ label: "", value: "" }],
      is_loading: false,
      bidTimeStopped: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };

    this.updateMetaTags = this.updateMetaTags.bind(this);
    this.addWishList = this.addWishList.bind(this);
    this.addLike = this.addLike.bind(this);
    this.addCalendar = this.addCalendar.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.addViewCount();
    this.loadAuction();
    this.setServerTime();
  }

  async setServerTime() {
    try {
      let response = await getServerTime();
      let serverTime = new Date(response.server_time.replace(/-/g, "/"));
      this.setState({ now: () => serverTime });
    } catch (error) {
      console.error("Error fetching server time:", error);
    }
  }

  updateMetaTags(auction) {
    let { id, title, images, description } = auction;
    console.log("Count : " + id);
    let first_image = images && images.length > 0 ? images[0].image : false;
    let url = baseUrl + "auction-details/" + id;

    const updatedMetaTags = [
      { property: "og:title", content: title },
      { property: "og:type", content: "Auctions" },
      { property: "og:url", content: url },
      { property: "og:site_name", content: "Mzadcom" },
      { property: "og:description", content: description },
      { property: "og:image", content: first_image },
      { name: "twitter:card", content: "app" },
      { name: "twitter:title", content: "Mzadcom Auctions" },
      { name: "twitter:text:title", content: "Mzadcom Auctions" },
      { name: "twitter:image", content: first_image },
      { name: "twitter:description", content: description },
    ];

    return (
      <Helmet>
        <title>{title}</title>
        {updatedMetaTags.map((tag, index) => (
          <meta key={index} {...tag} />
        ))}
      </Helmet>
    );
  }

  async addViewCount() {
    await addViewCount({ auction_id: this.state.auctionId });
  }

  async loadAuction() {
    this.setState({ is_loading: true });
    let id = this.state.auctionId;
    const response = await getAuction(id);
    if (response) {
      let auction = response.data;
      let enquiry = auction.enquiry;
      auction.selected_mask = auction.mask;
      this.setState({
        auction: auction,
        current_bid_amount: auction.start_amount,
        enquiries: enquiry,
        is_loading: false,
      });
      this.setState({ like: auction.auction_liked });
      this.setState({ wishlist: auction.auction_wishlisted });
      this.setState({ calendar: auction.calendar });
    }
  }

  async addWishList(wishlist) {
    let { auction } = this.state;
    if (is_logged) {
      const response = await toggleWishlist({
        auction_id: auction.id,
        wishlist,
      });
      if (response) {
        this.setState({ wishlist: wishlist });
      }
    }
  }

  async addLike(like) {
    let { auction } = this.state;
    if (is_logged) {
      const response = await toggleLike({
        auction_id: auction.id,
        like,
      });
      if (response) {
        this.setState({ like: like });
      }
    }
  }

  async addCalendar(calendar) {
    let { auction } = this.state;
    if (is_logged) {
      const response = await toggleCalendar({
        auction_id: auction.id,
        calendar,
      });
      if (response) {
        this.setState({ calendar: calendar });
        if (calendar) {
          swal("Success", i18n.t("added_to_calendar"), "success");
        } else {
          swal("Success", i18n.t("removed_from_calendar"), "success");
        }
      }
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let auction = this.state.auction;
    let email_cont = `mailto:your@friendsmail.com?subject=${auction.title}&body=${auction.description}\r\n${window.location.href}`;
    let action_name = current_lng === "ar" ? auction.title_ar : auction.title;
    let auction_images = auction.images;
    let startDateAr = auction.start_date_ar;
    let endDateAr = auction.end_date_ar;

    let now = this.state.now;
    let endTime = new Date(this.createDate(auction.start_date)).getTime();
    let aucEndTime = new Date(this.createDate(auction.end_date)).getTime();

    let end_time = endDateAr && endDateAr["time"] ? endDateAr["time"] : "";
    end_time = end_time.replace("PM", i18n.t("PM"));
    end_time = end_time.replace("AM", i18n.t("AM"));
    let start_time =
      startDateAr && startDateAr["time"] ? startDateAr["time"] : "";
    start_time = start_time.replace("PM", i18n.t("PM"));
    start_time = start_time.replace("AM", i18n.t("AM"));
    let guarantee_amount = Number(auction.guarantee_amount);

    let updatedMetaTags = "";
    if (auction && auction.id) {
      updatedMetaTags = this.updateMetaTags(auction);
    }
    let addCalendarClass =
      "p-2 text_div custom_btn-color3 rounded-5 btn-block border border-1";
    if (this.state.calendar) {
      addCalendarClass =
        "p-2 text_div btn btn-danger rounded-5 btn-block border border-1";
    }

    const currentDate = new Date();
    const auctionStartDate = new Date(auction.start_date);
    let can_show_end_clock = true;
    console.log(auctionStartDate + " < " + currentDate);
    if (auctionStartDate > currentDate) {
      can_show_end_clock = false;
    }
    let { detailsTab } = this.state;
    let details = auction.details;
    let details_length =
      (auction && auction.details && auction.details.length) || 0;
    let vehicle = false;
    if (auction.is_vehicle) {
      vehicle = auction.vehicle_info;
    }
    return (
      <React.Fragment>
        <div className="page-container mb-5">
          <HomeBanner />
          {/* <Categories /> */}

          {updatedMetaTags}
          {this.state.is_loading && (
            <div className="cover-image sptb-1 bg-white text-center mt-8 mb-8 mx-5">
              <SkeletonAuctionDetails />
            </div>
          )}

          {!this.state.is_loading && (
            <div className="mt-5">
              <section className="sptb auction-details-section">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="card overflow-hidden image-holder bg-lt-secondary rounded-5">
                        {/* {auction.status_dis === "A" && (
                          <div className=" p-1 px-4 active_cus success">
                            <span>{i18n.t("active")}</span>
                          </div>
                        )}
                        {auction.status_dis === "E" && (
                          <div className=" p-1 px-4 active_cus danger">
                            <span>{i18n.t("expired")}</span>
                          </div>
                        )}
                        {auction.status_dis === "U" && (
                          <div className=" p-1 px-4 active_cus info">
                            <span>{i18n.t("upcoming")}</span>
                          </div>
                        )}
                        {auction.status_dis === "F" && (
                          <div className=" p-1 px-4 active_cus warning">
                            <span>{i18n.t("featured")}</span>
                          </div>
                        )} */}
                        <div className="active_cus bg-none">
                          <div
                            className="p-2 bd-highlight"
                            onClick={(event) => {
                              this.addWishList(!this.state.wishlist);
                            }}
                          >
                            <i className="mdi mdi-heart text-danger" />
                          </div>
                        </div>
                        {auction_images && auction_images.length === 0 ? (
                          <div className="border text-center">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/placeholder.jpg"
                              }
                              alt=""
                              className="m-auto w-100"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {auction_images && auction_images.length > 0 && (
                          <CarouselAuctionDetails
                            images={auction_images}
                            showThumbs={true}
                          />
                        )}
                        <div className="card-body bg-lt-secondary p-0 pb-4">
                          <div className="ps-container d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-2">
                              <span>
                                <span className="text-primary">
                                  {auction.total_views}
                                </span>
                                &nbsp;
                                <i className="mdi mdi-eye-outline" />
                              </span>
                              <h4 className="text-dark m-0">{action_name}</h4>
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <h1 className="text-primary m-0">
                                  <b>{formatCurrency(auction.start_amount)}</b>
                                </h1>
                                &nbsp;
                                <span className="text-primary">
                                  {i18n.t("omr")}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="ps-container d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-1">
                              <img
                                height="35"
                                width="35"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icons/auc-icon.png"
                                }
                                alt=""
                              />
                              <span
                                className="text-primary font-weight-bold"
                                style={{ fontSize: "1.5rem" }}
                              >
                                {auction?.auction_number}
                              </span>
                            </div>
                            <div>
                              {auction.status === "A" &&
                                !auction.is_bid_time_ends &&
                                auction.end_date && (
                                  <Countdown
                                    date={endTime}
                                    renderer={rendererStarts}
                                    onComplete={() => {
                                      this.setState({ bidTimeStopped: true });
                                    }}
                                    now={() => new Date(auction.server_time)}
                                  />
                                )}

                              {auction.status === "A" &&
                                !auction.is_bid_time_ends &&
                                auction.end_date &&
                                can_show_end_clock && (
                                  <Countdown
                                    date={aucEndTime}
                                    renderer={rendererEnds}
                                    onComplete={() => {
                                      this.setState({ bidTimeStopped: true });
                                    }}
                                    now={() => new Date(auction.server_time)}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                      <div className="bg-lt-secondary rounded-5">
                        <div className="auc-tabs-in-details d-flex align-items-center justify-content-between border-lt border-lt-primary rounded-5 p-3 bg-lt-secondary">
                          <button
                            onClick={() => {
                              this.setState({ detailsTab: 1 });
                            }}
                            className={`btn rounded-5 text-primary px-4 ${
                              detailsTab === 1 ? "bg-secondary" : "bg-none"
                            }`}
                            style={{ fontSize: "1.1rem" }}
                          >
                            {i18n.t("auction")} {i18n.t("details")}
                          </button>
                          <button
                            onClick={() => {
                              this.setState({ detailsTab: 2 });
                            }}
                            className={`btn rounded-5 text-primary px-4 ${
                              detailsTab === 2 ? "bg-secondary" : "bg-none"
                            }`}
                            style={{ fontSize: "1.1rem" }}
                          >
                            {i18n.t("documents")}
                          </button>
                          <button
                            onClick={() => {
                              this.setState({ detailsTab: 3 });
                            }}
                            className={`btn rounded-5 text-primary px-4 ${
                              detailsTab === 3 ? "bg-secondary" : "bg-none"
                            }`}
                            style={{ fontSize: "1.1rem" }}
                          >
                            {i18n.t("location")}
                          </button>
                        </div>
                        <div className="bg-lt-secondary rounded-4">
                          {detailsTab === 1 && (
                            <>
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <div className="d-flex flex-column p-3 align-items-center">
                                  <div>{i18n.t("auction_number")}:</div>
                                  <div className="mb-3">
                                    <h5 className="text-info m-0">
                                      {auction.auction_number}
                                    </h5>
                                  </div>
                                  <div>{i18n.t("vat")} : </div>
                                  <div className="mb-3">
                                    <h5 className="text-info m-0">
                                      {auction.vat}
                                      {"%"}
                                    </h5>
                                  </div>
                                  <div>{i18n.t("start_date")} : </div>
                                  <div className="mb-3">
                                    <h5 className="text-info m-0">
                                      {startDateAr && startDateAr["date"]
                                        ? startDateAr["date"]
                                        : ""}
                                    </h5>
                                  </div>
                                  <div className="d-flex justify-content-end mb-3">
                                    <h6 className="text-info m-0">
                                      ({start_time})
                                    </h6>
                                  </div>
                                  <div>{i18n.t("end_date")} : </div>
                                  <div>
                                    <h5 className="text-info m-0">
                                      {endDateAr && endDateAr["date"]
                                        ? endDateAr["date"]
                                        : ""}
                                    </h5>
                                  </div>
                                  <div className="d-flex justify-content-end">
                                    <h6 className="text-info m-0">
                                      ({end_time})
                                    </h6>
                                  </div>
                                </div>
                                <div>
                                  <div className="mb-3">
                                    <div className="">
                                      {i18n.t("start_bid_amount")}:
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <h3 className="text-info m-0">
                                        <b>
                                          {formatCurrency(auction.start_amount)}{" "}
                                        </b>
                                      </h3>
                                      <span>{i18n.t("omr")}</span>
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <div className="">
                                      {i18n.t("gurantee_amount")}:
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <h3 className="text-info m-0">
                                        <b>
                                          {formatCurrency(guarantee_amount)}{" "}
                                        </b>
                                      </h3>
                                      <span>{i18n.t("omr")}</span>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <div className="">
                                      {i18n.t("visit_amount")}:{" "}
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <h3 className="text-info m-0">
                                        <b>
                                          {formatCurrency(auction.visit_amount)}{" "}
                                        </b>
                                      </h3>
                                      <span>{i18n.t("omr")}</span>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <div className="">
                                      {i18n.t("current_amount")}:{" "}
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <h3 className="text-info m-0">
                                        <b>
                                          {formatCurrency(
                                            auction.current_amount
                                          )}{" "}
                                        </b>
                                      </h3>
                                      <span>{i18n.t("omr")}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {details_length > 0 && (
                                <div className="px-4">
                                  <h5 className="text-primary px-1">
                                    <b>{i18n.t("details")}</b>
                                  </h5>
                                  <table className="table table-bordered border-top mb-0 rounded-0">
                                    <tbody>
                                      {details.map((item, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="bg-none">
                                              {current_lng === "ar" &&
                                              item.label_ar
                                                ? item.label_ar
                                                : item.label}
                                            </td>
                                            <td className="bg-none">
                                              {current_lng === "ar" &&
                                              item.value_ar
                                                ? item.value_ar
                                                : item.value}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              )}

                              {vehicle && auction.is_vehicle && (
                                <div className="px-4">
                                  <h5 className="text-primary px-1">
                                    <b>
                                      {i18n.t("vehicle")}&nbsp;
                                      {i18n.t("details")}
                                    </b>
                                  </h5>
                                  <table className="table-cust">
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("make")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.make}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("mileage")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.mileage}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("model")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.model}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("body_type")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.body_type}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("fuel_type")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.fuel_type}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("transmission_type")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.transmission_type}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("color")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.color}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("vehicle_number")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.vehicle_number}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("warranty")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.warranty}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("document_type")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.document_type}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("engine_size")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.engine_size}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("extras")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.extras}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("information_number")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.information_number}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="bg-none">
                                        {i18n.t("no_of_keys")}
                                      </td>
                                      <td className="bg-none">
                                        {vehicle.no_of_keys}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              )}
                            </>
                          )}
                          {detailsTab === 2 && (
                            <>
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <AuctionDocuments
                                  auction={auction}
                                  lng={current_lng}
                                />
                              </div>

                              <div className="row p-3">
                                <div className="col-md-6 col-sm-12 mb-2">
                                  <div className="border p-0 px-1 d-flex my-2 justify-content-between bg-secondary rounded-2 align-items-center">
                                    <div className="d-flex gap-3 align-items-center">
                                      <i
                                        class="fa fa-file-pdf-o p-3"
                                        style={{
                                          color: "#FF0000",
                                          fontSize: "2rem",
                                          background: "#f8f9fa",
                                        }}
                                      ></i>
                                      <span className="fs-5rem-mob">
                                        {i18n.t("terms_conditions_payment")}
                                      </span>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm rounded-2 border"
                                      onClick={() => {
                                        if (!auction.file_payment_terms) {
                                          swal(
                                            "Failed",
                                            "No files were found!!. Please contact mzadcom administrator",
                                            "error"
                                          );
                                          return;
                                        }
                                        if (auction.file_payment_terms) {
                                          window.open(
                                            auction.file_payment_terms,
                                            "_blank"
                                          );
                                        }
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                      &nbsp;
                                      {i18n.t("preview")}{" "}
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12 mb-2">
                                  <div className="border p-0 px-1 d-flex my-2 justify-content-between bg-secondary rounded-2 align-items-center">
                                    <div className="d-flex gap-3 align-items-center">
                                      <i
                                        class="fa fa-file-pdf-o p-3"
                                        style={{
                                          color: "#FF0000",
                                          fontSize: "2rem",
                                          background: "#f8f9fa",
                                        }}
                                      ></i>
                                      <span className="fs-5rem-mob">
                                        {i18n.t("terms_conditions")}
                                      </span>
                                    </div>
                                    <button
                                      className="btn btn-primary btn-sm rounded-2 border"
                                      type="button"
                                      onClick={() => {
                                        if (!auction.file_terms) {
                                          swal(
                                            "Failed",
                                            "No files were found!!. Please contact mzadcom administrator",
                                            "error"
                                          );
                                          return;
                                        }
                                        if (auction.file_terms) {
                                          window.open(
                                            auction.file_terms,
                                            "_blank"
                                          );
                                        }
                                      }}
                                    >
                                      <i className="fa fa-eye" />
                                      &nbsp;
                                      {i18n.t("preview")}{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {detailsTab === 3 && (
                            <>
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <div
                                  className="card mt-3 border rounded-5 bg-none"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="card-body p-0 border rounded-5"
                                    style={{ minHeight: "150px" }}
                                  >
                                    <div
                                      className=""
                                      style={{ overflow: "hidden" }}
                                    >
                                      <div
                                        className="map-header-layer"
                                        id="map2"
                                      >
                                        {auction.latitude &&
                                          auction.longitude && (
                                            <MapPicker
                                              defaultLocation={{
                                                lat: parseFloat(
                                                  auction.latitude
                                                ),
                                                lng: parseFloat(
                                                  auction.longitude
                                                ),
                                              }}
                                              zoom={15}
                                              mapTypeId="satellite"
                                              style={{ height: "350px" }}
                                              apiKey="AIzaSyDfYuPapVCAb5QOJZKQ36oB-vE9hRaVSO8"
                                              className="border rounded-5"
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </div>

                                  <a
                                    href={
                                      "https://www.google.com/maps?q=" +
                                      auction.latitude +
                                      ", " +
                                      auction.longitude
                                    }
                                    target="_new"
                                    className="btn btn-link"
                                    style={{ left: "30%" }}
                                  >
                                    <i
                                      class="fa fa-external-link"
                                      aria-hidden="true"
                                    />
                                    &nbsp;
                                    {i18n.t("open_google_map")}
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="btn btn-primary rounded-4 px-5 py-2"
                          onClick={() => {
                            this.setState({
                              is_active_bidnow: !this.state.is_active_bidnow,
                            });
                          }}
                        >
                          {i18n.t("bid_now")}
                        </button>
                      </div>
                    </div>

                    {this.state.auctionId &&
                      auction &&
                      this.state.is_active_bidnow && (
                        // <ActionSheet
                        //   show={this.state.is_active_bidnow}
                        //   onHide={() =>
                        //     this.setState({ is_active_bidnow: false })
                        //   }
                        // >
                        // </ActionSheet>
                        <Modal
                          size="lg"
                          show={this.state.is_active_bidnow}
                          onHide={() =>
                            this.setState({ is_active_bidnow: false })
                          }
                          contentClassName="bg-lt-secondary"
                        >
                          <Modal.Header closeButton className="border-0">
                            {/* <Modal.Title
                              id="example-modal-sizes-title-lg"
                              className="text-center text-primary"
                            >
                              {i18n.t("bid_now")}
                            </Modal.Title> */}
                          </Modal.Header>
                          <Modal.Body className="text-primary">
                            <BiddingComp auction={auction} lng={current_lng} />
                          </Modal.Body>
                        </Modal>
                      )}

                    <div className="col-xl-12 col-lg-12 col-md-12">
                      {/* Show only on mobile */}
                      {/* <div className="row show_mobile">
                        <div className="d-flex mb-0">
                          <div
                            className="p-2 bd-highlight"
                            onClick={(event) => {
                              this.addWishList(!this.state.wishlist);
                            }}
                          >
                            <img
                              height="15"
                              width="15"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/ph_heart.png"
                              }
                              alt=""
                            />
                          </div>
                          <div
                            className="p-2 bd-highlight"
                            onClick={(event) => {
                              this.addLike(!this.state.like);
                            }}
                          >
                            <img
                              height="15"
                              width="15"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/iconamoon_like-light.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="row px-1 py-1 pb-1">
                        <div className="col-lg-4 col-md-6 mb-1">
                          <div className="bg-light rounded-4 p-3 text-center card-background">
                            <div>
                              <span className="custom_text3">
                                {startDateAr && startDateAr["day"]
                                  ? i18n.t(startDateAr["day"])
                                  : ""}
                              </span>
                              <br />
                              <span className="custom_text4">
                                {i18n.t("start_date")}
                              </span>
                              <br />
                              <span className="custom_text5">
                                {startDateAr && startDateAr["date"]
                                  ? startDateAr["date"]
                                  : ""}
                              </span>
                              <br />
                              <span className="custom_text6">
                                ({start_time})
                              </span>
                            </div>
                            <div className="bg-white rounded-3 border p-1 mt-2 custom_bgcolor d-flex justify-content-center align-items-center">
                              <i className="fa fa-calendar text-danger" />
                              <span className="mx-1">
                                {auction.days_remaining}
                              </span>
                              <span className="custom_text4 mx-1">
                                {i18n.t("days_of_auction")}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-1">
                          <div className="bg-light rounded-4 p-1 text-center card-background">
                            {auction.is_bid_time_ends && (
                              <div className="row">
                                <div className="col-md-12">
                                  <div className=" border text-center">
                                    <div className="p-3 text-center text-danger">
                                      {i18n.t("bid_time_ends")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col-md-12">
                                <div className=" border text-center">
                                  <span className="custom_text7_green">
                                    {!auction.is_bid_time_ends &&
                                      auction.end_date && (
                                        <Countdown
                                          date={endTime}
                                          renderer={(props) =>
                                            renderer(props, auction.status)
                                          }
                                          onComplete={() => {
                                            this.setState({
                                              bidTimeStopped: true,
                                            });
                                          }}
                                          now={now}
                                        />
                                      )}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className=" bg-white rounded-4 card-background p-2 mt-3">
                                  <div>
                                    <p className="custom_text6 pb-0 mb-1">
                                      {i18n.t("start_bid_amount")}:{" "}
                                      {formatCurrency(auction.start_amount)}{" "}
                                      {i18n.t("omr")}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="custom_text6 pb-0 mb-1">
                                      {i18n.t("gurantee_amount")}:{" "}
                                      {formatCurrency(guarantee_amount)}{" "}
                                      {i18n.t("omr")}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="custom_text6 pb-0 mb-1">
                                      {i18n.t("visit_amount")}:{" "}
                                      {formatCurrency(auction.visit_amount)}{" "}
                                      {i18n.t("omr")}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="custom_text6 pb-0 text-danger">
                                      {i18n.t("current_amount")}:{" "}
                                      {formatCurrency(auction.current_amount)}{" "}
                                      {i18n.t("omr")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-12 mb-1">
                          <div className="bg-light rounded-4 p-1 text-center card-background">
                            <div className="row">
                              <div className="col-md-12">
                                <div>
                                  <div className="d-flex bd-highlight mb-0 justify-content-between d-mob-none">
                                    <div className="d-flex gap-1">
                                      <div
                                        className="sc-highlight"
                                        onClick={(event) => {
                                          this.addWishList(
                                            !this.state.wishlist
                                          );
                                        }}
                                      >
                                        <img
                                          height="15"
                                          width="15"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/ph_heart.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        className="sc-highlight"
                                        onClick={(event) => {
                                          this.addLike(!this.state.like);
                                        }}
                                      >
                                        <img
                                          height="15"
                                          width="15"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/iconamoon_like-light.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="sc-highlight gap-1"
                                      style={{ width: "50px" }}
                                    >
                                      <img
                                        height="15"
                                        width="15"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/ph_eye.png"
                                        }
                                        alt=""
                                      />
                                      <span>{auction.total_views}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-1">
                              <div className="col-md-12">
                                <div className="bg-white rounded-4 border p-2 card-background">
                                  <div className="row">
                                    <div className="col gap-2 d-flex justify-content-center">
                                      <div className="bg-white rounded-3 border">
                                        <div className="bg-white rounded-3 border custom_bgcolor align-self-start text-center p-1 mb-1 d-flex justify-content-center align-items-center">
                                          <i className="mdi mdi-calendar-clock text-danger" />
                                          <span className="custom_text8 mx-1">
                                            {i18n.t("end_date")}
                                            {" & "}
                                            {i18n.t("time")}
                                          </span>
                                        </div>
                                        <div className="p-1">
                                          <span className="custom_text6">
                                            {endDateAr && endDateAr["date"]
                                              ? endDateAr["date"]
                                              : ""}{" "}
                                            {end_time}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className=" rounded  border text-center custom-bg-color2 mt-2">
                                    <span className="custom_text9">
                                      {i18n.t("final_approval_lbl")}
                                    </span>
                                  </div>
                                  <div className="mt-2 text-center link_text"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <a
                              target="_new"
                              href={auction.file_additional_information}
                              style={{
                                color: "#ff064e",
                                fontSize: "0.7rem",
                                textDecoration: "underline",
                              }}
                            >
                              {i18n.t("additional_information")}
                            </a>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12">
                      {/* {this.state.auctionId && auction && (
                        <BiddingComp auction={auction} lng={current_lng} />
                      )} */}
                      {/* <div className="card border ">
                        <div className="p-1">
                          <AuctionShare auction={auction} />
                        </div>
                      </div> */}

                      {/* <div>
                        <div className="mb-2">
                          <button
                            className="btn btn-block btn-warning border rounded-5 py-2"
                            type="button"
                            onClick={(event) => {
                              this.addWishList(!this.state.wishlist);
                            }}
                          >
                            <i
                              className={
                                this.state.wishlist
                                  ? "fa fa-heart"
                                  : "fa fa-heart-o"
                              }
                            />
                            &nbsp; {i18n.t("add_to_wishlist")}
                          </button>
                        </div>
                        <div>
                          <a
                            className="btn btn-block btn-warning border rounded-5 py-2"
                            href={email_cont}
                          >
                            <i className="fa fa-envelope-o" />
                            &nbsp; {i18n.t("email_to_a_friend")}
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="row mt-3">
                    <AuctionsDetailTabs auction={auction} lng={current_lng} />
                  </div> */}
                </div>
              </section>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
