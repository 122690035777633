import React from "react";
import i18n from "../../../i18n";
import swal from "sweetalert";
// import { Spinner } from "react-bootstrap";
import { updateUser, roles } from "../../../models/users";

export class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: this.props.data,
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {}

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        let { id } = this.state.input;
        const { password, ...inputWithoutPassword } = this.state.input;
        const response = await updateUser(inputWithoutPassword, id);
        if (response.success) {
          swal(
            i18n.t("success"),
            "User update successfully completed!!",
            "success",
            {
              buttons: false,
              timer: 2000,
            }
          );
          this.props.triggerReload(true);
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let { input } = this.state;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your Name.";
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = "Please enter a valid Username.";
    }
    if (!input["country_code"]) {
      isValid = false;
      errors["country_code"] = "Please enter phone country code.";
    }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your Phone Number.";
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter valid email address.";
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your Password.";
    }
    if (!input["role"]) {
      isValid = false;
      errors["role"] = "Please select a role.";
    }

    this.setState({
      errors: errors,
    });
    console.log(errors);
    return isValid;
  }

  render() {
    //let roles_needed = this.props.roles_needed || true;
    return (
      <React.Fragment>
        <div className="row">
          <div className="single-page">
            <div className="col-md-12 d-block mx-auto">
              <div className="wrapper wrapper2">
                <form
                  id="UpdateUser"
                  className="card-body"
                  tabindex="500"
                  autoComplete="off"
                >
                  <div className="name mb-1">
                    <label>{i18n.t("name")}</label>&nbsp;
                    <input
                      type="text"
                      name="name"
                      value={this.state.input.name}
                      onChange={this.handleChange}
                      autoComplete="name"
                      className="form-control custom-input "
                    />
                    <div className="text-danger">{this.state.errors.name}</div>
                  </div>

                  <div className="mb-1">
                    <label>{i18n.t("phone_number")}</label>
                    <div className="input-group input-group-sm mt-2 ">
                      <span className="country-flag-field border col-1">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/flags/ar.svg"
                          }
                          alt=""
                          width="25"
                        />
                      </span>
                      <input
                        type="text"
                        className="form-control col-11"
                        style={{ "z-index": 0 }}
                        name="mobile"
                        value={this.state.input.mobile}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="text-danger">
                      {this.state.errors.mobile}
                    </div>
                  </div>

                  <div className="email mb-1">
                    <label>{i18n.t("email")}</label>
                    <input
                      type="text"
                      name="email"
                      value={this.state.input.email}
                      onChange={this.handleChange}
                      style={{ direction: "ltr" }}
                      className="form-control custom-input "
                    />
                    <div className="text-danger">{this.state.errors.email}</div>
                  </div>

                  <div className="username mb-1">
                    <label>{i18n.t("username")}</label>
                    <input
                      type="text"
                      name="username"
                      value={this.state.input.username}
                      onChange={this.handleChange}
                      autoComplete="username"
                      className="form-control custom-input "
                    />
                    <div className="text-danger">
                      {this.state.errors.username}
                    </div>
                  </div>

                  {this.state.input.role !== "C" && (
                    <div className="mb-1">
                      <label>{i18n.t("role")}</label>
                      <select
                        className="form-control custom-input "
                        name="role"
                        value={this.state.input.role}
                        onChange={this.handleChange}
                      >
                        <option value="">
                          -- {i18n.t("select_any_role")} --
                        </option>
                        {Object.keys(roles).map((rKey, i) => {
                          return (
                            <option value={rKey} key={i}>
                              {roles[rKey]}
                            </option>
                          );
                        })}
                      </select>
                      <div className="text-danger">
                        {this.state.errors.role}
                      </div>
                    </div>
                  )}

                  <button
                    type="button"
                    className="btn btn-primary btn-block mt-5"
                    onClick={this.handleSubmit}
                  >
                    {i18n.t("submit")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
