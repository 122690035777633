import React from "react";
import i18n from "../../i18n";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import i18n from "../../i18n";
// let lang = localStorage.getItem("I18N_LANGUAGE");
export class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer className="mzad-footer">
          <div className="footer-right-section">
            <img src="/assets/logo/mzad-footer.png" alt="logo" width="150" />
          </div>
        </footer>
        <div className="copyright">
          Copyright &copy;ROP Powered By Mzadcom.com
        </div>

        <button
          className="footer-message auction-button"
          onClick={() => {
            var anchor = document.createElement("a");
            anchor.href = "https://api.whatsapp.com/send?phone=96892788822";
            anchor.target = "_blank";
            anchor.click();
          }}
        >
          <div className="image-container">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/messaging.png"}
              height="26.25"
              width="26.25"
              alt=""
            />
          </div>
          <span className="msg-label">{i18n.t("messaging")}</span>
        </button>
      </React.Fragment>
    );
  }
}
