import React from "react";
import { HomeBanner } from "./home/homeBanner";
import { getCategories } from "../models/categories";
import i18n from "../i18n";
import { Link } from "react-router-dom";

let lng = localStorage.getItem("I18N_LANGUAGE");
export class CategoryAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    this.loadAllCategories();
  }

  async loadAllCategories() {
    const response = await getCategories("");
    let categories = response.data;
    this.setState({ categories });
  }

  render() {
    let { categories } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />
        <section className="mt-4 mb-4 overflow-hidden">
          <div className="container-fluid p-0">
            <div className="row mx-5 mx-1-mob">
              <div className="col-12">
                <div className="container-fluid">
                  <h4 className="text-primary mb-5 mt-3">
                    <i
                      className="mdi mdi-microsoft"
                      style={{ fontSize: "1.5rem" }}
                    />
                    &nbsp;{i18n.t("categories")}
                  </h4>
                  <div className="row">
                    {categories.map((item, i) => {
                      return (
                        <div className="col-md-3 col-sm-6">
                          <div
                            className="category-icon card bg-primary me-3 text-center px-5 py-2 d-flex flex-column justify-content-center align-items-center"
                            style={{ minHeight: "150px" }}
                          >
                            <div>
                              <Link
                                to={`/auctions?sec=main&category=${item.id}`}
                                className=""
                              >
                                <img
                                  src={item?.file_category_image}
                                  alt="category"
                                  width={75}
                                />
                              </Link>
                            </div>
                            <div>
                              <Link
                                to={`/auctions?sec=main&category=${item.id}`}
                                className=""
                              >
                                {lng === "ar"
                                  ? item?.category_name_ar
                                  : item?.category_name}
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
