import React from "react";
import i18n from "../../i18n";

class FileInputNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFileName: this.props.default_placeholder
        ? this.props.default_placeholder
        : i18n.t("drag_and_drop"),
    };
    this.fileInputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const selectedFile = event.target.files[0];
    this.setState({
      selectedFileName: this.props.default_placeholder
        ? this.props.default_placeholder
        : i18n.t("drag_and_drop"),
    });
    if (selectedFile && selectedFile.name) {
      this.setState({ selectedFileName: selectedFile.name });
    }
    this.props.onChange(event);
  }

  shortenFileName = (name, maxLen) => {
    if (name.length <= maxLen) return name;
    const start = name.substr(0, maxLen / 2 - 3);
    const end = name.substr(name.length - maxLen / 2 + 3);
    return `${start}...${end}`;
  };

  render() {
    let placeholder = this.props.placeholder
      ? this.props.placeholder
      : i18n.t("drag_and_drop");
    let info = this.props.help_text ? this.props.help_text : "";
    let multiple = this.props.multiple ? true : false;
    let wrapperClass = `cloud-upload-btn-wrapper d-flex justify-content-between ${this.props.wrapperClass}`;
    return (
      <div class={wrapperClass}>
        <div className="uploader-icon p-2 m-0 rounded-2">
          <i className="mdi mdi-file-image" />
        </div>
        <div className="mt-1 text-muted p-1 px-2 text-center overflow-hidden">
          <p
            className="p-0 m-1 uploader-info-title"
            title={this.state.selectedFileName}
          >
            {this.shortenFileName(this.state.selectedFileName, 35)}
          </p>
          <p className="p-0 m-0 text-grey uploader-info-subtitle">{info}</p>
        </div>
        <button className="btn btn-outline-white bg-white rounded-1 uploader-placeholder">
          <span>{placeholder}</span>
        </button>

        {this.props.format_url && (
          <div className="d-flex justify-content-end uploader-format-viewer">
            <a
              href={this.props.format_url}
              target="_new"
              className="text-gray"
              style={{ fontSize: "0.6rem", textWrap: "nowrap" }}
            >
              {this.props.is_file_view ? i18n.t("view") : i18n.t("view_format")}
            </a>
          </div>
        )}
        <input
          type="file"
          ref={this.fileInputRef}
          className={this.props.className}
          placeholder={this.props.placeholder}
          id={this.props.id}
          name={this.props.name}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          multiple={multiple}
        />
      </div>
    );
  }
}

export default FileInputNew;
