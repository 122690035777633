import React from "react";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import i18n from "../../../i18n";
import { BiddingModal } from "../../helpers/biddingModal";

let lng = localStorage.getItem("I18N_LANGUAGE");
export class AuctionRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_popup: false,
      bid_count: 0,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.closeModal = this.closeModal.bind(this);
    this.updateRow = this.updateRow.bind(this);
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return this.props.auction.end_date_formatted;
    } else {
      let warning =
        days === 0 && hours === 0 && minutes === 0 && seconds < 10
          ? true
          : false;
      return (
        <span
          style={{
            border: warning ? "1px solid #ff382b" : "1px solid #1da1f3",
            padding: "0.5rem",
            borderRadius: "0.5rem",
            fontSize: "0.5rem",
            textWrap: "nowrap",
          }}
          className={
            warning ? "text-danger font_05_rem" : "text-info font_05_rem"
          }
        >
          {days}
          {i18n.t("D")} : {hours}
          {i18n.t("H")} : {minutes}
          {i18n.t("M")} : {seconds}
          {i18n.t("S")}
        </span>
      );
    }
  };

  componentDidMount() {
    this.setState({ bid_count: this.props.auction.bid_count });
  }

  closeModal() {
    this.setState({ open_popup: false });
  }

  updateRow(data) {
    this.setState({ bid_count: data });
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let key = this.props.keys;
    let auction = this.props.auction;
    let images = auction.images;
    let img_src = process.env.PUBLIC_URL + "/assets/images/placeholder.jpg";
    if (images.length > 0) {
      img_src = images[0].image;
    }

    let is_vehicle = false;
    if (auction && auction.categoryDetails) {
      let cat = auction.categoryDetails;
      is_vehicle = cat.category_name === "Numbers" ? true : false;
    }

    let now = this.state.now;
    let aucEndDate = auction.end_date;
    let auctionEndTime = new Date(this.createDate(aucEndDate)).getTime();

    let slno = key;
    if (lng === "ar") {
      slno = key.toLocaleString("ar-u-nu-arab");
    }
    return (
      <React.Fragment>
        <tr>
          <td className="text-center font-75rem text-center">{slno}</td>
          <td className="font-75rem text-center" style={{ width: "75px" }}>
            <Link to={"/auction-details/" + auction.id} className="">
              {/* <picture>
                <img src={img_src} className="img-fluid img-thumbnail" alt="" />
              </picture> */}
              {/* <img
                src={img_src}
                className="width_20_mob"
                alt=""
                style={{
                  height: "auto",
                  border: "1px solid #afe7ff",
                  cursor: "pointer",
                }}
                //   onClick={() => {
                //     this.props.getImagePopup(true, images);
                //   }}
              /> */}
            </Link>
            <p className="p-0 m-0">
              <span className={is_vehicle ? "registration-ui" : ""}>
                {lng === "ar" ? auction.title_ar : auction.title}
              </span>
            </p>
          </td>

          <td>
            {auction.organizationDetails
              ? lng === "ar"
                ? auction.organizationDetails.organization_name_ar
                : auction.organizationDetails.organization_name
              : ""}
          </td>

          <td className="font-75rem">
            <p className="wrap-no p-0 m-2 text-center">
              {auctionEndTime && (
                <Countdown
                  date={auctionEndTime}
                  renderer={this.renderer}
                  now={now}
                  onComplete={() => {
                    this.setState({ show_end_time: true });
                  }}
                />
              )}
            </p>
          </td>
          <td className="text-center d-none-mob font-75rem">
            {i18n.t("omr")}&nbsp;{auction.start_amount}
          </td>
          <td className="text-center font-75rem">
            {i18n.t("omr")}&nbsp;{auction.current_amount}
          </td>
          <td className="font-75rem">{this.state.bid_count}</td>
          {this.props.screen === "dashboad" ? (
            <td className="font-75rem">{auction.my_rank}</td>
          ) : (
            false
          )}
          <td className="text-center wrap-no">
            <div className="d-flex">
              <Link
                to={"/auction-details/" + auction.id}
                className="btn btn-info btn-sm m-1 btn-sm font-75rem rounded-5"
              >
                {/**btn-block-mob */}
                <i className="fa fa-eye"></i>
                &nbsp;
                <span className="d-mob-none wrap-no">{i18n.t("details")}</span>
              </Link>

              {auction.status === "A" && (
                <button
                  className="btn btn-sm btn-primary m-1 btn-sm font-75rem rounded-5"
                  onClick={() => {
                    this.setState({
                      open_popup: true,
                    });
                  }}
                >
                  {/**btn-block-mob */}
                  <i className="fa fa-gavel"></i>
                  &nbsp;
                  <span className="d-mob-none wrap-no">
                    {i18n.t("bid_now")}
                  </span>
                </button>
              )}
            </div>
          </td>
        </tr>

        <BiddingModal
          open_popup={this.state.open_popup}
          closeModal={this.closeModal}
          auction={auction}
          lng={lng}
          key={key}
          updateRow={this.updateRow}
        />
      </React.Fragment>
    );
  }
}
