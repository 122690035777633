import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { resetPassword, checkValidResetUrl } from "../../models/login";
import swal from "sweetalert";
import { HomeBanner } from "../home/homeBanner";
export class ResetPassword extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let params = path.split("/");
    let email = params[params.length - 2];
    let token = params[params.length - 1];

    this.state = {
      input: {},
      errors: {},
      email: email,
      token: token,
    };
    this.handleChange = this.handleChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.checkValidResetUrl = this.checkValidResetUrl.bind(this);
  }
  componentDidMount() {
    this.checkValidResetUrl();
  }

  async checkValidResetUrl() {
    const response = await checkValidResetUrl({
      email: this.state.email,
      token: this.state.token,
    });
    if (!response.success) {
      window.location = "/notfound";
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async resetPassword() {
    if (this.state.input.password !== this.state.input.c_password) {
      swal("Failed", "Password mismatch", "error");
      return;
    }

    const response = await resetPassword({
      password: this.state.input.password,
      email: this.state.email,
      token: this.state.token,
    });
    if (response.success) {
      swal(i18n.t("success"), response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          window.location.href = "/login";
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <React.Fragment>
        <HomeBanner />
        <div className="page-container">
          <div className="content-bg">
            <div className="container py-5">
              <div className="row justify-content-center">
                <div className="mx-auto">
                  <div className="col-lg-12 col-lg-6 col-lg-3 d-flex justify-content-center">
                    <div className="card w-65">
                      <div
                        className="card-header bg-primary"
                        style={{ zIndex: "999" }}
                      >
                        <h4>
                          <i
                            className="mdi mdi-account-circle"
                            style={{ fontSize: "1.5rem" }}
                          />
                          &nbsp;{i18n.t("login")}
                        </h4>
                      </div>
                      <div
                        className="card-body"
                        style={{ zIndex: "99", marginTop: "-35px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="page-container-otp-login">
          <div className="divider"></div>
          <div className="content">
            <div className="main-container">
              <div className="container-header">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/login-icon.png"}
                  alt="login"
                />
                <span className="m-1">{i18n.t("reset_password")}</span>
              </div>
              <div className="inner-container">
                <div className="left-section">
                  <form id="forgotpsd" className="card-body">
                    <div>
                      <div className="col-md-12">
                        <br></br>
                        <div>
                          <label for="Name">
                            <span className="text-black">*</span>
                            {i18n.t("password")}
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            name="password"
                            value={this.state.input.password}
                            onChange={this.handleChange}
                            className="form-control custom-input "
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="col-md-12">
                        <br></br>
                        <div>
                          <label for="Name">
                            <span className="text-black">*</span>
                            {i18n.t("confirm_password")}
                          </label>
                        </div>
                        <div>
                          <input
                            type="text"
                            name="c_password"
                            value={this.state.input.c_password}
                            onChange={this.handleChange}
                            className="form-control custom-input "
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 m-auto mt-4 mb-5">
                      <button
                        type="button"
                        className="btn btn-warning  btn-block"
                        style={{ width: "100%" }}
                        disabled={
                          !this.state.input.password ||
                          !this.state.input.c_password
                        }
                        onClick={this.resetPassword}
                      >
                        {i18n.t("send")}
                      </button>
                    </div>
                    <div className="text-center text-dark mb-0">
                      Forget it,{" "}
                      <Link to="/login" className="text-danger">
                        send me back{" "}
                      </Link>{" "}
                      to the{" "}
                      <Link to="/login" className="text-danger">
                        sign in
                      </Link>
                      .
                    </div>
                  </form>
                </div>
                <div className="right-section d-none d-md-block">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/1696936701627 1.png"
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
