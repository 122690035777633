import React from "react";
import { Link } from "react-router-dom";
import registerUser from "../../models/register";
import { SocialSignin } from "./socialSignin";
import swal from "sweetalert";
import contryData from "../../constants/country.json";
import i18n from "../../i18n";
import Dropdown from "react-bootstrap/Dropdown";
//import { HomeBanner } from "../home/homeBanner";
import FileInputNew from "../helpers/fileInputNew";
import { Spinner } from "react-bootstrap";
import { HomeBanner } from "../home/homeBanner";

let lng = localStorage.getItem("I18N_LANGUAGE");
const codeData = [...contryData];
export class ClientRegistration extends React.Component {
  constructor() {
    super();
    this.state = {
      is_loading: false,
      input: {
        country_code: "+968",
        client_type: "private",
      },
      is_company: true,
      selected_country: {},
      t_c_acept: false,
      errors: {},
      file_organization_image: null,
      file_auth_letter: null,
      file_id_number: null,
      file_cr_number: null,
      file_vat_certificate: null,
      file_additional_doc: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }
  componentDidMount() {
    var scrollDiv = document.getElementById("scroll-to");
    if (scrollDiv) {
      scrollDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    var defaultCodeValue = this.getObjectByKeyValue(
      codeData,
      "dial_code",
      "+968"
    );
    this.setState({ selected_country: defaultCodeValue });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }

  onFileChange = (event) => {
    if (!this.validateFileSize(event.target.files[0])) {
      swal("Failed", "File size should be less than 5MB", "error");
      return;
    }
    if (event.target.name === "file_organization_image") {
      this.setState({ file_organization_image: event.target.files[0] });
    }
    if (event.target.name === "file_auth_letter") {
      this.setState({ file_auth_letter: event.target.files[0] });
    }
    if (event.target.name === "file_id_number") {
      this.setState({ file_id_number: event.target.files[0] });
    }
    if (event.target.name === "file_cr_number") {
      this.setState({ file_cr_number: event.target.files[0] });
    }
    if (event.target.name === "file_vat_certificate") {
      this.setState({ file_vat_certificate: event.target.files[0] });
    }
    if (event.target.name === "file_additional_doc") {
      this.setState({ file_additional_doc: event.target.files[0] });
    }
  };

  validateFileSize(file) {
    //const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      return;
    }
    return true;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.validate()) {
      try {
        this.setState({ is_loading: true });
        const formData = new FormData();

        formData.append(
          "organization_name",
          this.state.input.organization_name
        );
        formData.append(
          "organization_name_ar",
          this.state.input.organization_name_ar
        );
        formData.append("description", this.state.input.description);
        formData.append("description_ar", this.state.input.description_ar);
        formData.append("cr_expiry_date", this.state.input.cr_expiry_date);
        formData.append("client_type", this.state.input.client_type);
        formData.append("contact_number", this.state.input.contact_number);
        formData.append("focal_point_name", this.state.input.focal_point_name);
        formData.append("name", this.state.input.organization_name);
        formData.append("country_code", this.state.input.country_code);
        formData.append("mobile", this.state.input.mobile);
        formData.append("email", this.state.input.email);
        formData.append("username", this.state.input.username);
        formData.append("password", this.state.input.password);
        //formData.append("confirm_password", this.state.input.confirm_password);
        // formData.append(
        //   "resident_card_number",
        //   this.state.input.resident_card_number
        // );
        //formData.append("account_number", this.state.input.account_number);
        //formData.append("bank", this.state.input.bank);
        //formData.append("authority_name", this.state.input.authority_name);
        formData.append("cr_number", this.state.input.cr_number);
        formData.append("vat_number", this.state.input.vat_number);
        formData.append("is_company", true);
        formData.append("is_client", true);

        if (this.state.file_organization_image) {
          formData.append(
            "file_organization_image",
            this.state.file_organization_image,
            this.state.file_organization_image.name
          );
        }

        if (this.state.file_id_number) {
          formData.append(
            "file_id_number",
            this.state.file_id_number,
            this.state.file_id_number.name
          );
        }
        if (this.state.file_auth_letter) {
          formData.append(
            "file_auth_letter",
            this.state.file_auth_letter,
            this.state.file_auth_letter.name
          );
        }
        if (this.state.file_cr_number) {
          formData.append(
            "file_cr_number",
            this.state.file_cr_number,
            this.state.file_cr_number.name
          );
        }
        if (this.state.file_vat_certificate) {
          formData.append(
            "file_vat_certificate",
            this.state.file_vat_certificate,
            this.state.file_vat_certificate.name
          );
        }
        if (this.state.file_additional_doc) {
          formData.append(
            "file_additional_doc",
            this.state.file_additional_doc,
            this.state.file_additional_doc.name
          );
        }
        const response = await registerUser(formData);
        if (response.success) {
          this.setState({ is_loading: false });
          swal(
            i18n.t("success"),
            "Registration completed successfully!!",
            "success",
            {
              buttons: false,
              timer: 2000,
            }
          )
            .then((value) => {
              console.log(response.data);
              //this.resetForm();
              //window.location.href = "/login";
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          this.setState({ is_loading: false });
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        this.setState({ is_loading: false });
        console.log(err);
      }
    }
  };

  resetForm() {
    let input = {};
    input["organization_name"] = "";
    input["organization_name_ar"] = "";
    input["mobile"] = "";
    input["email"] = "";
    input["username"] = "";
    input["country_code"] = "+968";
    input["password"] = "";
    //input["confirm_password"] = "";
    this.setState({
      input: input,
      file_organization_image: null,
      file_auth_letter: null,
      file_id_number: null,
      file_cr_number: null,
      file_vat_certificate: null,
      file_additional_doc: null,
    });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.input;
    console.log(input);
    let errors = {};
    let isValid = true;
    if (!input["organization_name"]) {
      isValid = false;
      errors["organization_name"] = i18n.t("validate_name");
    }
    if (!input["organization_name_ar"]) {
      isValid = false;
      errors["organization_name_ar"] = i18n.t("validate_name");
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = i18n.t("validate_username");
    }
    if (!input["country_code"]) {
      isValid = false;
      errors["country_code"] = i18n.t("validate_country_code");
    }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = i18n.t("validate_phone");
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = i18n.t("validate_email");
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = i18n.t("validate_password");
    }

    if (!this.state.file_organization_image) {
      isValid = false;
      errors["file_organization_image"] = i18n.t("validate_file_id");
    }

    if (input["client_type"] === "private") {
      if (!input["cr_number"]) {
        isValid = false;
        errors["cr_number"] = i18n.t("validate_cr_number");
      }
      if (!this.state.file_cr_number) {
        isValid = false;
        errors["file_cr_number"] = i18n.t("validate_file_cr");
      }

      if (!input["vat_number"]) {
        isValid = false;
        errors["vat_number"] = i18n.t("validate_vat_no");
      }

      if (!this.state.file_vat_certificate) {
        isValid = false;
        errors["file_vat_certificate"] = i18n.t("validate_file_vat");
      }
      if (!this.state.file_additional_doc) {
        isValid = false;
        errors["file_additional_doc"] = i18n.t("validate_file_doc");
      }
    }

    this.setState({
      errors: errors,
    });
    return isValid;
  }

  getObjectByKeyValue(jsonArray, key, value) {
    for (var i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i][key] === value) {
        return jsonArray[i];
      }
    }
    return null;
  }

  render() {
    let selected_img =
      this.state.selected_country && this.state.selected_country.code
        ? this.state.selected_country.code.toLowerCase()
        : "";
    return (
      <React.Fragment>
        <HomeBanner />
        <div className="page-container">
          <div className="content-bg">
            <div className="container py-5">
              <div className="row justify-content-center">
                <div className="mx-auto">
                  <div className="col-lg-12 col-lg-6 col-lg-3 d-flex justify-content-center">
                    <div className="card w-65">
                      <div
                        className="card-header bg-primary"
                        style={{ zIndex: "999" }}
                      >
                        <h4>
                          <i
                            className="mdi mdi-account-plus"
                            style={{ fontSize: "1.5rem" }}
                          />
                          &nbsp;{i18n.t("register_client")}
                        </h4>
                      </div>
                      <div
                        className="card-body"
                        style={{ zIndex: "99", marginTop: "-35px" }}
                      >
                        <form
                          id="Register"
                          className=""
                          tabindex="500"
                          autoComplete="off"
                          onSubmit={this.handleSubmit}
                        >
                          <div class="container container-fluid">
                            <div className="row">
                              <div className="col-md-6 ">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("title")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="organization_name"
                                    value={this.state.input.organization_name}
                                    onChange={this.handleChange}
                                    autoComplete="organization_name"
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.organization_name}
                                </div>
                              </div>

                              <div className="col-md-6 ">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("title_ar")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="organization_name_ar"
                                    value={
                                      this.state.input.organization_name_ar
                                    }
                                    onChange={this.handleChange}
                                    autoComplete="organization_name_ar"
                                    className="form-control custom-input "
                                    style={{ direction: "rtl" }}
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.organization_name_ar}
                                </div>
                              </div>

                              <div className="col-md-6 ">
                                <div>
                                  <label for="description">
                                    <span className="text-black">*</span>
                                    {i18n.t("description")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="description"
                                    value={this.state.input.description}
                                    onChange={this.handleChange}
                                    autoComplete="description"
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.description}
                                </div>
                              </div>

                              <div className="col-md-6 ">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("description_ar")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="description_ar"
                                    value={this.state.input.description_ar}
                                    onChange={this.handleChange}
                                    autoComplete="description_ar"
                                    className="form-control custom-input "
                                    style={{ direction: "rtl" }}
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.description_ar}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div>
                                  <label for="form-label">
                                    <span className="text-black">*</span>
                                    {i18n.t("upload_org_logo")}
                                  </label>
                                </div>

                                <div>
                                  <FileInputNew
                                    wrapperClass=""
                                    className="form-control custom-input "
                                    placeholder={i18n.t("choose_file")}
                                    help_text="Image size must be of size 200(w) and 200(h)"
                                    name="file_organization_image"
                                    id="file_organization_image"
                                    onChange={this.onFileChange}
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.file_organization_image}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("username")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="username"
                                    value={this.state.input.username}
                                    onChange={this.handleChange}
                                    autoComplete="username"
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.username}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("password")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="password"
                                    name="password"
                                    value={this.state.input.password}
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.password}
                                </div>
                              </div>
                              {/* <div className="col-md-3">
                            
                            <div>
                              <label for="Name">
                                <span className="text-black">*</span>
                                {i18n.t("confirm_password")}
                              </label>
                            </div>
                            
                            <div>
                              <input
                                type="password"
                                name="confirm_password"
                                value={this.state.input.confirm_password}
                                onChange={this.handleChange}
                                className="form-control custom-input "
                              />
                            </div>
                            <div className="text-danger text-justify">
                              {this.state.errors.confirm_password}
                            </div>
                          </div> */}

                              <div className="col-md-6 ">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("email")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    name="email"
                                    value={this.state.input.email}
                                    onChange={this.handleChange}
                                    style={{ direction: "ltr" }}
                                    className="form-control custom-input "
                                  />
                                </div>

                                <div className="text-danger text-justify">
                                  {this.state.errors.email}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div>
                                  <label for="Name">
                                    <span className="text-black">*</span>
                                    {i18n.t("phone_number")}
                                  </label>
                                </div>

                                <div>
                                  <Dropdown className="width30 flag-dd">
                                    <Dropdown.Toggle variant="country">
                                      {this.state.selected_country && (
                                        <div>
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/images/flags/" +
                                              selected_img +
                                              ".svg"
                                            }
                                            alt=""
                                            style={{ width: "25px" }}
                                          />
                                          {/* {this.state.selected_country.label} */}
                                        </div>
                                      )}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      {codeData.map((item, i) => {
                                        let img = item.code.toLowerCase();
                                        return (
                                          <Dropdown.Item
                                            onClick={() => {
                                              let { input } = this.state;
                                              input.country_code =
                                                item.dial_code;
                                              this.setState({
                                                input: item,
                                                selected_country: item,
                                              });
                                            }}
                                          >
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/images/flags/" +
                                                img +
                                                ".svg"
                                              }
                                              alt=""
                                              style={{ width: "25px" }}
                                            />{" "}
                                            {item.label}
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>

                                  <input
                                    type="text"
                                    style={{ "z-index": 0 }}
                                    name="mobile"
                                    value={this.state.input.mobile}
                                    onChange={this.handleChange}
                                    className="form-control custom-input  px-6"
                                  />
                                </div>

                                <div className="text-danger text-justify">
                                  {this.state.errors.mobile}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-3">
                                  <div>
                                    <select
                                      className="form-select custom-input "
                                      name="client_type"
                                      value={this.state.input.client_type}
                                      onChange={this.handleChange}
                                    >
                                      <option value="government">
                                        {i18n.t("government")}
                                      </option>
                                      <option value="private">
                                        {i18n.t("private")}
                                      </option>
                                    </select>
                                  </div>
                                  <div className="text-danger text-justify">
                                    {this.state.errors.client_type}
                                  </div>
                                </div>
                              </div>

                              {this.state.input.client_type === "private" && (
                                <>
                                  <div className="col-md-6">
                                    <div>
                                      <label>
                                        <span className="text-black">*</span>
                                        {i18n.t("cr_number")}
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="text"
                                        placeholder=""
                                        value={this.state.input.cr_number}
                                        name="cr_number"
                                        onChange={this.handleChange}
                                        className="form-control custom-input "
                                      />
                                    </div>
                                    <div className="text-danger text-justify">
                                      {this.state.errors.cr_number}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div>
                                      <label>
                                        <span className="text-black">*</span>
                                        {i18n.t("expiry_date")}
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="date"
                                        placeholder=""
                                        value={this.state.input.cr_expiry_date}
                                        name="cr_expiry_date"
                                        onChange={this.handleChange}
                                        className="form-control custom-input "
                                      />
                                    </div>
                                    <div className="text-danger text-justify">
                                      {this.state.errors.cr_expiry_date}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div>
                                      <label>
                                        <span className="text-black">*</span>
                                        {i18n.t("attacth_cr_copy")}
                                      </label>
                                    </div>

                                    <div>
                                      <FileInputNew
                                        wrapperClass=""
                                        placeholder={i18n.t("choose_file")}
                                        className="form-control custom-input "
                                        name="file_cr_number"
                                        id="file_cr_number"
                                        onChange={this.onFileChange}
                                      />
                                    </div>
                                    <div className="text-danger text-justify">
                                      {this.state.errors.file_cr_number}
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div>
                                      <label>
                                        <span className="text-black">*</span>
                                        {i18n.t("vat_number")}
                                      </label>
                                    </div>

                                    <div>
                                      <input
                                        type="text"
                                        className="form-control custom-input "
                                        placeholder=""
                                        value={this.state.input.vat_number}
                                        name="vat_number"
                                        onChange={this.handleChange}
                                      />
                                    </div>
                                    <div className="text-danger text-justify">
                                      {this.state.errors.vat_number}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div>
                                      <label>
                                        <span className="text-black">*</span>
                                        {i18n.t("attach_vat")}
                                      </label>
                                    </div>

                                    <div>
                                      <FileInputNew
                                        wrapperClass=""
                                        placeholder={i18n.t("choose_file")}
                                        className="form-control custom-input "
                                        name="file_vat_certificate"
                                        id="file_vat_certificate"
                                        onChange={this.onFileChange}
                                      />
                                    </div>
                                    <div className="text-danger text-justify">
                                      {this.state.errors.file_vat_certificate}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div>
                                      <label for="form-label">
                                        <span className="text-black">*</span>
                                        {i18n.t("additional_doc")}
                                      </label>
                                    </div>

                                    <div>
                                      <FileInputNew
                                        wrapperClass=""
                                        className="form-control custom-input "
                                        placeholder={i18n.t("choose_file")}
                                        name="file_additional_doc"
                                        id="file_additional_doc"
                                        onChange={this.onFileChange}
                                      />
                                    </div>
                                    <div className="text-danger text-justify">
                                      {this.state.errors.file_additional_doc}
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="col-md-6">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("contact_number")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={this.state.input.contact_number}
                                    name="contact_number"
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.contact_number}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div>
                                  <label>
                                    <span className="text-black">*</span>
                                    {i18n.t("focal_point_name")}
                                  </label>
                                </div>

                                <div>
                                  <input
                                    type="text"
                                    placeholder=""
                                    value={this.state.input.focal_point_name}
                                    name="focal_point_name"
                                    onChange={this.handleChange}
                                    className="form-control custom-input "
                                  />
                                </div>
                                <div className="text-danger text-justify">
                                  {this.state.errors.focal_point_name}
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                textAlign: lng === "ar" ? "right" : "left",
                              }}
                            >
                              <input
                                style={{
                                  height: "15px",
                                  padding: "0",
                                  width: "15px",
                                }}
                                type="checkbox"
                                name="t_c"
                                checked={this.state.t_c_acept}
                                onChange={(event) => {
                                  this.setState({
                                    t_c_acept: !this.state.t_c_acept,
                                  });
                                }}
                              />{" "}
                              <span className="text text-dark">
                                {i18n.t("please_accept")}{" "}
                                <a
                                  href="/terms"
                                  target="_new"
                                  className="text-blue"
                                >
                                  {i18n.t("terms_conditions")}
                                </a>
                              </span>
                            </div>

                            <div class="text-center">
                              <button
                                type="submit"
                                className="btn btn-color "
                                disabled={!this.state.t_c_acept}
                              >
                                {this.state.is_loading && (
                                  <Spinner size="sm" animation="grow" />
                                )}
                                {!this.state.is_loading && (
                                  <span>{i18n.t("signup")}</span>
                                )}
                              </button>
                            </div>

                            <div class="text-center">
                              <p class="mt-3">
                                {i18n.t("alreadyhaveaccount")}{" "}
                                <Link to="/login" className="text-blue">
                                  {" "}
                                  {i18n.t("login")}{" "}
                                </Link>
                              </p>
                            </div>
                            <div class="horizontal-line">
                              <span class="line-text">
                                {i18n.t("or_continue")}
                              </span>
                            </div>
                            <div class="text-center">
                              <SocialSignin />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
