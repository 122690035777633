import React from "react";
import { HomeBanner } from "../../components/home/homeBanner";
//import { AuctionListing } from "../../components/home/auctionListing";
//import { getLoggedUser } from "../../state/user";
// import { Testimonials } from "./testimonials";
//import { BeingPart } from "./beignpart";
//import { Categories } from "./categories";
import { CategoryAuctions } from "./categoryAuctions";
//import { CharityAuctions } from "./charityAuctions";
import { CategorySearch } from "./helpers/categorySearch";
import { HomeCategories } from "./helpers/HomeCategories";
import { HomeSearchResults } from "./helpers/homeSearchResults";
import { getEndingAuctions } from "../../models/auctions";
//import { ClientSection } from "./helpers/clientSection";
//import { Categories } from "./categories";
//import { Specials } from "./specials";
//import { PinnedMap } from "./pinnedMap";
//const user = getLoggedUser();
//let is_logged = localStorage.getItem("is_logged_in") ? true : false;
export class Homepage extends React.Component {
  constructor() {
    super();
    this.state = {
      qry: { limit: 10, page: 1 },
      auctions: [],
      total: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadEndingAuctions();
  }

  async loadEndingAuctions() {
    let { qry } = this.state;
    let q = new URLSearchParams(qry).toString();
    let result = await getEndingAuctions(q);
    let total = result.meta.total;
    this.setState({ auctions: result.data, total });
  }

  render() {
    let { categories } = this.props;
    return (
      <div>
        <HomeBanner />
        <CategorySearch />
        <HomeSearchResults
          auctions={this.state.auctions}
          total={this.state.total}
        />
        <HomeCategories categories={categories} />
        <CategoryAuctions />
      </div>
    );
  }
}
