import React from "react";
import { SideBar } from "./sidebar";
import { getUsersByRoles, getWalletUsers } from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal, Spinner } from "react-bootstrap";
import { EditUser } from "./helper/edit_user";
import Select from "react-select";
import {
  createWallet,
  deleteWallet,
  getAllWallets,
  getWallet,
  updateWallet,
  updateWalletTransaction,
} from "../../models/wallet";
import { getLoggedUser } from "../../state/user";
import { apiUrl } from "../../constants/global";
import { HomeBanner } from "../home/homeBanner";
import { getAllOrganization } from "../../models/organization";
import { getAllGroups } from "../../models/groups";
import FileInput from "../helpers/fileInputNew";

const user = getLoggedUser();
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9 }),
};

const selectStylesPop = {
  control: (provided) => ({
    ...provided,
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
  singleValue: (provided) => ({
    ...provided,
    textAlign: "justify",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 99999 }),
};

const wallet_types = {
  admin: "Added by admin",
  sitevisit: "Site Visit",
  enroll: "Enroll",
  wallet_recharge: i18n.t("deposit"),
  withdraw: i18n.t("withdraw"),
  client_payment: i18n.t("client_payment"),
};
const wallet_statuses = {
  A: i18n.t("approved"),
  P: "Waiting for admin approval",
  F: i18n.t("waiting_for_finance_approval"),
  W: "Withdraw requested",
  WA: i18n.t("withdraw_request_approved_by_admin"),
  R: "Refund Approved",
  WR: "Withdraw Request Rejected",
};

export class UsersWallet extends React.Component {
  constructor() {
    super();
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());
    this.state = {
      selected_row: null,
      open_popup: false,
      update_popup: false,
      add: {},
      data: {},
      errors: {},
      is_loading: false,
      transactions: [],
      customers: [],
      all_customers: [],
      hideSidebar: false,
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
        customer: params && params.user ? params.user : "",
      },
      file_receipt_edit: null,
      file_fm_edit: null,
      clients: [],
      groups: [],
      credit_sum: 0,
      debit_sum: 0,
      balance_sum: 0,
      hold_sum: 0,
      columns: [
        {
          name: "ID",
          wrap: true,
          sortable: true,
          width: "80px",
          cell: (row) => {
            if (row.group_id) {
              return (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <b>{row.id}</b>

                  <small>
                    <a
                      href={`/enrolled_users?group=${row.group_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i18n.t("enroll")}
                    </a>
                  </small>
                </div>
              );
            }
            return (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <b>{row.id}</b>
                <small>
                  <a href={`/enrolled_users`} target="_blank" rel="noreferrer">
                    {i18n.t("enroll")}
                  </a>
                </small>
              </div>
            );
          },
        },
        {
          name: i18n.t("group"),
          sortable: true,
          wrap: true,
          cell: (row) => {
            if (row && row.group_info) {
              return row.group_info.group_name ? row.group_info.group_name : "";
            }
            return "";
          },
        },
        {
          name: i18n.t("mzadcom_id"),
          selector: "user",
          sortable: true,
          wrap: false,
        },
        {
          name: i18n.t("username"),
          selector: "user",
          sortable: true,
          wrap: true,
          cell: (row) =>
            `${row.user && row.user_info ? row.user_info.name : ""}`,
        },
        {
          name: i18n.t("transaction"),
          selector: "transaction_id",
          sortable: true,
        },
        {
          name: i18n.t("deposit"),
          selector: "credit",
        },
        {
          name: i18n.t("withdraw"),
          selector: "debit",
        },
        {
          name: i18n.t("hold"),
          selector: "hold_amount",
        },
        {
          name: i18n.t("balance"),
          selector: "balance",
        },
        {
          name: i18n.t("reference"),
          selector: "reference",
          wrap: true,
        },
        {
          name: i18n.t("type"),
          selector: "type",
          wrap: true,
          cell: (row) => `${row.type ? wallet_types[row.type] : ""}`,
        },
        {
          name: i18n.t("status"),
          selector: "status",
          wrap: true,
          cell: (row) => `${row.status ? wallet_statuses[row.status] : ""}`,
        },
        {
          name: i18n.t("admin_approved_on"),
          wrap: true,
          selector: "admin_approved_on",
        },
        {
          name: i18n.t("fm_approved_on"),
          wrap: true,
          selector: "fm_approved_on",
        },
        {
          name: i18n.t("date"),
          wrap: true,
          selector: "created_at",
        },
        {
          name: i18n.t("receipt"),
          selector: "file_receipt",
          cell: (row) => {
            if (row.file_receipt) {
              return (
                <a href={row.file_receipt} target="_new">
                  {i18n.t("receipt")}
                </a>
              );
            }
          },
        },
        {
          name: i18n.t("attachment"),
          selector: "file_fm",
          width: "150px",
          cell: (row) => {
            let ret = null;
            if (row.file_fm) {
              ret = (
                <a href={row.file_fm} target="_blank" rel="noopener noreferrer">
                  {i18n.t("receipt")}
                </a>
              );
            }
            let attachments = row.attatchments || [];
            const attachmentLinks = attachments.map((attachment, index) => (
              <>
                <a
                  key={index}
                  href={attachment.document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i18n.t("document")} - {index + 1}
                </a>
                <br />
              </>
            ));

            return (
              <div className="d-flex flex-wrap">
                {ret}
                <br />
                {attachmentLinks}
              </div>
            );
          },
        },
        {
          name: i18n.t("action"),
          wrap: true,
          width: "200px",
          cell: (row) => {
            if (user.role === "A") {
              let ret = [
                <button
                  key="edit"
                  className="btn btn-outline-warning btn-sm"
                  onClick={() => {
                    this.setState({
                      show_edit_popup: true,
                      selected_row: row,
                    });
                  }}
                >
                  {i18n.t("edit")}
                </button>,
              ];

              if (row.status === "P") {
                ret.push(
                  <button
                    className="btn btn-outline-info"
                    onClick={() => {
                      let sts = row.status === "P" ? "F" : "A";
                      this.updateWalletRow(sts, row);
                    }}
                  >
                    {i18n.t("approve")}
                  </button>
                );
              }
              return <>{ret}</>;
            }

            if (user.role === "F" && row.status === "F") {
              return (
                <button
                  className="btn btn-outline-info"
                  onClick={() => {
                    let sts = row.status === "P" ? "F" : "A";
                    this.updateWalletRow(sts, row);
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.triggerReload = this.triggerReload.bind(this);
    this.updateWalletRow = this.updateWalletRow.bind(this);
    this.updateTransactionRow = this.updateTransactionRow.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleChangeUpdate = this.handleChangeUpdate.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
    this.loadCustomers();
    this.loadAllCustomers();
    this.loadAllOrganisation();
    this.loadAllGroups();
  }

  handleChangeUpdate(event) {
    let { selected_row } = this.state;
    selected_row[event.target.name] = event.target.value;
    this.setState({ selected_row });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_receipt_edit") {
      this.setState({ file_receipt_edit: event.target.files[0] });
    }
    if (event.target.name === "file_fm_edit") {
      this.setState({ file_fm_edit: event.target.files[0] });
    }
  };

  async loadAllOrganisation() {
    const response = await getAllOrganization();
    let clients = response.data;
    this.setState({ clients });
  }

  async loadAllGroups() {
    let q = "";
    let { input } = this.state;
    if (input.client) {
      q += `organization=${input.client}`;
    }
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadCustomers() {
    this.setState({ is_loading: true });
    const response = await getWalletUsers();
    let data = response.data;
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });

      this.setState({ is_loading: false });
      this.setState({ customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async loadAllCustomers() {
    this.setState({ is_loading: true });
    let q = `roles=U&is_role_wise=${true}`;
    const response = await getUsersByRoles(q);
    let data = response.data["U"];
    if (response.success) {
      const customers = data.map((item) => {
        return {
          value: item.id,
          label: item.name + " (" + item.username + ")",
        };
      });
      this.setState({ is_loading: false });
      this.setState({ all_customers: customers });
    } else {
      this.setState({ is_loading: false });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "client") {
      this.loadAllGroups();
    }
  }
  handleAddChange(event) {
    let { add } = this.state;
    add[event.target.name] = event.target.value;
    this.setState({ add });
  }

  handleSearch(event) {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadAllTransactions(qstring);
  }

  async loadAllTransactions(q) {
    this.setState({ is_loading: true });
    const response = await getAllWallets(q);
    let transactions = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ transactions: transactions });
      this.setState({ totalRows: meta.total });
      this.setState({
        credit_sum: meta["credit_sum"],
        debit_sum: meta["debit_sum"],
        balance_sum: meta["balance_sum"],
        hold_sum: meta["hold_sum"],
      });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async viewRow(id) {
    const response = await getWallet(id);
    let data = response.data;
    this.setState({ data, update_popup: true });
  }

  async updateWalletRow(status, row) {
    let payload = { status: status, id: row.id, user: row.user };
    const response = await updateWallet(payload, row.id);
    if (response && response.success) {
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllTransactions(qstring);
  };

  async deleteRow(id) {
    var ans = window.confirm("Do you want to continue.?");
    if (ans) {
      const response = await deleteWallet(id);
      if (response.success) {
        swal(i18n.t("success"), "User deleted", "success", {
          buttons: false,
          timer: 2000,
        })
          .then((response) => {
            window.location.reload();
          })
          .catch((response) => {
            swal("Failed", "Something went wrong", "error");
          });
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createWallet(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "Fund added successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              this.resetForm();
              let { input } = this.state;
              let q = new URLSearchParams(input).toString();
              this.loadAllTransactions(q);
              this.setState({ open_popup: false });
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async updateTransactionRow() {
    let {
      id,
      user,
      bank,
      account_number,
      receipt_number,
      status,
      debit,
      credit,
      hold_amount,
      balance,
    } = this.state.selected_row;
    // if (!status) {
    //   swal(i18n.t("error"), i18n.t("all_mandatory"), "error");
    //   return;
    // }

    const formData = new FormData();
    formData.append("id", id);
    formData.append("user", user);
    formData.append("credit", credit);
    formData.append("debit", debit);
    formData.append("status", status);
    formData.append("bank", bank);
    formData.append("hold_amount", hold_amount);
    formData.append("balance", balance);
    formData.append("account_number", account_number);
    if (receipt_number && typeof receipt_number != "undefined") {
      formData.append("receipt_number", receipt_number);
    }

    if (this.state.file_receipt_edit) {
      formData.append(
        "file_receipt",
        this.state.file_receipt_edit,
        this.state.file_receipt_edit.name
      );
    }
    if (this.state.file_fm_edit) {
      formData.append(
        "file_fm",
        this.state.file_fm_edit,
        this.state.file_fm_edit.name
      );
    }
    const response = await updateWalletTransaction(formData, id);
    if (response && response.success) {
      swal(i18n.t("success"), i18n.t("approved"), "success");
      this.handleSearch();
    }
  }

  resetForm() {
    let { add } = this.state;
    add["name"] = "";
    add["mobile"] = "";
    add["email"] = "";
    add["username"] = "";
    add["country_code"] = "+968";
    add["password"] = "";
    add["role"] = "";
    this.setState({ add: add });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.add;
    let errors = {};
    let isValid = true;
    if (!input["user"]) {
      isValid = false;
      errors["user"] = "Please select a user.";
    }
    if (!input["credit"]) {
      isValid = false;
      errors["credit"] = "Please enter an amount.";
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  triggerReload(opt) {
    if (opt) {
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadAllTransactions(qstring);
    }
  }

  render() {
    let { transactions, customers, all_customers, input, clients, groups } =
      this.state;
    const queryString = Object.keys(input)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(input[key])}`
      )
      .join("&");
    const statusOptions = Object.entries(wallet_statuses);
    return (
      <React.Fragment>
        <HomeBanner />

        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header d-flex justify-content-between">
                    <div className="d-flex w-30 w-mob-100">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="icon-size-actual"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="icon-size-fullscreen"></i>
                        )}
                      </span>
                      <h3 className="card-title">
                        {i18n.t("mzadcom")} - {i18n.t("wallet")}
                      </h3>
                    </div>

                    <div className="p-0 m-0 d-flex justify-content-between align-items-center">
                      {!this.state.is_loading && transactions.length > 0 && (
                        <div className="d-flex justify-content-between align-items-center">
                          <a
                            href={
                              apiUrl + "user_wallet/export?q=" + queryString
                            }
                            target="_new"
                          >
                            <i className="fa fa-print fa-1x text-dark px-1 mx-1" />
                          </a>

                          <a
                            href={apiUrl + "user_wallet/excel?q=" + queryString}
                            target="_new"
                          >
                            <i className="fa fa-file-excel-o fa-1x text-dark px-1 mx-1" />
                          </a>
                        </div>
                      )}
                      {user.role === "A" && (
                        <button
                          className="btn btn-sm btn-outline-dark rounded-5 p-1 px-2"
                          onClick={(event) => {
                            this.setState({
                              open_popup: !this.state.open_popup,
                            });
                          }}
                        >
                          <i className="fa fa-plus-circle" />
                          &nbsp;
                          <span className="d-mob-none">
                            {i18n.t("add_fund")}
                          </span>
                        </button>
                      )}
                    </div>

                    <div className="d-flex w-33 w-mob-100 gap-1">
                      <div className="col-sm-6 col-md-6">
                        {/* <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Client"
                          name="client"
                          value={this.state.input.client}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_client")}</option>
                          {clients.length > 0 &&
                            clients.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.organization_name}
                                </option>
                              );
                            })}
                        </select> */}
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <select
                          className="form-select textbox fs15 text-gray filter-icon rounded-2"
                          data-placeholder="Select Group"
                          name="group"
                          value={this.state.input.group}
                          onChange={this.handleChange}
                        >
                          <option value="">{i18n.t("select_group")}</option>
                          {groups.length > 0 &&
                            groups.map((item) => {
                              return (
                                <option value={item.id} key={item.id}>
                                  {item.group_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.state.is_loading && (
                      <div className="text-center">
                        <Spinner animation="border" size="lg" />
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control textbox rounded-2"
                            placeholder={i18n.t("from_date")}
                            name="fdate"
                            value={this.state.input.fdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control textbox rounded-2"
                            placeholder={i18n.t("to_date")}
                            name="tdate"
                            value={this.state.input.tdate}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <select
                            className="form-control textbox rounded-2"
                            name="pay_type"
                            value={this.state.input.pay_type}
                            onChange={this.handleChange}
                          >
                            <option value="">{i18n.t("pay_type")}</option>
                            <option value="online">{i18n.t("online")}</option>
                            <option value="offline">{i18n.t("offline")}</option>
                            <option value="wallet">{i18n.t("wallet")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <select
                            className="form-control textbox rounded-2"
                            name="trans_type"
                            value={this.state.input.trans_type}
                            onChange={this.handleChange}
                          >
                            <option value="">
                              {i18n.t("transaction") + " " + i18n.t("type")}
                            </option>
                            <option value="deposit">{i18n.t("deposit")}</option>
                            <option value="withdraw">
                              {i18n.t("withdraw")}
                            </option>
                            <option value="hold">{i18n.t("hold")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <Select
                            options={customers}
                            onChange={(e) => {
                              let { input } = this.state;
                              input.customer = e.value;
                              this.setState({ input });
                            }}
                            name="customer"
                            styles={selectStyles}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            className="form-control textbox rounded-2 p-0"
                          ></Select>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="d-flex">
                          {(this.state.input.customer ||
                            this.state.input.fdate ||
                            this.state.input.tdate) && (
                            <button
                              className="btn btn-danger btn-sm rounded-0"
                              onClick={(e) => {
                                // let { input } = this.state;
                                // input.customer = "";
                                // input.fdate = "";
                                // input.tdate = "";
                                // this.setState({ input });
                                // this.handleSearch(e);
                                window.location.href = "/users-wallet";
                              }}
                            >
                              <i className="fa fa-times" />
                            </button>
                          )}
                          <button
                            className="btn btn-outline-dark rounded-5"
                            onClick={this.handleSearch}
                          >
                            <i className="fa fa-search fa-1x" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {!this.state.is_loading && transactions.length > 0 && (
                      <div className="">
                        <div class="payment-info-section">
                          <div
                            class="bg-lt-green payment-info-container"
                            onClick={() => {
                              let { input } = this.state;
                              input.trans_type = "deposit";
                              this.setState({ input });
                              this.handleSearch();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span class="payment-info-text">
                              {i18n.t("deposit")} : {this.state.credit_sum}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                          <div class="bg-lt-orange payment-info-container">
                            <span class="payment-info-text">
                              {i18n.t("client_due")} :{" "}
                              {this.state.client_due || 0} {i18n.t("omr")}
                            </span>
                          </div>
                          <div
                            class="bg-lt-red payment-info-container"
                            onClick={() => {
                              let { input } = this.state;
                              input.trans_type = "hold";
                              this.setState({ input });
                              this.handleSearch();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span class="payment-info-text">
                              {i18n.t("hold")} : {this.state.hold_sum || 0.0}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                          <div
                            class="bg-lt-blue payment-info-container"
                            onClick={() => {
                              let { input } = this.state;
                              input.trans_type = "withdraw";
                              this.setState({ input });
                              this.handleSearch();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span class="payment-info-text">
                              {i18n.t("withdraw")} : {this.state.debit_sum}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                          <div class="bg-lt-green payment-info-container">
                            <span class="payment-info-text">
                              {i18n.t("balance")} : {this.state.balance_sum}{" "}
                              {i18n.t("omr")}
                            </span>
                          </div>
                        </div>
                        <div className="table-responsive ">
                          <div className="table-wrapper">
                            <DataTable
                              title=""
                              fixedHeader
                              columns={this.state.columns}
                              data={transactions}
                              customStyles={customStyles}
                              highlightOnHover
                              pagination
                              paginationServer
                              paginationTotalRows={this.state.totalRows}
                              paginationPerPage={15}
                              paginationDefaultPage={this.state.currentPage}
                              paginationRowsPerPageOptions={[15, 25, 50, 100]}
                              paginationComponentOptions={{
                                rowsPerPageText: i18n.t(
                                  "transactions_per_page"
                                ),
                                rangeSeparatorText: i18n.t("out"),
                              }}
                              onChangePage={this.handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          size="md"
          show={this.state.open_popup}
          onHide={() => this.setState({ open_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("add_fund")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="single-page">
                <div className="col-md-12 d-block mx-auto">
                  <div className="wrapper">
                    <form
                      id="Register"
                      className="card-body"
                      tabindex="500"
                      autoComplete="off"
                    >
                      <div className="user">
                        <label>{i18n.t("user")}</label>
                        <Select
                          options={all_customers}
                          onChange={(e) => {
                            let { add } = this.state;
                            add.user = e.value;
                            this.setState({ add });
                          }}
                          name="user"
                          styles={selectStylesPop}
                          menuPlacement="auto"
                          menuPortalTarget={document.body}
                          className="form-control"
                        ></Select>
                        <div className="text-danger">
                          {this.state.errors.user}
                        </div>
                      </div>

                      <div className="credit">
                        <label>{i18n.t("amount")}</label>
                        <input
                          type="text"
                          name="credit"
                          value={this.state.add.credit}
                          onChange={this.handleAddChange}
                          autoComplete="credit"
                          className="form-control"
                        />

                        <div className="text-danger">
                          {this.state.errors.credit}
                        </div>
                      </div>

                      <div className="reference">
                        <label>{i18n.t("reference")}</label>
                        <input
                          type="text"
                          name="reference"
                          value={this.state.add.reference}
                          onChange={this.handleAddChange}
                          className="form-control"
                        />
                        <div className="text-danger">
                          {this.state.errors.reference}
                        </div>
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        onClick={this.handleSubmit}
                      >
                        {i18n.t("submit")}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.update_popup}
          onHide={() => this.setState({ update_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              View/Edit User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditUser
              data={this.state.data}
              triggerReload={this.triggerReload}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.show_edit_popup}
          onHide={() => {
            let { selected_row } = this.state;
            selected_row = "";
            this.setState({ show_edit_popup: false, selected_row });
          }}
          size="lg"
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("edit")} {i18n.t("transaction")} :{" "}
              {this.state.selected_row?.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <form
                  id="UserUpdate"
                  className="card-body pt-0"
                  tabindex="500"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("withdraw")} {i18n.t("amount")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="debit"
                            value={this.state.selected_row?.debit}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("deposit")} {i18n.t("amount")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="credit"
                            value={this.state.selected_row?.credit}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("hold")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={i18n.t("hold_amount")}
                            name="hold_amount"
                            value={this.state.selected_row?.hold_amount}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("balance")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={i18n.t("balance")}
                            name="balance"
                            value={this.state.selected_row?.balance}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("bank")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="bank"
                            value={this.state.selected_row?.bank}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("account_number")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="account_number"
                            value={this.state.selected_row?.account_number}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("receipt")}{" "}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder={i18n.t("please_enter_receipt_number")}
                            name="receipt_number"
                            value={this.state.selected_row?.receipt_number}
                            onChange={this.handleChangeUpdate}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("attatchment")}({i18n.t("receipt")})
                          </label>
                          <FileInput
                            wrapperClass=" pad-adjust"
                            placeholder={i18n.t("choose_file")}
                            id="file_receipt_edit"
                            name="file_receipt_edit"
                            onChange={this.onFileChange}
                            className="form-control"
                            format_url={this.state.selected_row?.file_receipt}
                            is_file_view={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("attatchment")}({i18n.t("finance")})
                          </label>
                          <FileInput
                            wrapperClass=" pad-adjust"
                            placeholder={i18n.t("choose_file")}
                            id="file_fm_edit"
                            name="file_fm_edit"
                            onChange={this.onFileChange}
                            className="form-control"
                            format_url={this.state.selected_row?.file_fm}
                            is_file_view={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group">
                          <label
                            for="form-label"
                            className="form-label"
                            style={{ top: "inherit" }}
                          >
                            {i18n.t("status")}
                          </label>
                          <select
                            className="form-select textbox fs15 text-gray filter-icon rounded-2"
                            data-placeholder="Select status"
                            name="status"
                            value={this.state.selected_row?.status}
                            onChange={this.handleChangeUpdate}
                          >
                            <option value="">{i18n.t("status")}</option>
                            {statusOptions.length > 0 &&
                              statusOptions.map(([key, value]) => (
                                <option value={key} key={key}>
                                  {value}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="form-group mb-0 mt-4 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-success btn-md"
                            onClick={() => {
                              this.updateTransactionRow(false);
                            }}
                          >
                            <i className="fa fa-save" />
                            &nbsp;
                            {i18n.t("submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
