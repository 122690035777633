import React from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import {
  paymentCancelledUrl,
  paymentSuccessUrl,
  thawaniConfig,
  thawaniPaymentUrl,
} from "../../../constants/global";
import i18n from "../../../i18n";
import {
  createThawaniCustomer,
  createThawaniSession,
} from "../../../models/thawani";
import { getLoggedUser } from "../../../state/user";
import {
  createGatePass,
  // offlineUpdate,
  // updateGatePass,
} from "../../../models/gatePass";
import { getUserInfo, updateUser } from "../../../models/users";
import FileInput from "../../helpers/fileInput";
import logoThawani from "../../../logoThawani.svg";
import { Spinner } from "react-bootstrap";

const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;

const success_msgs = {
  offline: i18n.t("message_sitevisit_succs"),
  wallet: i18n.t("message_sitevisit_succs_wallet"),
};

const statuses = {
  offline: "NP",
  online: "N",
  wallet: "N",
};

export class VisitNow extends React.Component {
  constructor() {
    super();
    this.state = {
      online_type: "local",
      is_hide_comp: false,
      thawani_data_id: user ? user.thawani_id : null,
      is_loading: false,
      is_payment_loading: false,
      input: {},
      show_payment_popup: false,
      ptype: "offline", //online
      existing: {},
      is_not_wallet_balance: false,
      file_receipt: null,
      is_checking_wallet: false,
    };
    this.createCustomer = this.createCustomer.bind(this);
    this.gotoPayment = this.gotoPayment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createSiteVisitInfo = this.createSiteVisitInfo.bind(this);
    //this.updateSiteVisitInfo = this.updateSiteVisitInfo.bind(this);
    this.checkUserWallet = this.checkUserWallet.bind(this);
  }
  componentDidMount() {
    let { input } = this.state;
    input.visit_time = null;
    input.amount = this.props.amount;
    input.auction = this.props.id ? this.props.id : "";
    input.group_id = this.props.gid;
    this.setState({ input });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async createSiteVisitInfo(e) {
    e.preventDefault();
    if (!this.state.ptype) {
      swal("failed", "Please select a payment method.", "error");
      return;
    }
    if (this.state.ptype === "offline") {
      if (!this.state.file_receipt) {
        swal("Failed", i18n.t("upload_receipt"), "error");
        return;
      }
    }
    this.setState({ is_loading: true });
    const formData = new FormData();
    formData.append("status", statuses[this.state.ptype]);
    formData.append("is_transaction", true);
    formData.append("payment_type", this.state.ptype);
    formData.append("amount", this.state.input.amount);
    formData.append("auction", this.state.input.auction);
    formData.append("group_id", this.state.input.group_id);
    if (this.state.file_receipt) {
      formData.append(
        "file_receipt",
        this.state.file_receipt,
        this.state.file_receipt.name
      );
    }

    const response = await createGatePass(formData);
    if (response && response.success) {
      let data = response.data;
      if (response.success) {
        this.setState({ existing: data });
        this.setState({ is_loading: false });
        if (this.state.ptype === "online") {
          this.createCustomer(e);
        }
        if (this.state.ptype === "offline" || this.state.ptype === "wallet") {
          this.setState({ show_payment_popup: false });
          this.setState({ is_hide_comp: true });
          swal(i18n.t("success"), success_msgs[this.state.ptype], "success");
        }
      } else {
        swal(
          "Failed",
          "Some errors occured. please contact administrator",
          "error"
        );
        this.setState({ is_loading: false });
        return;
      }
    } else {
      this.setState({ is_loading: false });
    }
  }

  onFileChange = (event) => {
    if (event.target.name === "file_receipt") {
      this.setState({ file_receipt: event.target.files[0] });
    }
  };

  // async updateSiteVisitInfo() {
  //   this.setState({ is_loading: true });
  //   let { existing, ptype } = this.state;
  //   existing.payment_type = ptype;
  //   existing.is_transaction = true;
  //   let response = await updateGatePass(existing, existing.id);
  //   if (response && response.success) {
  //     let data = response.data;
  //     this.setState({ existing: data });
  //     this.setState({ is_loading: false });
  //     this.checkUserWallet();
  //     this.setState({ is_not_wallet_balance: false });
  //     swal(i18n.t("success"), success_msgs[ptype], "success");
  //   } else {
  //     this.setState({ is_loading: false });
  //   }
  // }

  async createCustomer() {
    let { amount } = this.props;
    if (amount <= 0) {
      return;
    }
    this.setState({ is_loading: true });
    this.setState({ is_payment_loading: true });
    if (user && user.email) {
      if (!user.thawani_id) {
        let thw = await createThawaniCustomer({
          client_customer_id: user.email,
        });
        if (thw && thw.data) {
          localStorage.setItem("thawani_customer_id", thw.data.id);
          this.setState({ thawani_data_id: thw.data.id });
          let payload = user;
          payload.thawani_id = thw.data.id;
          payload.flag_thawani_update = true;
          let usr = await updateUser(payload, user.id);
          localStorage.setItem("mzad_user_info", JSON.stringify(usr.data));
          this.gotoPayment();
        }
        this.setState({ is_payment_loading: false });
        this.setState({ is_loading: false });
      } else {
        this.setState({
          thawani_data_id: user.thawani_id,
          is_payment_loading: false,
        });
        this.setState({ is_loading: false });
        this.gotoPayment();
      }
    }
  }

  async gotoPayment() {
    let { id, gid, type, is_group, amount } = this.props;
    let { online_type, ptype } = this.state;
    let { existing, thawani_data_id } = this.state;
    let customer_id = thawani_data_id;
    if (ptype === "online" && online_type === "local") {
      let debit = (parseFloat(amount) * 1.5) / 100;
      amount = parseFloat(amount) + parseFloat(debit);
    }
    if (ptype === "online" && online_type === "credit") {
      let credit = (parseFloat(amount) * 2) / 100;
      amount = parseFloat(amount) + parseFloat(credit);
    }
    // console.log(ptype, online_type);
    // console.log("amount : " + amount);
    // return;
    let queryParams =
      "?type=" +
      type +
      "&is_group=" +
      is_group +
      "&auc=" +
      (id ? id : "") +
      "&gid=" +
      gid +
      "&amt=" +
      amount +
      "&gate_pass=" +
      existing.id;

    let payload = {
      client_reference_id: user.email,
      mode: "payment",
      products: [
        {
          name: "Mzadcom Auction : Visit Amount",
          quantity: 1,
          unit_amount: parseInt(amount) * 1000,
        },
      ],
      success_url: paymentSuccessUrl + queryParams,
      cancel_url: paymentCancelledUrl,
      customer_id: customer_id,
      metadata: {
        "Customer name": user.name,
        "Order id": user.id + "",
        user: user.id,
        group: gid,
      },
    };

    let thw = await createThawaniSession(payload);
    if (thw && thw.data && thw.success && thw.data.session_id) {
      let session_id = thw.data.session_id;
      localStorage.setItem("invoice", thw.data.invoice);
      window.location.href =
        thawaniPaymentUrl +
        session_id +
        "?key=" +
        thawaniConfig.publishable_key;
    } else {
      this.setState({ is_payment_loading: false });
    }
  }

  async checkUserWallet() {
    this.setState({ is_checking_wallet: true });
    const response = await getUserInfo(user.id);
    if (response && response.success) {
      this.setState({ is_checking_wallet: false });
      let usr = response.data;
      if (usr) {
        user.wallet_amount = usr.wallet_amount;
        localStorage.setItem("mzad_user_info", JSON.stringify(user));
        if (Number(usr.wallet_amount) < Number(this.props.amount)) {
          this.setState({ is_not_wallet_balance: true });
        }
      }
    } else {
      this.setState({ is_checking_wallet: false });
    }
  }

  render() {
    let { is_loading, is_not_wallet_balance, is_hide_comp } = this.state;
    let main_class = "p-0 px-2 border border-danger rounded-5 mx-1 text-danger";
    let cls_p_btn = is_loading
      ? "p-0 px-2 border border-warning rounded-5 mx-1 text-warning fs-5rem"
      : "p-0 px-2 border border-danger rounded-5 mx-1 text-danger fs-5rem";

    if (this.props.main_class) {
      main_class = this.props.main_class;
      cls_p_btn = this.props.main_class;
    }
    if (is_hide_comp) {
      cls_p_btn = "d-none";
    }

    let online_buttons =
      "btn btn-outline-success d-flex w-100 m-2 rounded-0 align-items-center justify-content-center m-auto";
    let online_buttons_active =
      "btn btn-success d-flex w-100 m-2 rounded-0 align-items-center justify-content-center m-auto";

    return (
      <React.Fragment>
        {is_logged && (
          <button
            className={cls_p_btn}
            onClick={(e) => {
              if (this.props.amount <= 0) {
                swal(
                  "Failed",
                  "Visit amount is not set for this auction. Please contact administrator.",
                  "warning"
                );
                return;
              }
              this.setState({ show_payment_popup: true });
            }}
          >
            {!is_loading ? i18n.t("pay_now") : i18n.t("loading")}
          </button>
        )}

        {!is_logged && (
          <a className={main_class} href="/login">
            {i18n.t("pay_now")}
          </a>
        )}

        <Modal
          size="md"
          show={this.state.show_payment_popup}
          onHide={() => this.setState({ show_payment_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {i18n.t("site_visit")} - {i18n.t("payment")}
            </Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <div className="row p-3">
                <div className="card p-0">
                  <div className="card-header rounded-4">
                    {i18n.t("amount")} : {i18n.t("omr")} {this.props.amount}
                  </div>
                  <div className="card-body p-1 m-0">
                    <div className="d-flex align-items-center justify-content-between bg-light border rounded-5">
                      <button
                        className={
                          this.state.ptype === "offline"
                            ? "btn btn-info rounded-5"
                            : "btn btn-outline-info rounded-5 border-0"
                        }
                        onClick={(e) => {
                          this.setState({ ptype: "offline" });
                          this.setState({ is_not_wallet_balance: false });
                          e.preventDefault();
                        }}
                        style={{ width: "33.33%" }}
                      >
                        <i class="fa fa-bank" />
                        &nbsp;
                        {i18n.t("offline")}
                      </button>
                      <button
                        className={
                          this.state.ptype === "online"
                            ? "btn btn-info rounded-5"
                            : "btn btn-outline-info rounded-5 border-0"
                        }
                        onClick={(e) => {
                          this.setState({ ptype: "online" });
                          this.setState({ is_not_wallet_balance: false });
                          e.preventDefault();
                        }}
                        style={{ width: "33.33%" }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/new-phone.png"
                          }
                          alt="wallet"
                          width="15"
                        />
                        &nbsp;
                        {i18n.t("online")}
                      </button>
                      <button
                        className={
                          this.state.ptype === "wallet"
                            ? "btn btn-info rounded-5"
                            : "btn btn-outline-info rounded-5 border-0"
                        }
                        onClick={(e) => {
                          this.setState({ is_checking_wallet: true });
                          this.checkUserWallet();
                          this.setState({ ptype: "wallet" });
                          e.preventDefault();
                        }}
                        style={{ width: "33.33%" }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/wallet-icon.png"
                          }
                          alt="wallet"
                        />
                        &nbsp;
                        {i18n.t("wallet")}
                      </button>
                    </div>

                    {this.state.ptype === "offline" && (
                      <div>
                        <div className="col-md-12 col-md-6 col-md-3">
                          <label
                            className="custom-text3 mb-2"
                            for="GuaranteeamountDepositandRefundPolicy"
                          >
                            <img
                              width="23px"
                              height="23px"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/image 25.png"
                              }
                              alt=""
                            />
                            {i18n.t("guarantee_amount_policy_head")}
                          </label>
                          <div
                            className="form-control overflow-auto custom-textarea border-25"
                            style={{ height: "170px" }}
                          >
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_1")}
                            </p>
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_2")}
                            </p>
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_3")}
                            </p>
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_4")}
                            </p>
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_5")}
                            </p>
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_6")}
                            </p>
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_7")}
                            </p>
                            <p className="p-0 m-0">
                              {i18n.t("guarantee_amount_policy_8")}
                            </p>
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <div className="form-group">
                            <label
                              for="form-label"
                              className="form-label"
                              style={{ textAlign: "jusify" }}
                            >
                              {i18n.t("receipt")}{" "}
                              <span className="text text-danger">(*)</span>
                            </label>
                            <FileInput
                              wrapperClass=""
                              placeholder={i18n.t("choose_file")}
                              name="file_receipt"
                              id="file_receipt"
                              onChange={this.onFileChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {this.state.ptype === "online" && (
                    <>
                      <div className="col-md-12 col-md-6 col-md-3 mt-3">
                        <label
                          className="custom-text3 mb-2"
                          for="GuaranteeamountDepositandRefundPolicy"
                        >
                          <img
                            width="23px"
                            height="23px"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/image 25.png"
                            }
                            alt=""
                          />
                          {i18n.t("online_deposit_and_refund_policy")}
                        </label>
                        <div
                          className="form-control overflow-auto custom-textarea border-25"
                          style={{ height: "170px" }}
                        >
                          <p className="p-0 m-0">
                            1) Those willing to participate in the e-auction
                            must deposit an Guarantee amount.{" "}
                          </p>
                          <p className="p-0 m-0">
                            2) The Guarantee amount is refundable. However, the
                            Service Charges for the Online Payment is not
                            refundable, which is 1.5% for local cards and 2% for
                            debit cards.{" "}
                          </p>
                          <p className="p-0 m-0">
                            3) The guarantee amount is separate from the value
                            of the items offered in the auction.{" "}
                          </p>
                          <p className="p-0 m-0">
                            4) The Guarantee amount may vary from an item (a
                            commodity) to another, and it may be a fixed amount
                            or a percentage of the present value of the offered
                            commodity(item).
                          </p>
                          <p className="p-0 m-0">
                            5) The Guarantee amount is not automatically
                            returned. In case of delay, Mzadcom will not bear
                            any delay in refund.
                          </p>
                          <p className="p-0 m-0">
                            6) The Guarantee amount will return to the Bidders
                            wallet and Bidders can request for refund.
                          </p>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="text-center mb-3">
                          <div className="m-3">
                            <img src={logoThawani} alt="" height={30} />
                          </div>
                          <div className="m-3">
                            {i18n.t("redirect_to_thawani")}
                          </div>
                        </div>

                        <div className="d-flex w-100 px-0">
                          <div className="w-50 p-2 px-1">
                            <div
                              className={
                                this.state.online_type === "local"
                                  ? online_buttons_active
                                  : online_buttons
                              }
                              style={{ height: "65px", fontSize: "0.8rem" }}
                              onClick={() => {
                                this.setState({
                                  online_type: "local",
                                });
                              }}
                            >
                              {i18n.t("local_cards_ecommended")}
                            </div>
                          </div>
                          <div className="w-50 p-2 px-1">
                            <div
                              className={
                                this.state.online_type === "credit"
                                  ? online_buttons_active
                                  : online_buttons
                              }
                              style={{ height: "65px", fontSize: "0.8rem" }}
                              onClick={() => {
                                this.setState({
                                  online_type: "credit",
                                });
                              }}
                            >
                              {i18n.t("credit_cards_coast_2")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.is_checking_wallet && (
                    <div className="text-center p-2">
                      <Spinner animation="grow" className="m-auto" />
                    </div>
                  )}

                  {this.state.ptype === "wallet" &&
                    !this.state.is_checking_wallet &&
                    is_not_wallet_balance && (
                      <>
                        <div className="card-footer text-center">
                          <div className="alert alert-danger">
                            {i18n.t("insufficient_wallet_balance")}
                          </div>
                          <a
                            href="/wallet-manage"
                            className="btn btn-outline-info"
                          >
                            {i18n.t("add_fund")}
                          </a>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <button
                className="btn btn-primary  px-5"
                disabled={is_not_wallet_balance}
                onClick={(e) => {
                  this.createSiteVisitInfo(e);
                }}
              >
                {this.state.is_loading && (
                  <Spinner size="sm" animation="grow" />
                )}
                {!this.state.is_loading ? i18n.t("pay_now") : ""}
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </React.Fragment>
    );
  }
}
