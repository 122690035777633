import React from "react";
import i18n from "../../../i18n";
import { Link } from "react-router-dom";

let lng = localStorage.getItem("I18N_LANGUAGE");
export class HomeCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let { categories } = this.props;
    return (
      <React.Fragment>
        <section className="mt-1 mb-4 overflow-hidden">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="d-flex auction-tabs justify-content-center justify-content-mob-start gap-4 overflow-scroll pt-3">
                    {categories.map((item, i) => {
                      return (
                        <div className="category-icon card bg-primary me-1 text-center px-5 py-2 d-flex flex-column justify-content-evenly align-items-center">
                          <div className="mb-2">
                            <Link
                              to={`/auctions?sec=main&category=${item.id}`}
                              className=""
                            >
                              {lng === "ar"
                                ? item?.category_name_ar
                                : item?.category_name}
                            </Link>
                          </div>
                          <div>
                            <Link
                              to={`/auctions?sec=main&category=${item.id}`}
                              className=""
                            >
                              <img
                                src={item?.file_category_image}
                                alt="category"
                                width={75}
                              />
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                    <div className="card d-flex flex-row gap-2 justify-content-center align-items-center py-2">
                      <div
                        className="text-primary px-3"
                        style={{ fontSize: "1.5rem" }}
                      >
                        <Link
                          to="/category-list"
                          className="text-primary  d-mob-none"
                        >
                          {i18n.t("view_all")}
                        </Link>
                      </div>
                      <div>
                        <Link to="/category-list" className="text-primary">
                          <i
                            className={
                              lng === "ar"
                                ? "fa fa-chevron-left"
                                : "fa fa-chevron-right"
                            }
                            style={{ fontSize: "4rem" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
