import React from "react";
import i18n from "../../i18n";
import { Link, NavLink } from "react-router-dom";
import { getLoggedUser } from "../../state/user";
import { SidebarHomeMob } from "./sidebar-home-mob";

const user = getLoggedUser();
const roles = {
  A: i18n.t("admin"),
  C: i18n.t("client"),
  CE: i18n.t("ceo"),
  GM: i18n.t("gm"),
  F: i18n.t("finance"),
  HSE: i18n.t("hse"),
  M: i18n.t("manager"),
  PM: "PM",
  OM: i18n.t("OM"),
  U: i18n.t("bidder"),
};

const changeLanguage = (ln) => {
  i18n.changeLanguage(ln);
  window.location.reload();
};

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("mzad_user_info");
  window.location.href = "/";
}

export class Header extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let { wallet_amount, is_loading, is_logged_in } = this.props;

    return (
      <React.Fragment>
        <div className="header-main">
          <div className="logo-div">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo/logo-l.png"}
                alt="rop-logo"
                width="200"
              />
            </Link>
          </div>
          <div className="logo-div">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo/logo-r.png"}
                alt="rop-logo"
                width="250"
              />
            </Link>
          </div>
        </div>
        <div className="header-nav-menu  d-mob-none">
          <ul className="nav-list p-0 m-0">
            <li>
              <Link to="/">
                <span
                  className="mdi mdi-home-outline"
                  style={{ fontSize: "2rem" }}
                ></span>
                &nbsp;
              </Link>
            </li>
            <li>
              {is_logged_in && (
                <Link to="/dashboard">
                  <i className="fa fa-dashboard"></i>&nbsp;
                  <span>{i18n.t("dashboard")}</span>
                </Link>
              )}
              {!is_logged_in && (
                <Link to="/login">
                  <span className="mdi mdi-account-circle"></span>&nbsp;
                  <span>{i18n.t("login")}</span>
                </Link>
              )}
            </li>
            <li>
              <Link to="/">{i18n.t("home")}</Link>
            </li>
            <li>
              <Link to="/auctions">{i18n.t("auctions")}</Link>
            </li>
            <li>
              <Link to="/contact">{i18n.t("contactus")}</Link>
            </li>
          </ul>
          <div className="d-flex gap-2">
            {current_lng === "ar" && (
              <a
                href={true}
                onClick={(e) => changeLanguage("en")}
                className="custom-btn-1 px-4"
                style={{ cursor: "pointer" }}
              >
                {/* <img
                  src={process.env.PUBLIC_URL + "/assets/images/flags/en.svg"}
                  height="20"
                  alt=""
                />{" "} */}
                ENGLISH
              </a>
            )}
            {current_lng === "en" && (
              <a
                href={true}
                onClick={(e) => changeLanguage("ar")}
                className="custom-btn-1 px-4"
                style={{ cursor: "pointer" }}
              >
                {/* <img
                  src={process.env.PUBLIC_URL + "/assets/images/flags/ar.svg"}
                  height="20"
                  alt=""
                />{" "} */}
                عربي{" "}
              </a>
            )}

            {is_logged_in && (
              <NavLink
                exact="true"
                to={true}
                onClick={logout}
                className="login-btn"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/offcanvas-logout.png"
                  }
                  alt="logout"
                  width="15"
                  style={{ marginRight: "10px" }}
                />
                <span>{i18n.t("logout")}</span>
              </NavLink>
            )}
          </div>
        </div>
        <div className="header-tab-mobile">
          <div className="logo-noti-menu">
            <Link to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/logo/logo-l.png"}
                alt=""
                width="130"
              />
            </Link>
            <div className="noti-menu">
              <div className="dropdown">
                <button className="dropbtn">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/burger-menu-black.png"
                    }
                    alt="burger-menu"
                  />
                </button>
                <div className="dropdown-content">
                  <div className="dropdown-items">
                    <div className="header-search">
                      <input type="text" placeholder={i18n.t("search")} />
                      <button className="header-search-btn">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/search-trans-icon.png"
                          }
                          alt="search"
                          width="30"
                        />
                      </button>
                    </div>
                    <div className="mobile-welcome-guest-role">
                      <button className="nav-btn welcome-staff">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/welcome-staff-icon.png"
                          }
                          alt="staff"
                        />
                        <span>
                          {i18n.t("welcome")}{" "}
                          {user && user.name ? user.name : i18n.t("guest")}
                        </span>
                      </button>
                      <button className="nav-btn">
                        {i18n.t("role")}:{" "}
                        <span>
                          {user && user.role ? roles[user.role] : "Guest"}
                        </span>
                      </button>
                    </div>
                    <div className="header-nav-menu">
                      <ul className="nav-list">
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-home-icon.png"
                            }
                            alt="home"
                          />
                          <a href={false}>{i18n.t("home")}</a>
                        </li>
                        {is_logged_in && (
                          <li>
                            <Link to="/dashboard" className="text-danger">
                              <span>{i18n.t("dashboard")}</span>
                            </Link>
                          </li>
                        )}
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-who-we-are-icon.png"
                            }
                            alt="who-we-are"
                          />
                          <a href={false}>{i18n.t("whoweare")}</a>
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-auction-icon.png"
                            }
                            alt="auction-calendar"
                          />
                          <a href={false}>{i18n.t("auction_calendar")}</a>
                        </li>
                        <li>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/menu-help-icon.png"
                            }
                            alt="help"
                          />
                          <a href={false}>{i18n.t("contactus")}</a>
                        </li>
                      </ul>
                    </div>

                    {!is_logged_in && (
                      <div className="mobile-login-reg">
                        <NavLink
                          exact="true"
                          to="/login"
                          className="login-btn"
                          style={{ borderRadius: "0" }}
                          onClick={(event) => {
                            this.hideSideBar();
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/login-icon.png"
                            }
                            alt="login"
                          />
                          <span>{i18n.t("login")}</span>
                        </NavLink>

                        <NavLink
                          className="reg-btn"
                          exact="true"
                          to="/comp-registration"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/register-icon.png"
                            }
                            alt="register"
                          />
                          <span>{i18n.t("registration")}</span>
                        </NavLink>
                      </div>
                    )}

                    {is_logged_in && (
                      <div className="mobile-wallet">
                        <Link to="/wallet" className="nav-btn wallet-btn">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/wallet-icon.png"
                            }
                            alt="wallet"
                          />
                          <span>
                            {is_loading && <span>..</span>}
                            {!is_loading && (
                              <span>
                                {i18n.t("wallet")} : {i18n.t("omr")}{" "}
                                {wallet_amount}
                              </span>
                            )}
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <SidebarHomeMob {...this.props} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
