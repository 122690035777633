import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SocialSignin } from "./socialSignin";
import swal from "sweetalert";
import { loginUser } from "../../models/login";
import i18n from "../../i18n";
import { baseUrl } from "../../constants/global";
import { HomeBanner } from "../home/homeBanner";

export default function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [passwordType, setPasswordType] = useState("password");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password,
    });

    if ("token" in response.data) {
      swal(i18n.t("success"), i18n.t("login_message"), "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <React.Fragment>
      <HomeBanner />
      <div className="page-container">
        <div className="content-bg">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="mx-auto">
                <div className="col-lg-12 col-lg-6 col-lg-3 d-flex justify-content-center">
                  <div className="card w-65">
                    <div
                      className="card-header bg-primary"
                      style={{ zIndex: "999" }}
                    >
                      <h4>
                        <i
                          className="mdi mdi-account-circle"
                          style={{ fontSize: "1.5rem" }}
                        />
                        &nbsp;{i18n.t("login")}
                      </h4>
                    </div>
                    <div
                      className="card-body"
                      style={{ zIndex: "99", marginTop: "-35px" }}
                    >
                      <div className="d-flex gap-5">
                        <div className="col ps-4 pt-4 pb-4 px-4 d-flex justify-content-center">
                          <form id="login" className="" onSubmit={handleSubmit}>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div>
                                <label for="username">
                                  {i18n.t("username_or_email")}
                                </label>
                              </div>
                              <div className="mt-1">
                                <input
                                  className="form-control custom-input "
                                  type="text"
                                  id="username"
                                  name="username"
                                  placeholder={i18n.t("username_or_email")}
                                  onChange={(e) => setUserName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div className="mt-1">
                                <label for="password">
                                  {i18n.t("password")}
                                </label>
                              </div>
                              <div className="mt-1 wrapper">
                                <input
                                  className="form-control custom-input "
                                  type={passwordType}
                                  id="password"
                                  name="password"
                                  placeholder={i18n.t("please_enter_password")}
                                  onChange={(e) => setPassword(e.target.value)}
                                />

                                {passwordType === "password" ? (
                                  <i
                                    className="fa fa-eye-slash password-eye"
                                    onClick={togglePassword}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-solid fa-eye password-eye"
                                    onClick={togglePassword}
                                  ></i>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div className="d-flex flex-row-reverse mt-1">
                                <Link
                                  to="/forgotPassword"
                                  className="custom_forgetpassword"
                                >
                                  {i18n.t("forget_password")}
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div className="text-center mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-secondary px-5"
                                >
                                  <i className="mdi mdi-account" />{" "}
                                  <span className="">{i18n.t("login")}</span>
                                </button>
                              </div>
                            </div>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div className="text-center d-flex justify-content-between align-items-center mt-2">
                                <span className="me-5"></span>
                                <p className="text-center ms-4">
                                  <Link
                                    to="/login_otp"
                                    className="custom-text2"
                                  >
                                    {i18n.t("login_using_otp")}
                                  </Link>
                                </p>
                                <p className="">
                                  <a
                                    className="custom-text3 d-flex"
                                    target="_new"
                                    href={baseUrl + "/guide/login.pdf"}
                                  >
                                    <img
                                      height="15.157"
                                      width="15.157"
                                      src="./assets/images/uiw_file-pdf.png"
                                      alt=""
                                    />
                                    {i18n.t("user_guide")}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div className="text-center">
                                <p>
                                  <a href={true} className="custom-text4">
                                    {i18n.t("donthaveaccount")}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div className="text-center custom-outline-button">
                                <Link
                                  to="/comp-registration"
                                  className="btn bg-none border border-primary"
                                >
                                  {i18n.t("signup")}
                                </Link>
                              </div>
                            </div>
                            {/* <div className="col-md-12 col-md-6 col-md-3">
                              <div className="horizontal-line">
                                <span className="line-text">
                                  {i18n.t("or_continue")}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12 col-md-6 col-md-3">
                              <div className="text-center">
                                <SocialSignin />
                              </div>
                            </div> */}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
