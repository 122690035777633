const prepends = {
  A: "ADMIN",
  U: "B",
  C: "C",
  M: "E",
  PM: "E",
  HSE: "E",
  F: "E",
  CE: "CEO",
  GM: "GM",
  CM: "CM",
  UN: "MZAD",
};
export function formatCurrency(amount, lang = "en") {
  let locale = lang === "ar" ? "ar-OM" : "en-US";
  return Number(amount) > 0
    ? Number(amount).toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      })
    : "0.000";
}

export function generateMzadcomID(number, role = "UN") {
  const num = Math.abs(Math.floor(number));
  const numString = num.toString();
  const zerosToAdd = Math.max(0, 4 - numString.length);
  const prefixedNumber = "0".repeat(zerosToAdd) + numString;
  return `${prepends[role]}${prefixedNumber}`;
}
