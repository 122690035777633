import React from "react";
import { SideBar } from "./sidebar";
import i18n from "../../i18n";
import Chart from "react-apexcharts";
import { getAuctionChartData, getSalesChartData } from "../../models/auctions";
import { getUserChartData } from "../../models/users";
import { getDashboardData } from "../../models/dashboard";
import { Spinner } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";
import { HomeBanner } from "../home/homeBanner";

const user = getLoggedUser();

export class DashboardUserAnalysis extends React.Component {
  constructor() {
    super();
    this.state = {
      first_panel: false,
      second_panel: true,
      third_panel: false,
      forth_panel: false,
      input: {},
      is_loading_1: false,
      is_loading_2: false,
      is_loading_3: false,
      is_loading_4: false,
      main_auctions: [],
      charity_auctions: [],
      auctions: {
        series: [0, 0, 0, 0, 0],
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
            },
          },
          labels: [
            i18n.t("active"),
            i18n.t("upcoming"),
            i18n.t("previous"),
            i18n.t("featured"),
            i18n.t("direct"),
          ],
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
      charity: {
        series: [0, 0, 0, 0, 0],
        options: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "100%",
              },
            },
          },
          labels: ["Active", "Upcoming", "Previous", "Featured", "Direct"],
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
      userCounts: {},
      userData: "monthly",
      daily: {},
      monthly: {},
      salesData: "monthly",
      yearlySales: {},
      monthlySales: {},
      active_auctions: 0,
      active_bidders: 0,
      total_bidders: 0,
      total_bids: 0,
      maximum_bid_amount: 0,
      total_amount: 0,
      auction_in_bidding: 0,
      dd_loading: false,
      group: false,
      is_side_bar: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.loadDashboardData = this.loadDashboardData.bind(this);
    this.hideSideBar = this.hideSideBar.bind(this);
  }

  componentDidMount() {
    this.loadAuctionsData();
    this.loadUserChartData();
    this.loadSalesChartData();
    if (user.role === "A" || user.role === "M") {
      setInterval(this.loadDashboardData, 10000);
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async loadDashboardData(data = false) {
    this.setState({ dd_loading: true });
    let { group, auction } = data;
    let input = this.state.input;
    input.group = typeof group !== "undefined" ? group : input.group;
    input.auction = typeof auction !== "undefined" ? auction : input.auction;
    if (typeof input.group === "undefined") {
      this.setState({ dd_loading: false });
      return;
    }
    let response = await getDashboardData(input);
    if (response.success) {
      let { data } = response;
      this.setState({
        active_auctions: data.active_auctions,
        active_bidders: data.active_bidders,
        total_bidders: data.total_bidders,
        maximum_bid_amount: data.maximum_bid_amount,
        total_amount: data.total_amount,
        auction_in_bidding: data.auction_in_bidding,
        total_bids: data.total_bids,
      });
      this.setState({ dd_loading: false });
    }
  }

  async loadAuctionsData() {
    this.setState({ is_loading_1: true });
    let auctions = this.state.auctions;
    const response = await getAuctionChartData("main");
    let data = response.data;
    if (response) {
      auctions.series = [
        data.active,
        data.upcoming,
        data.previous,
        data.featured,
        data.direct,
      ];
      this.setState({
        is_loading_1: false,
        auctions,
        main_auctions: auctions.series,
      });
    }
  }

  async loadUserChartData() {
    this.setState({ is_loading_3: true });
    let daily = this.state.daily;
    let monthly = this.state.monthly;
    const response = await getUserChartData();
    let data = response.data;
    if (response) {
      this.setState({ is_loading_3: false, userCounts: response.meta });
      daily.options = {
        chart: { id: "chart-userdata" },
        xaxis: {
          categories: data.dates,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      daily.series = [
        {
          name: "Total Users",
          data: data.values,
        },
      ];

      monthly.options = {
        chart: { id: "chart-userdata" },
        xaxis: {
          categories: data.months,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      monthly.series = [
        {
          name: "Total Users",
          data: data.month_values,
        },
      ];

      this.setState({ daily, monthly });
    }
    //console.log(this.state.users);
  }

  async loadSalesChartData() {
    this.setState({ is_loading_4: true });
    let yearly = this.state.yearlySales;
    let monthly = this.state.monthlySales;
    const response = await getSalesChartData();
    let data = response.data;
    if (response) {
      this.setState({ is_loading_4: false });
      yearly.options = {
        chart: { id: "chart-salesdata" },
        xaxis: {
          categories: data.years,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      yearly.series = [
        {
          name: "Total Sales",
          data: data.year_values,
        },
      ];

      monthly.options = {
        chart: { id: "chart-salesdata" },
        xaxis: {
          categories: data.months,
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      };
      monthly.series = [
        {
          name: "Total Sales",
          data: data.month_values,
        },
      ];

      this.setState({ yearlySales: yearly, monthlySales: monthly });
    }
    //console.log(this.state.users);
  }

  hideSideBar(is_side_bar) {
    this.setState({
      is_side_bar: is_side_bar,
    });
  }

  render() {
    let userDailyOptions = this.state.daily.options;
    let userDailySeries = this.state.daily.series;
    let userMonthlyOptions = this.state.monthly.options;
    let userMonthlySeries = this.state.monthly.series;
    let { userCounts } = this.state;
    return (
      <React.Fragment>
        <HomeBanner callbackForSupport={this.props.callbackForSupport} />
        <section className="sptb mt-3 mx-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.is_side_bar && <SideBar />}
              </div>
              <div
                className={
                  this.state.is_side_bar
                    ? "col-xl-12 col-lg-12 col-md-12 mt-1 dash-container"
                    : "col-xl-9 col-lg-12 col-md-12 mt-1 dash-container"
                }
              >
                <div className="d-flex justify-content-between mt-3 dash-container-head">
                  <h3 className="font-weight-bold">
                    {i18n.t("user_analysis")}
                  </h3>
                </div>

                <div className="bg-white rounded-4 hide_div mb-3">
                  <div className="p-3">
                    <div className="p-2 user-brd rounded-4">
                      <div className="grid_container ">
                        <div className="outer_round1 flex_container d-flex justify-content-between">
                          <div className="d-flex">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/dashboard-sub1.png"
                              }
                              alt=""
                              height="25"
                              width="25"
                              className="mx-3"
                            />
                            <div className=" outer_text lh20">
                              <span>{i18n.t("bidders")}</span>
                            </div>
                          </div>
                          <div className="inner_round">
                            <span>
                              {userCounts && userCounts.bidders
                                ? userCounts.bidders
                                : 0}
                            </span>
                          </div>
                        </div>
                        <div className="outer_round2 flex_container d-flex justify-content-between">
                          <div className="d-flex">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/future-auctions-icon.png"
                              }
                              alt=""
                              height="25"
                              width="25"
                              className="mx-3"
                            />
                            <div className=" outer_text lh20">
                              <span>{i18n.t("clients")}</span>
                            </div>
                          </div>
                          <div className="inner_round">
                            <span>
                              {userCounts && userCounts.clients
                                ? userCounts.clients
                                : 0}
                            </span>
                          </div>
                        </div>
                        <div className="outer_round3 flex_container d-flex justify-content-between">
                          <div className="d-flex">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/mdi_page-previous-outline.png"
                              }
                              alt=""
                              height="25"
                              width="25"
                              className="mx-3"
                            />
                            <div className=" outer_text lh20">
                              <span>{i18n.t("employees")}</span>
                            </div>
                          </div>
                          <div className="inner_round">
                            <span>
                              {userCounts && userCounts.employees
                                ? userCounts.employees
                                : 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div
                        className="card-header d-flex justify-content-between"
                        onClick={() => {
                          this.setState({
                            forth_panel: !this.state.forth_panel,
                          });
                        }}
                        role="button"
                      >
                        <h3 className="card-title">
                          {this.state.userData === "daily" && (
                            <span>{i18n.t("user_reg_last_30_days")}</span>
                          )}
                          {this.state.userData === "monthly" && (
                            <span>{i18n.t("user_reg_last_12_months")}</span>
                          )}
                        </h3>
                      </div>

                      <div className="card-body text-center">
                        <div className="btn-group" role="group">
                          <button
                            type="button"
                            className={
                              this.state.userData === "monthly"
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            onClick={() => {
                              this.setState({
                                userData: "monthly",
                              });
                            }}
                          >
                            {i18n.t("monthly")}
                          </button>
                          <button
                            type="button"
                            className={
                              this.state.userData === "daily"
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            onClick={() => {
                              this.setState({
                                userData: "daily",
                              });
                            }}
                          >
                            {i18n.t("daily")}
                          </button>
                        </div>

                        {this.state.is_loading_3 && (
                          <div className="m-5">
                            <Spinner animation="border" size="lg" />
                          </div>
                        )}

                        {!this.state.is_loading_3 &&
                          this.state.userData === "daily" &&
                          typeof userDailyOptions !== "undefined" &&
                          typeof userDailySeries !== "undefined" && (
                            <Chart
                              options={userDailyOptions}
                              series={userDailySeries}
                              type="bar"
                              height={320}
                            />
                          )}

                        {!this.state.is_loading_3 &&
                          this.state.userData === "monthly" &&
                          typeof userMonthlyOptions !== "undefined" &&
                          typeof userMonthlySeries !== "undefined" && (
                            <Chart
                              options={userMonthlyOptions}
                              series={userMonthlySeries}
                              type="bar"
                              height={320}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
