import React from "react";
import Countdown from "react-countdown";
import i18n from "../../../i18n";
import { formatCurrency } from "../../../state/utils";
import { Link } from "react-router-dom";

let current_lng = localStorage.getItem("I18N_LANGUAGE");

const FinishBid = () => (
  <div className="outer-div m-auto mt-1">
    <div className="p-1 text-center text-success">
      {i18n.t("auction_started")}
    </div>
  </div>
);

const rendererEnds = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div className="d-flex flex-column align-items-start m-auto mt-1">
        <div className="first d-flex gap-1">
          <span className="d-flex gap-2" style={{ fontSize: "0.8rem" }}>
            <small>
              {days}
              {i18n.t("D")}
            </small>
            <small>
              {hours}
              {i18n.t("H")}
            </small>{" "}
            <small>
              {minutes}
              {i18n.t("M")}{" "}
            </small>
            <small>
              {seconds}
              {i18n.t("S")}
            </small>
          </span>
        </div>
      </div>
    );
  }
};

export class SearchCard extends React.Component {
  constructor() {
    super();
    this.state = {
      auctions: [],
    };
  }

  componentDidMount() {
    let { auctions } = this.props;
    this.setState({ auctions });
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let { auction } = this.props;
    let aucEndTime = new Date(this.createDate(auction.end_date)).getTime();
    const currentDate = new Date();
    const auctionStartDate = new Date(auction.start_date);
    let can_show_end_clock = true;
    if (auctionStartDate > currentDate) {
      can_show_end_clock = false;
    }
    return (
      <React.Fragment>
        <div className="rounded-4 border border-1 border-light p-0 px-1">
          <div className="auction-card-img-cont">
            <span className="red-circle"></span>
            <img
              src={auction?.main_image}
              class="img-fluid border-0 rounded-4"
              alt="Title"
            />
          </div>
          <div className="d-flex justify-content-between px-1 py-2">
            <div
              style={{
                overflowWrap: "break-word",
                width: "50%",
              }}
              className="d-flex align-items-center"
            >
              <Link to={`/auction-details/${auction.id}`} className="text-dark">
                {current_lng === "ar" ? auction?.title_ar : auction?.title}
              </Link>
            </div>
            <div className="d-flex flex-column">
              <div>
                <span className="text-primary" style={{ fontSize: "1.7rem" }}>
                  <strong>{formatCurrency(auction.start_amount)} </strong>
                </span>
                &nbsp;<small>{i18n.t("omr")}</small>
              </div>
              <div>
                {auction.status === "A" &&
                  !auction.is_bid_time_ends &&
                  auction.end_date &&
                  can_show_end_clock && (
                    <Countdown
                      date={aucEndTime}
                      renderer={rendererEnds}
                      onComplete={() => {
                        this.setState({ bidTimeStopped: true });
                      }}
                      now={() => new Date(auction.server_time)}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
